import { Box, Text } from "@chakra-ui/react";
import { Component } from "react";

class Banner extends Component {
  render() {
    const { imageSrc, text } = this.props; // Destructuring props for easier access

    return (
      <Box>
        <Box
          position="relative"
          mt={{ base: '0px', sm: '0px', md: '0px', lg: '-92px' }}
          h={{ base: '118px', sm: '118px', md: '118px', lg: '544px' }}
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          {/* Background Image */}
          <Box
            as="img"
            src={imageSrc} // Dynamic image source from props
            alt="Background Image"
            position="absolute"
            objectFit="cover"
            zIndex="-1"
          />

          {/* Content */}
          <Box
            display={{ base: 'block', sm: 'none', md: 'none', lg: 'block' }}
            position="relative"
            zIndex="1"
          >
            <Text
              fontFamily="Unbounded"
              fontWeight="400"
              fontSize={{ base: '28px', sm: '28px', md: '28px', lg: '70px' }}
              color="white"
              textAlign="center"
            >
              {text} {/* Dynamic text from props */}
            </Text>
          </Box>
        </Box>
      </Box>
    );
  }
}

export default Banner;
