import { Box, Flex, Img, ListItem, Text, UnorderedList } from "@chakra-ui/react";
import { Component } from "react";
import Banner from "../ContactUspage/Banner";

class CloudMigration extends Component {
  componentDidMount() {
    window.scrollTo(0, 0); // Scroll to the top of the page when the component mounts
  }
  state = {}
  render() {
    return (

      <Box>

        <Banner
          imageSrc="Cloud Migration 1.jpg"
          text="Cloud Migration"
        />


        <Box px={{ base: '20px', sm: '20px', md: '20px', lg: '50px' }}
          w={{ base: 'none', sm: 'none', md: 'none', lg: '1300px' }}
          margin="70px auto"

        >
          <Text fontFamily="Unbounded" fontWeight="700"
            fontSize={{ base: '35px', sm: '35px', md: '35px', lg: '54px' }}
            color="#06BCD0"
            lineHeight={{ base: 'none', sm: 'none', md: 'none', lg: '65px' }}
            w={{ base: 'none', sm: 'none', md: 'none', lg: '850px' }}
          >
            Cloud Migration
          </Text>
          <Text fontFamily="Poppins" fontWeight="400"
            fontSize={{ base: '17px', sm: '17px', md: '17px', lg: '20px' }}
            color="black" lineHeight="28px" mt="30px">
            Our <b>Cloud Migration Framework</b> is designed to help organizations seamlessly transition their infrastructure, applications, and data to the cloud. By following a structured, secure, and strategic approach, we enable businesses to achieve increased scalability, flexibility, and operational efficiency while minimizing disruptions and ensuring cost optimization.
          </Text>

          <Text fontFamily="Unbounded" fontWeight="700" fontSize="24px"
            color="black"
            lineHeight={{ base: '35px', sm: '35px', md: '35px', lg: '65px' }}
            mt={{ base: '8px', sm: '8px', md: '8px', lg: 'none' }}
          >
            Key Components of the Cloud Migration Framework
          </Text>

          {/* 1st Box  */}
          <Box>
            <Text fontFamily="Poppins" fontWeight="600" fontSize="22px" color="black" >
              Assessment and Planning    </Text>
            <Text fontFamily="Poppins" fontWeight="500" fontSize="18px" color="black" mt="5px" >
              Understanding your current environment and defining the migration strategy       </Text>
            <Text fontFamily="Poppins" fontWeight="400" fontSize="18px" color="black" mt="5px" >
              We begin with a thorough assessment of your existing IT landscape to create a tailored migration plan:
            </Text>
            <Box display="flex"
              flexDirection={{ base: 'column', sm: 'column', md: 'column', xl: 'row' }}

              gap="10px">
              <Text fontFamily="Poppins" fontWeight="400" fontSize="16px" color="black" mt="5px" >
                <b>1.Infrastructure and Application Assessment: </b>  </Text>
              <Text fontFamily="Poppins" fontWeight="400" fontSize="16px" color="black"
                mt={{ base: '-7px', sm: '-7px', md: '-7px', xl: '5px' }} >
                Identification of workloads, dependencies, and potential risks.
              </Text>
            </Box>
            <Box display="flex"
              flexDirection={{ base: 'column', sm: 'column', md: 'column', xl: 'row' }}

              gap="10px">
              <Text fontFamily="Poppins" fontWeight="400" fontSize="16px" color="black" mt="5px" >
                <b>2.Cloud Readiness Evaluation:  </b>  </Text>
              <Text fontFamily="Poppins" fontWeight="400" fontSize="16px" color="black"
                mt={{ base: '-7px', sm: '-7px', md: '-7px', xl: '5px' }} >
                Assessment of cloud compatibility and areas for optimization.
              </Text>
            </Box>
            <Box display="flex"
              flexDirection={{ base: 'column', sm: 'column', md: 'column', xl: 'row' }}

              gap="10px">
              <Text fontFamily="Poppins" fontWeight="400" fontSize="16px" color="black" mt="5px" >
                <b>3.Migration Strategy: </b>  </Text>
              <Text fontFamily="Poppins" fontWeight="400" fontSize="16px" color="black"
                mt={{ base: '-7px', sm: '-7px', md: '-7px', xl: '5px' }} >
                Selection of the appropriate migration approach (e.g., rehost, refactor, rearchitect, or rebuild) based on business goals.
              </Text>
            </Box>
            <Text fontFamily="Poppins" fontWeight="400" fontSize="18px" color="black" mt="5px" >
              This phase ensures a clear and actionable roadmap that aligns with your business objectives.
            </Text>
          </Box>


          {/* 2nd Box  */}
          <Box mt="20px">
            <Text fontFamily="Poppins" fontWeight="600" fontSize="22px" color="black" >
              Cloud Architecture Design    </Text>
            <Text fontFamily="Poppins" fontWeight="500" fontSize="18px" color="black" mt="5px" >
              Creating a robust, scalable, and secure cloud environment     </Text>
            <Text fontFamily="Poppins" fontWeight="400" fontSize="18px" color="black" mt="5px" >
              Our experts design a cloud architecture that meets performance, security, and cost-efficiency requirements:
            </Text>
            <Box display="flex"
              flexDirection={{ base: 'column', sm: 'column', md: 'column', xl: 'row' }}

              gap="10px">
              <Text fontFamily="Poppins" fontWeight="400" fontSize="16px" color="black" mt="5px" >
                <b>1.Cloud Platform Selection:  </b>  </Text>
              <Text fontFamily="Poppins" fontWeight="400" fontSize="16px" color="black"
                mt={{ base: '-7px', sm: '-7px', md: '-7px', xl: '5px' }} >
                Guidance on choosing the right cloud provider (e.g., Azure, AWS, Google Cloud) based on your needs.
              </Text>
            </Box>
            <Box display="flex"
              flexDirection={{ base: 'column', sm: 'column', md: 'column', xl: 'row' }}

              gap="10px">
              <Text fontFamily="Poppins" fontWeight="400" fontSize="16px" color="black" mt="5px" >
                <b>2.Scalability and Performance:   </b>  </Text>
              <Text fontFamily="Poppins" fontWeight="400" fontSize="16px" color="black"
                mt={{ base: '-7px', sm: '-7px', md: '-7px', xl: '5px' }} >
                Design of auto-scaling solutions to handle variable workloads.
              </Text>
            </Box>
            <Box display="flex"
              flexDirection={{ base: 'column', sm: 'column', md: 'column', xl: 'row' }}

              gap="10px">
              <Text fontFamily="Poppins" fontWeight="400" fontSize="16px" color="black" mt="5px" >
                <b>3.Security and Compliance:   </b>  </Text>
              <Text fontFamily="Poppins" fontWeight="400" fontSize="16px" color="black"
                mt={{ base: '-7px', sm: '-7px', md: '-7px', xl: '5px' }} >
                Implementation of cloud-native security controls and adherence to industry regulations.
              </Text>
            </Box>
            <Text fontFamily="Poppins" fontWeight="400" fontSize="18px" color="black" mt="5px" >
              This ensures a secure and resilient foundation for your cloud environment.
            </Text>
          </Box>



          {/* 3rd Box  */}
          <Box mt="20px">
            <Text fontFamily="Poppins" fontWeight="600" fontSize="22px" color="black">
              Data and Application Migration     </Text>
            <Text fontFamily="Poppins" fontWeight="500" fontSize="18px" color="black" mt="5px" >
              Ensuring a seamless transition with minimal downtime    </Text>
            <Text fontFamily="Poppins" fontWeight="400" fontSize="18px" color="black"
              mt={{ base: '-7px', sm: '-7px', md: '-7px', xl: '5px' }} >
              We handle the migration of data, applications, and workloads with precision and care:
            </Text>
            <Box display="flex"
              flexDirection={{ base: 'column', sm: 'column', md: 'column', xl: 'row' }}

              gap="10px">
              <Text fontFamily="Poppins" fontWeight="400" fontSize="16px" color="black" mt="5px" >
                <b>1.Data Migration:   </b>  </Text>
              <Text fontFamily="Poppins" fontWeight="400" fontSize="16px" color="black"
                mt={{ base: '-7px', sm: '-7px', md: '-7px', xl: '5px' }} >
                Secure and efficient transfer of databases, files, and structured/unstructured data.
              </Text>
            </Box>
            <Box display="flex"
              flexDirection={{ base: 'column', sm: 'column', md: 'column', xl: 'row' }}

              gap="10px">
              <Text fontFamily="Poppins" fontWeight="400" fontSize="16px" color="black" mt="5px" >
                <b>2.Application Migration:   </b>  </Text>
              <Text fontFamily="Poppins" fontWeight="400" fontSize="16px" color="black"
                mt={{ base: '-7px', sm: '-7px', md: '-7px', xl: '5px' }} >
                Rehosting, refactoring, or rebuilding applications to optimize for cloud performance.
              </Text>
            </Box>
            <Box display="flex"
              flexDirection={{ base: 'column', sm: 'column', md: 'column', xl: 'row' }}

              gap="10px">
              <Text fontFamily="Poppins" fontWeight="400" fontSize="16px" color="black" mt="5px" >
                <b>3.Downtime Mitigation:   </b>  </Text>
              <Text fontFamily="Poppins" fontWeight="400" fontSize="16px" color="black"
                mt={{ base: '-7px', sm: '-7px', md: '-7px', xl: '5px' }} >
                Implementation of phased migrations and fallback mechanisms to ensure minimal service interruptions.
              </Text>
            </Box>
            <Text fontFamily="Poppins" fontWeight="400" fontSize="18px" color="black" mt="5px" >
              Our approach ensures that critical operations continue smoothly during the migration process.
            </Text>
          </Box>


          {/* 4th Box  */}
          <Box mt="20px">
            <Text fontFamily="Poppins" fontWeight="600" fontSize="22px" color="black">
              Optimization and Cost Management     </Text>
            <Text fontFamily="Poppins" fontWeight="500" fontSize="18px" color="black" mt="5px" >
              Maximizing the value of your cloud investments     </Text>
            <Text fontFamily="Poppins" fontWeight="400" fontSize="18px" color="black" mt="5px" >
              Post-migration, we focus on continuous optimization to improve performance and manage costs:
            </Text>
            <Box display="flex"
              flexDirection={{ base: 'column', sm: 'column', md: 'column', xl: 'row' }}

              gap="10px">
              <Text fontFamily="Poppins" fontWeight="400" fontSize="16px" color="black" mt="5px" >
                <b>1.Performance Tuning:   </b>  </Text>
              <Text fontFamily="Poppins" fontWeight="400" fontSize="16px" color="black"
                mt={{ base: '-7px', sm: '-7px', md: '-7px', xl: '5px' }} >
                Regular monitoring and adjustments to enhance cloud performance.
              </Text>
            </Box>
            <Box display="flex"
              flexDirection={{ base: 'column', sm: 'column', md: 'column', xl: 'row' }}

              gap="10px">
              <Text fontFamily="Poppins" fontWeight="400" fontSize="16px" color="black" mt="5px" >
                <b>2.Cost Optimization:    </b>  </Text>
              <Text fontFamily="Poppins" fontWeight="400" fontSize="16px" color="black"
                mt={{ base: '-7px', sm: '-7px', md: '-7px', xl: '5px' }} >
                Identification of cost-saving opportunities through resource right-sizing, reserved instances, and workload automation.
              </Text>
            </Box>
            <Box display="flex"
              flexDirection={{ base: 'column', sm: 'column', md: 'column', xl: 'row' }}

              gap="10px">
              <Text fontFamily="Poppins" fontWeight="400" fontSize="16px" color="black" mt="5px" >
                <b>3.Governance and Usage Insights:   </b>  </Text>
              <Text fontFamily="Poppins" fontWeight="400" fontSize="16px" color="black"
                mt={{ base: '-7px', sm: '-7px', md: '-7px', xl: '5px' }} >
                Implementation of dashboards and reports to track cloud usage and ensure alignment with budgetary goals.
              </Text>
            </Box>
            <Text fontFamily="Poppins" fontWeight="400" fontSize="18px" color="black" mt="5px" >
              By optimizing your cloud environment, we help you achieve operational efficiency and cost-effectiveness.
            </Text>
          </Box>


          {/* 5th Box  */}
          <Box mt="20px">
            <Text fontFamily="Poppins" fontWeight="600" fontSize="22px" color="black">
              Security and Compliance     </Text>
            <Text fontFamily="Poppins" fontWeight="500" fontSize="18px" color="black" mt="5px" >
              Maintaining robust security and regulatory adherence     </Text>
            <Text fontFamily="Poppins" fontWeight="400" fontSize="18px" color="black" mt="5px" >
              Our framework integrates advanced security measures to safeguard your cloud assets:
            </Text>
            <Box display="flex"
              flexDirection={{ base: 'column', sm: 'column', md: 'column', xl: 'row' }}

              gap="10px">
              <Text fontFamily="Poppins" fontWeight="400" fontSize="16px" color="black" mt="5px" >
                <b>1.Identity and Access Management (IAM):    </b>  </Text>
              <Text fontFamily="Poppins" fontWeight="400" fontSize="16px" color="black"
                mt={{ base: '-7px', sm: '-7px', md: '-7px', xl: '5px' }} >
                Role-based access controls and multi-factor authentication.
              </Text>
            </Box>
            <Box display="flex"
              flexDirection={{ base: 'column', sm: 'column', md: 'column', xl: 'row' }}

              gap="10px">
              <Text fontFamily="Poppins" fontWeight="400" fontSize="16px" color="black" mt="5px" >
                <b>2.Encryption:    </b>  </Text>
              <Text fontFamily="Poppins" fontWeight="400" fontSize="16px" color="black"
                mt={{ base: '-7px', sm: '-7px', md: '-7px', xl: '5px' }} >
                End-to-end encryption for data at rest and in transit.
              </Text>
            </Box>
            <Box display="flex"
              flexDirection={{ base: 'column', sm: 'column', md: 'column', xl: 'row' }}

              gap="10px">
              <Text fontFamily="Poppins" fontWeight="400" fontSize="16px" color="black" mt="5px" >
                <b>3.Security Monitoring and Alerts:    </b>  </Text>
              <Text fontFamily="Poppins" fontWeight="400" fontSize="16px" color="black"
                mt={{ base: '-7px', sm: '-7px', md: '-7px', xl: '5px' }} >
                Real-time monitoring to detect and respond to potential threats.
              </Text>
            </Box>
            <Box display="flex"
              flexDirection={{ base: 'column', sm: 'column', md: 'column', xl: 'row' }}

              gap="10px">
              <Text fontFamily="Poppins" fontWeight="400" fontSize="16px" color="black" mt="5px" >
                <b>4.Compliance Frameworks:     </b>  </Text>
              <Text fontFamily="Poppins" fontWeight="400" fontSize="16px" color="black" mt="5px" >
                Ensuring alignment with key regulatory standards (GDPR, ISO 27001, HIPAA, etc.).
              </Text>
            </Box>
            <Text fontFamily="Poppins" fontWeight="400" fontSize="18px" color="black"
              mt={{ base: '-7px', sm: '-7px', md: '-7px', xl: '5px' }} >
              These measures provide peace of mind that your cloud environment remains secure and compliant.
            </Text>
          </Box>



          {/* 5th Box  */}
          <Box mt="20px">
            <Text fontFamily="Poppins" fontWeight="600" fontSize="22px" color="black" >
              Post-Migration Support and Maintenance      </Text>
            <Text fontFamily="Poppins" fontWeight="500" fontSize="18px" color="black" mt="5px" >
              Ensuring ongoing success and continuous improvement    </Text>
            <Text fontFamily="Poppins" fontWeight="400" fontSize="18px" color="black" mt="5px" >
              We provide comprehensive post-migration support to ensure smooth operations:
            </Text>
            <Box display="flex"
              flexDirection={{ base: 'column', sm: 'column', md: 'column', xl: 'row' }}

              gap="10px">
              <Text fontFamily="Poppins" fontWeight="400" fontSize="16px" color="black" mt="5px" >
                <b>1.24/7 Support Services:    </b>  </Text>
              <Text fontFamily="Poppins" fontWeight="400" fontSize="16px" color="black"
                mt={{ base: '-7px', sm: '-7px', md: '-7px', xl: '5px' }} >
                Ongoing assistance to address any technical issues.
              </Text>
            </Box>
            <Box display="flex"
              flexDirection={{ base: 'column', sm: 'column', md: 'column', xl: 'row' }}

              gap="10px">
              <Text fontFamily="Poppins" fontWeight="400" fontSize="16px" color="black" mt="5px" >
                <b>2.Cloud Performance Audits:   </b>  </Text>
              <Text fontFamily="Poppins" fontWeight="400" fontSize="16px" color="black"
                mt={{ base: '-7px', sm: '-7px', md: '-7px', xl: '5px' }} >
                Periodic evaluations to ensure optimal performance.
              </Text>
            </Box>
            <Box display="flex"
              flexDirection={{ base: 'column', sm: 'column', md: 'column', xl: 'row' }}

              gap="10px">
              <Text fontFamily="Poppins" fontWeight="400" fontSize="16px" color="black" mt="5px" >
                <b>3.Continuous Training:    </b>  </Text>
              <Text fontFamily="Poppins" fontWeight="400" fontSize="16px" color="black"
                mt={{ base: '-7px', sm: '-7px', md: '-7px', xl: '5px' }} >
                Empowering your teams with the knowledge and best practices for cloud management.
              </Text>
            </Box>

            <Text fontFamily="Poppins" fontWeight="400" fontSize="18px" color="black" mt="5px" >
              With proactive support and continuous improvements, your cloud environment stays future-ready.
            </Text>
          </Box>

          <Text fontFamily="Unbounded" fontWeight="700" fontSize="24px"
            color="black"
            lineHeight={{ base: '35px', sm: '35px', md: '35px', lg: '65px' }}
            mt={{ base: '8px', sm: '8px', md: '8px', lg: 'none' }}
          >
            Business Outcomes of the Cloud Migration Framework          </Text>

          <Box display="flex"
            flexDirection={{ base: 'column', sm: 'column', md: 'column', xl: 'row' }}

            gap="5px">
            <Text fontFamily="Poppins" fontWeight="400"
              fontSize={{ base: '16px', sm: '16px', md: '16px', lg: '17px' }}
              color="black">
              <b>Enhanced Scalability and Agility:  </b>
            </Text>
            <Text fontFamily="Poppins" fontWeight="400"
              fontSize={{ base: '16px', sm: '16px', md: '16px', lg: '17px' }}
              color="black">
              Respond to business demands with flexible, on-demand cloud resources.
            </Text>
          </Box>

          <Box display="flex"
            flexDirection={{ base: 'column', sm: 'column', md: 'column', xl: 'row' }}

            gap="5px">
            <Text fontFamily="Poppins" fontWeight="400"
              fontSize={{ base: '16px', sm: '16px', md: '16px', lg: '17px' }}
              color="black">
              <b>Improved Performance and Reliability:  </b>
            </Text>
            <Text fontFamily="Poppins" fontWeight="400"
              fontSize={{ base: '16px', sm: '16px', md: '16px', lg: '17px' }}
              color="black">
              Leverage cloud-native capabilities to ensure uptime and high availability.
            </Text>
          </Box>

          <Box display="flex"
            flexDirection={{ base: 'column', sm: 'column', md: 'column', xl: 'row' }}

            gap="5px">
            <Text fontFamily="Poppins" fontWeight="400"
              fontSize={{ base: '16px', sm: '16px', md: '16px', lg: '17px' }}
              color="black">
              <b>Cost Efficiency:   </b>
            </Text>
            <Text fontFamily="Poppins" fontWeight="400"
              fontSize={{ base: '16px', sm: '16px', md: '16px', lg: '17px' }}
              color="black">
              Optimize IT expenditures with a pay-as-you-go model and cost-saving measures.
            </Text>
          </Box>

          <Box display="flex"
            flexDirection={{ base: 'column', sm: 'column', md: 'column', xl: 'row' }}

            gap="5px">
            <Text fontFamily="Poppins" fontWeight="400"
              fontSize={{ base: '16px', sm: '16px', md: '16px', lg: '17px' }}
              color="black">
              <b>Strengthened Security and Compliance:   </b>
            </Text>
            <Text fontFamily="Poppins" fontWeight="400"
              fontSize={{ base: '16px', sm: '16px', md: '16px', lg: '17px' }}
              color="black">
              Implement robust security controls to protect sensitive data and meet regulatory requirements.
            </Text>
          </Box>

          <Box display="flex"
            flexDirection={{ base: 'column', sm: 'column', md: 'column', xl: 'row' }}

            gap="5px">
            <Text fontFamily="Poppins" fontWeight="400"
              fontSize={{ base: '16px', sm: '16px', md: '16px', lg: '17px' }}
              color="black">
              <b>Faster Time-to-Innovation:    </b>
            </Text>
            <Text fontFamily="Poppins" fontWeight="400"
              fontSize={{ base: '16px', sm: '16px', md: '16px', lg: '17px' }}
              color="black">
              Accelerate development cycles with cloud-based tools and services.
            </Text>
          </Box>

          <Text fontFamily="Unbounded" fontWeight="700" fontSize="24px"
            color="black"
            lineHeight={{ base: '35px', sm: '35px', md: '35px', lg: '65px' }}

            mt={{ base: '8px', sm: '8px', md: '8px', lg: 'none' }}
          >
            Why Choose Asture Consulting?
          </Text>

          <Text fontFamily="Poppins" fontWeight="400"
            fontSize={{ base: '17px', sm: '17px', md: '17px', lg: '20px' }}
            color="black" lineHeight="28px">
            At <b>Asture Consulting,</b> we understand that a successful cloud migration goes beyond the technical aspects—it’s about delivering tangible business value. Our team of cloud experts provides end-to-end guidance, from strategy and planning to implementation and ongoing support.
          </Text>

          <Text fontFamily="Poppins" fontWeight="400"
            fontSize={{ base: '17px', sm: '17px', md: '17px', lg: '20px' }}
            color="black" lineHeight="28px" mt="30px">
            We tailor our Cloud Migration framework to your unique business needs, ensuring a seamless transition and empowering your organization to leverage the full potential of cloud technologies.
          </Text>

          <Text fontFamily="Poppins" fontWeight="400"
            fontSize={{ base: '17px', sm: '17px', md: '17px', lg: '20px' }}
            color="black" lineHeight="28px" mt="30px">
            <b>Next Steps:</b> Ready to modernize your infrastructure? Contact <b>Asture Consulting</b> today to explore how our Cloud Migration Framework can accelerate your digital transformation and drive long-term success.
          </Text>

          <Img mt="30px" src="Cloud Migration 2.png" />

        </Box>

      </Box>


    );
  }
}

export default CloudMigration;