import { Accordion, AccordionButton, AccordionItem, AccordionPanel, Box, Text } from "@chakra-ui/react";
import { Component } from "react";
import Banner from "../ContactUspage/Banner";
import { AddIcon, MinusIcon } from "@chakra-ui/icons";
import Business_consultant from "./Business_consultant";
import Executive_assistant from "./Executive_assistant";
import Microsoft_dynamics_consultant from "./Microsoft_dynamics_consultant";
import Modern_workplace_consultant from "./Modern_workplace_consultant";
import Power_platform_consultant from "./Power_platform_consultant";
import Sales_manager from "./Sales_manager";

class Careers extends Component {
  componentDidMount() {
    window.scrollTo(0, 0); // Scroll to the top of the page when the component mounts
  }
  state = {}
  render() {
    return (

      <Box>
        <Banner
          imageSrc="Careers.jpg"
          text="Careers"
        />

        <Box
          px={{ base: '20px', sm: '20px', md: '20px', lg: '50px' }}
          w={{ base: 'none', sm: 'none', md: 'none', lg: '1300px' }}
          margin="70px auto"
        >

          <Text fontFamily="Poppins" fontWeight="400"
            fontSize={{ base: '17px', sm: '17px', md: '17px', lg: '20px' }}
            color="black">
            Join the team at Asture Consulting
            , where we combine Strategic Vision
            with Smart Execution
            to deliver innovative IT solutions for businesses across the globe
            . We are a dynamic, fast-growing startup with a passion for digital transformation, cloud technologies, and cutting-edge solutions like Microsoft 365, Power Platform, and Dynamics 365.
          </Text>


          <Text fontFamily="Poppins" fontWeight="400"
            fontSize={{ base: '17px', sm: '17px', md: '17px', lg: '20px' }}
            color="black" mt="15px">
            We’re always looking for talented, forward-thinking professionals who are ready to take on new challenges and make an impact. Whether you’re an experienced consultant, a developer, or an IT infrastructure specialist, we offer opportunities to grow your skills, work on exciting projects, and be part of a collaborative, innovative team.
          </Text>

          <Text fontFamily="Poppins" fontWeight="400"
            fontSize={{ base: '17px', sm: '17px', md: '17px', lg: '20px' }}
            color="black" mt="15px">
            Check out the positions we’re currently hiring for below and join us in helping businesses thrive in the digital age.
          </Text>

          <Accordion
            mt="20px"
            allowMultiple>

            {/* 1st  */}
            <AccordionItem p="10px"
              _hover="none"
              borderColor="#06BCD0"
              borderWidth="3px"
              borderRadius="35px"

            >
              {({ isExpanded }) => (
                <>
                  <h2>
                    <AccordionButton
                      _hover="none"
                    >
                      <Box as='span' flex='1' textAlign='left'>
                        <Text fontFamily="Unbounded" fontWeight="400"
                          fontSize={{ base: '20px', sm: '20px', md: '20px', lg: '25px' }}
                          color="black">
                          Executive Assistant
                        </Text>
                      </Box>
                      {isExpanded ? (
                        <MinusIcon fontSize='20px' />
                      ) : (
                        <AddIcon fontSize='20px' />
                      )}
                    </AccordionButton>
                  </h2>
                  <AccordionPanel pb={4}>


                    <Executive_assistant />

                  </AccordionPanel>
                </>
              )}
            </AccordionItem>


            {/* 2nd */}
            <AccordionItem
              p="10px"
              borderColor="#06BCD0"
              borderWidth="3px"
              borderRadius="35px"
              mt="15px"
              borderBottom="solid"
              borderBottomWidth="3px !important"
              borderBottomColor="#06BCD0!important"
            >
              {({ isExpanded }) => (
                <>
                  <h2>
                    <AccordionButton
                      _hover="none"
                    >
                      <Box as='span' flex='1' textAlign='left'>
                        <Text fontFamily="Unbounded" fontWeight="400"
                          fontSize={{ base: '20px', sm: '20px', md: '20px', lg: '25px' }}
                        >

                          Sales Manager
                        </Text>            </Box>
                      {isExpanded ? (
                        <MinusIcon fontSize='20px' />
                      ) : (
                        <AddIcon fontSize='20px' />
                      )}
                    </AccordionButton>
                  </h2>
                  <AccordionPanel pb={4}>

                    <Sales_manager />

                  </AccordionPanel>
                </>
              )}
            </AccordionItem>

            {/* 3rd */}
            <AccordionItem
              p="10px"
              borderColor="#06BCD0"
              borderWidth="3px"
              borderRadius="35px"
              mt="15px"
              borderBottom="solid"
              borderBottomWidth="3px !important"
              borderBottomColor="#06BCD0!important"
            >
              {({ isExpanded }) => (
                <>
                  <h2>
                    <AccordionButton
                      _hover="none"
                    >
                      <Box as='span' flex='1' textAlign='left'>
                        <Text fontFamily="Unbounded" fontWeight="400"
                          fontSize={{ base: '20px', sm: '20px', md: '20px', lg: '25px' }}
                        >

                          Business Consulttant
                        </Text>            </Box>
                      {isExpanded ? (
                        <MinusIcon fontSize='20px' />
                      ) : (
                        <AddIcon fontSize='20px' />
                      )}
                    </AccordionButton>
                  </h2>
                  <AccordionPanel pb={4}>

                    <Business_consultant />

                  </AccordionPanel>
                </>
              )}
            </AccordionItem>


            {/* 4th */}
            <AccordionItem
              p="10px"
              borderColor="#06BCD0"
              borderWidth="3px"
              borderRadius="35px"
              mt="15px"
              borderBottom="solid"
              borderBottomWidth="3px !important"
              borderBottomColor="#06BCD0!important"
            >
              {({ isExpanded }) => (
                <>
                  <h2>
                    <AccordionButton
                      _hover="none"
                    >
                      <Box as='span' flex='1' textAlign='left'>
                        <Text fontFamily="Unbounded" fontWeight="400"
                          fontSize={{ base: '20px', sm: '20px', md: '20px', lg: '25px' }}
                        >

                          Modern Workplace Consultant
                        </Text>            </Box>
                      {isExpanded ? (
                        <MinusIcon fontSize='20px' />
                      ) : (
                        <AddIcon fontSize='20px' />
                      )}
                    </AccordionButton>
                  </h2>
                  <AccordionPanel pb={4}>

                    <Modern_workplace_consultant />

                  </AccordionPanel>
                </>
              )}
            </AccordionItem>

            {/* 5th */}
            <AccordionItem
              p="10px"
              borderColor="#06BCD0"
              borderWidth="3px"
              borderRadius="35px"
              mt="15px"
              borderBottom="solid"
              borderBottomWidth="3px !important"
              borderBottomColor="#06BCD0!important"
            >
              {({ isExpanded }) => (
                <>
                  <h2>
                    <AccordionButton
                      _hover="none"
                    >
                      <Box as='span' flex='1' textAlign='left'>
                        <Text fontFamily="Unbounded" fontWeight="400"
                          fontSize={{ base: '20px', sm: '20px', md: '20px', lg: '25px' }}
                        >

                          Power Platform Consultant
                        </Text>            </Box>
                      {isExpanded ? (
                        <MinusIcon fontSize='20px' />
                      ) : (
                        <AddIcon fontSize='20px' />
                      )}
                    </AccordionButton>
                  </h2>
                  <AccordionPanel pb={4}>

                    <Power_platform_consultant />

                  </AccordionPanel>
                </>
              )}
            </AccordionItem>

            {/* 6th */}
            <AccordionItem
              p="10px"
              borderColor="#06BCD0"
              borderWidth="3px"
              borderRadius="35px"
              mt="15px"
              borderBottom="solid"
              borderBottomWidth="3px !important"
              borderBottomColor="#06BCD0!important"
            >
              {({ isExpanded }) => (
                <>
                  <h2>
                    <AccordionButton
                      _hover="none"
                    >
                      <Box as='span' flex='1' textAlign='left'>
                        <Text fontFamily="Unbounded" fontWeight="400"
                          fontSize={{ base: '20px', sm: '20px', md: '20px', lg: '25px' }}
                        >

                          Microsoft Dynamics Consultant
                        </Text>            </Box>
                      {isExpanded ? (
                        <MinusIcon fontSize='20px' />
                      ) : (
                        <AddIcon fontSize='20px' />
                      )}
                    </AccordionButton>
                  </h2>
                  <AccordionPanel pb={4}>

                    <Microsoft_dynamics_consultant />

                  </AccordionPanel>
                </>
              )}
            </AccordionItem>




          </Accordion>


        </Box>

      </Box>
    );
  }
}

export default Careers;