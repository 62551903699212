import React, { useState } from "react";

const MailchimpForm = () => {



    return (
        <div id="mc_embed_shell">
            <link
                href="//cdn-images.mailchimp.com/embedcode/classic-061523.css"
                rel="stylesheet"
                type="text/css"
            />
            <style type="text/css">
                {`
                    #mc_embed_signup {
                        background: #fff;
                        clear: left;
                        font: 14px Helvetica, Arial, sans-serif;
                        width: 900px;
                        max-width:100%;
                    }
                `}
            </style>
            <div id="mc_embed_signup">
                <form
                    action="https://ltd.us21.list-manage.com/subscribe/post?u=f0e1a522e9dbb72a857f6c120&amp;id=4e75591fc0&amp;f_id=0099f7e6f0"
                    method="post"
                    id="mc-embedded-subscribe-form"
                    name="mc-embedded-subscribe-form"
                    className="validate"
                    target="_blank"

                >
                    <div id="mc_embed_signup_scroll">
                        <div className="indicates-required">
                            <span className="asterisk">*</span> indicates required
                        </div>
                        <div className="mc-field-group">
                            <label htmlFor="mce-FNAME">
                                First Name <span className="asterisk">*</span>
                            </label>
                            <input
                                type="text"
                                name="FNAME"
                                className="required text"
                                id="mce-FNAME"
                                required
                            />
                        </div>
                        <div className="mc-field-group">
                            <label htmlFor="mce-LNAME">Last Name</label>
                            <input
                                type="text"
                                name="LNAME"
                                className="text"
                                id="mce-LNAME"
                            />
                        </div>
                        <div className="mc-field-group">
                            <label htmlFor="mce-EMAIL">
                                Email Address <span className="asterisk">*</span>
                            </label>
                            <input
                                type="email"
                                name="EMAIL"
                                className="required email"
                                id="mce-EMAIL"
                                required
                            />
                        </div>
                        <div className="mc-field-group">
                            <label htmlFor="mce-PHONE">
                                Phone Number <span className="asterisk">*</span>
                            </label>
                            <input
                                type="text"
                                name="PHONE"
                                className="REQ_CSS"
                                id="mce-PHONE"
                            />
                        </div>
                        <div className="mc-field-group">
                            <label htmlFor="mce-MMERGE6">Message</label>
                            <input
                                type="text"
                                name="MMERGE6"
                                className="text"
                                id="mce-MMERGE6"
                            />
                        </div>



                        <div className="optionalParent">
                            <div className="clear foot">
                                <input
                                    type="submit"
                                    name="subscribe"
                                    id="mc-embedded-subscribe"
                                    className="button"
                                    value="Send"
                                />
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default MailchimpForm;
