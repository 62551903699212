import { Accordion, AccordionButton, AccordionIcon, AccordionItem, AccordionPanel, AspectRatio, Box, Img, Text } from "@chakra-ui/react";
import { Component } from "react";
import Contactform from "../Contact Form/Contactform";
import Banner from "./Banner";
import Bookingsection from "./Bookingsection";
import { AddIcon, MinusIcon } from "@chakra-ui/icons";
import Contactinfo from "./Contactinfo";
import Formmailchimp from "./Formmailchimp";

class Contactpage extends Component {
  componentDidMount() {
    window.scrollTo(0, 0); // Scroll to the top of the page when the component mounts
  }
  state = {}
  render() {
    return (
      <Box>
        <Banner
          imageSrc="contactbanner.jpg"
          text="Contact Us"
        />

        {/* Contact form */}
        <Box px={{ base: '20px', sm: '20px', md: '20px', lg: '50px' }}
          py={{ base: '20px', sm: '20px', md: '20px', lg: '50px' }}
          display="flex"
          flexDirection={{ base: 'column-reverse', sm: 'column-reverse', md: 'column-reverse', lg: 'row' }}
          gap="50px"
        >


          <Box>



            <Contactinfo />


          </Box>

          <Box
            width="1920px"
            maxW="100%"
          >
            {/* <Accordion 
allowMultiple>
 

  <AccordionItem p="10px"
  _hover="none"
  borderColor="#06BCD0"
  borderWidth="3px"
  borderRadius="35px"

  >
    {({ isExpanded }) => (
      <>
        <h2>
          <AccordionButton
          _hover="none"
          >
            <Box as='span' flex='1' textAlign='left'>
            <Text fontFamily="Unbounded" fontWeight="400"
             fontSize={{base:'20px',sm:'20px',md:'20px',lg:'25px'}}
             color="black">
            Book Your Meeting                  
                </Text>
                            </Box>
            {isExpanded ? (
              <MinusIcon fontSize='20px' />
            ) : (
              <AddIcon fontSize='20px' />
            )}
          </AccordionButton>
        </h2>
        <AccordionPanel pb={4}>
        <Bookingsection/>

        </AccordionPanel>
      </>
    )}
  </AccordionItem>



  <AccordionItem
  p="10px"
  borderColor="#06BCD0"
  borderWidth="3px"
  borderRadius="35px"
  mt="15px"
  borderBottom="solid"
  borderBottomWidth="3px !important"
  borderBottomColor="#06BCD0!important"
  >
    {({ isExpanded }) => (
      <>
        <h2>
          <AccordionButton
          _hover="none"
          >
            <Box as='span' flex='1' textAlign='left'>
            <Text fontFamily="Unbounded" fontWeight="400" 
             fontSize={{base:'20px',sm:'20px',md:'20px',lg:'25px'}}
             >
            
            Contact                
                </Text>            </Box>
            {isExpanded ? (
              <MinusIcon fontSize='20px' />
            ) : (
              <AddIcon fontSize='20px' />
            )}
          </AccordionButton>
        </h2>
        <AccordionPanel pb={4}>
        <Contactform/>

        </AccordionPanel>
      </>
    )}
  </AccordionItem>


</Accordion> */}

            <Formmailchimp />

            <Box display="flex"
              gap={{
                base: '0px',
                sm: '0px',
                md: '0px',
                lg: '0px',
                xl: '80px'
              }}
              mt="30px"
              flexDirection={{
                base: 'column',
                sm: 'column',
                md: 'column',
                lg: 'column',
                xl: 'row'
              }}
            >

              <Box display="flex" gap="5px">
                {/* <Img h="50px" w="50px" src="locationpin.png" /> */}
                <Text fontFamily="Poppins" fontWeight="600"
                  fontSize={{ base: '17px', sm: '17px', md: '17px', lg: '20px' }}
                  color="#06BCD0" lineHeight="28px" mt="20px">
                  Dubai (HQ) :
                </Text>

                <Text fontFamily="Poppins" fontWeight="300"
                  fontSize={{ base: '17px', sm: '17px', md: '17px', lg: '20px' }}
                  color="black" lineHeight="28px" mt="20px">
                  Office - 55632 -001<br />
                  Makani - A1 - 3641379065<br />
                  IFZA Business Park, DDP<br />
                  Dubai Silicon Oasis<br />
                  Dubai, United Arab Emirates
                </Text>
              </Box>


              <Box display="flex" gap="15px">
                {/* <Img h="50px" w="50px" src="locationpin.png" /> */}
                <Text fontFamily="Poppins" fontWeight="600"
                  fontSize={{ base: '17px', sm: '17px', md: '17px', lg: '20px' }}
                  color="#06BCD0" lineHeight="28px" mt="20px">
                  Lahore :
                </Text>
                <Text fontFamily="Poppins" fontWeight="300"
                  fontSize={{ base: '17px', sm: '17px', md: '17px', lg: '20px' }}
                  color="black" lineHeight="28px" mt="20px">
                  65-Z Block, <br />
                  Phase 3, DHA, Lahore,<br />
                  Lahore, Pakistan, <br />
                  Punjab 54810, PK
                </Text>
              </Box>

            </Box>

          </Box>



        </Box>

      </Box>
    );
  }
}

export default Contactpage;