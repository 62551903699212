import { Box, ListItem, Text, UnorderedList } from "@chakra-ui/react";
import { Component } from "react";

class Executive_assistant extends Component {
    state = {}
    render() {
        return (
            <Box>

                <Text fontFamily="Unbounded" fontWeight="700"
                    fontSize={{ base: '20px', sm: '20px', md: '20px', lg: '24px' }}
                    lineHeight={{ base: '35px', sm: '35px', md: '35px', lg: '65px' }}
                    color="black"
                    mt={{ base: '8px', sm: '8px', md: '8px', lg: '10px' }}

                >
                    Be the Backbone of Asture Consulting’s Success as Our Executive Assistant!                 </Text>

                <Text fontFamily="Poppins" fontWeight="400"
                    fontSize={{ base: '17px', sm: '17px', md: '17px', lg: '20px' }}
                    color="black" lineHeight="28px">
                    Are you an organized, proactive, and detail-oriented professional looking to make a
                    meaningful impact? Do you excel in providing top-notch support and thrive in fast-paced
                    environments? If so, <b>Asture Consulting</b> is seeking an <b>Executive
                        Assistant</b> to join our team and support our leadership in achieving exceptional results.
                </Text>

                <Text fontFamily="Unbounded" fontWeight="700"
                    fontSize={{ base: '20px', sm: '20px', md: '20px', lg: '24px' }}
                    lineHeight={{ base: '35px', sm: '35px', md: '35px', lg: '65px' }}
                    color="black"

                    mt={{ base: '8px', sm: '8px', md: '8px', lg: '10px' }}

                >
                    About Asture Consulting                    </Text>


                <Text fontFamily="Poppins" fontWeight="400"
                    fontSize={{ base: '17px', sm: '17px', md: '17px', lg: '20px' }}
                    color="black" lineHeight="28px">
                    Asture Consulting is a leader in providing innovative, technology-driven solutions that empower businesses to thrive. We are a fast-growing consultancy dedicated to delivering exceptional results for our clients across industries. As an Executive Assistant,
                    you will play a pivotal role in ensuring the smooth operation of our leadership team, contributing to the overall success of the organization.
                </Text>


                <Text fontFamily="Unbounded" fontWeight="700"
                    fontSize={{ base: '20px', sm: '20px', md: '20px', lg: '24px' }}
                    lineHeight={{ base: '35px', sm: '35px', md: '35px', lg: '65px' }}
                    color="black"
                    mt={{ base: '8px', sm: '8px', md: '8px', lg: '10px' }}

                >
                    Role Overview                    </Text>



                <Text fontFamily="Poppins" fontWeight="400"
                    fontSize={{ base: '17px', sm: '17px', md: '17px', lg: '20px' }}
                    color="black" lineHeight="28px">
                    The <b>Executive Assistant</b> will provide high-level administrative support to senior executives, enabling them to focus on strategic priorities.
                    You will manage schedules, handle communications, and coordinate essential tasks to ensure efficiency and organization at the leadership level.
                </Text>


                <Text fontFamily="Unbounded" fontWeight="700"
                    fontSize={{ base: '20px', sm: '20px', md: '20px', lg: '24px' }}
                    lineHeight={{ base: '35px', sm: '35px', md: '35px', lg: '65px' }}
                    color="black"
                    mt={{ base: '8px', sm: '8px', md: '8px', lg: '10px' }}

                >
                    Key Responsibilities  </Text>

                <UnorderedList
                    fontFamily="Poppins" fontWeight="500"
                    fontSize={{ base: '15px', sm: '15px', md: '15px', lg: '18px' }}
                    color="black" lineHeight="28px"
                >
                    <ListItem>Manage and maintain executives’ schedules, including appointments, meetings, and travel arrangements.</ListItem>
                    <ListItem>Serve as the primary point of contact for internal and external communications on behalf of executives.</ListItem>
                    <ListItem>Prepare reports, presentations, and correspondence to support leadership objectives.</ListItem>
                    <ListItem>Coordinate and organize meetings, including preparing agendas and minutes.</ListItem>
                    <ListItem>Handle confidential information with the utmost discretion and professionalism.</ListItem>
                    <ListItem>Assist with project management tasks, ensuring deadlines are met and deliverables are completed.</ListItem>
                    <ListItem>Support the planning and execution of company events and initiatives.</ListItem>
                </UnorderedList>

                <Text fontFamily="Unbounded" fontWeight="700"
                    fontSize={{ base: '20px', sm: '20px', md: '20px', lg: '24px' }}
                    lineHeight={{ base: '35px', sm: '35px', md: '35px', lg: '65px' }}
                    color="black"
                    mt={{ base: '8px', sm: '8px', md: '8px', lg: '10px' }}

                >
                    What We’re Looking For </Text>

                {/* Bullets Start */}

                <Text fontFamily="Poppins" fontWeight="400"
                    fontSize={{ base: '15px', sm: '15px', md: '15px', lg: '18px' }}
                    color="black" lineHeight="28px" mt="10px">
                    <b>Educational Background:</b> Bachelor’s degree in Business Administration, Communications, or a related field preferred.
                </Text>

                <Text fontFamily="Poppins" fontWeight="400"
                    fontSize={{ base: '15px', sm: '15px', md: '15px', lg: '18px' }}
                    color="black" lineHeight="28px" mt="10px">
                    <b>Experience:</b> <br></br>
                    <UnorderedList
                        fontFamily="Poppins" fontWeight="400"
                        fontSize={{ base: '15px', sm: '15px', md: '15px', lg: '18px' }}
                        color="black" lineHeight="28px"
                    >
                        <ListItem>Minimum of 5 years of experience as an Executive Assistant or in a similar administrative role.</ListItem>
                        <ListItem>Experience supporting C-level executives is a plus.</ListItem>
                    </UnorderedList>
                </Text>

                <Text fontFamily="Poppins" fontWeight="400"
                    fontSize={{ base: '15px', sm: '15px', md: '15px', lg: '18px' }}
                    color="black" lineHeight="28px" mt="10px">
                    <b>Skills: </b> <br></br>
                    <UnorderedList
                        fontFamily="Poppins" fontWeight="400"
                        fontSize={{ base: '15px', sm: '15px', md: '15px', lg: '18px' }}
                        color="black" lineHeight="28px"
                    >
                        <ListItem>Exceptional organizational and time-management skills.</ListItem>
                        <ListItem>Strong written and verbal communication abilities.</ListItem>
                        <ListItem>Proficiency in Microsoft Office Suite (Word, Excel, PowerPoint, Outlook) and other productivity tools.</ListItem>
                        <ListItem>Strong communication and interpersonal skills to engage effectively with clients and teams.</ListItem>
                        <ListItem>Ability to handle multiple tasks with attention to detail and prioritize effectively.</ListItem>
                    </UnorderedList>
                </Text>


                <Text fontFamily="Poppins" fontWeight="400"
                    fontSize={{ base: '15px', sm: '15px', md: '15px', lg: '18px' }}
                    color="black" lineHeight="28px" mt="10px">
                    <b>Mindset:</b> <br></br>
                    <UnorderedList
                        fontFamily="Poppins" fontWeight="400"
                        fontSize={{ base: '15px', sm: '15px', md: '15px', lg: '18px' }}
                        color="black" lineHeight="28px"
                    >
                        <ListItem>Professional, proactive, and resourceful with a “can-do” attitude.</ListItem>
                        <ListItem>Comfortable working in a dynamic, fast-paced environment.</ListItem>
                        <ListItem>Strong interpersonal skills with the ability to build positive relationships across the organization.</ListItem>
                    </UnorderedList>
                </Text>

                {/* Bullets End */}

                <Text fontFamily="Unbounded" fontWeight="700"
                    fontSize={{ base: '20px', sm: '20px', md: '20px', lg: '24px' }}
                    lineHeight={{ base: '35px', sm: '35px', md: '35px', lg: '65px' }}
                    color="black"
                    mt={{ base: '8px', sm: '8px', md: '8px', lg: '10px' }}

                >
                    What We Offer </Text>

                <UnorderedList
                    fontFamily="Poppins" fontWeight="400"
                    fontSize={{ base: '15px', sm: '15px', md: '15px', lg: '18px' }}
                    color="black" lineHeight="28px"
                >
                    <ListItem>Competitive compensation and benefits package.</ListItem>
                    <ListItem>Opportunities for growth and development in a collaborative environment.</ListItem>
                    <ListItem>A supportive, inclusive culture where your contributions are valued.</ListItem>
                    <ListItem>A collaborative and inclusive work environment that values innovation and teamwork.</ListItem>
                    <ListItem>The chance to work closely with top leadership and make a tangible impact on company success.</ListItem>
                </UnorderedList>

                <Text fontFamily="Unbounded" fontWeight="700"
                    fontSize={{ base: '20px', sm: '20px', md: '20px', lg: '24px' }}
                    lineHeight={{ base: '35px', sm: '35px', md: '35px', lg: '65px' }}
                    color="black"
                    mt={{ base: '8px', sm: '8px', md: '8px', lg: '10px' }}

                >
                    How to Apply </Text>



                <Text fontFamily="Poppins" fontWeight="400"
                    fontSize={{ base: '17px', sm: '17px', md: '17px', lg: '20px' }}
                    color="black" lineHeight="28px">
                    If you’re ready to step into a critical role that supports leadership excellence, we’d love to hear from you! Please send your resume and a brief cover letter detailing your experience
                    and how you can contribute to Asture Consulting to


                </Text>
                <Text fontFamily="Poppins" fontWeight="400"
                    fontSize={{ base: '17px', sm: '17px', md: '17px', lg: '20px' }}
                    color="#06BCD0" lineHeight="28px">
                    <b>
                        <a href="mailto:post@asture.co" > post@asture.co.</a>
                    </b>

                </Text>


                <Text fontFamily="Poppins" fontWeight="400"
                    fontSize={{ base: '17px', sm: '17px', md: '17px', lg: '20px' }}
                    color="black" lineHeight="28px" mt="10px">
                    <b>Asture Consulting is an equal opportunity employer.</b> We celebrate diversity and are
                    committed to creating an inclusive workplace where everyone can thrive.
                </Text>

                <Text fontFamily="Poppins" fontWeight="400"
                    fontSize={{ base: '17px', sm: '17px', md: '17px', lg: '20px' }}
                    color="black" lineHeight="28px" mt="10px">
                    <b>Be the driving force behind our leadership’s success – apply now and grow with Asture Consulting!</b>
                </Text>


            </Box>
        );
    }
}

export default Executive_assistant;