import React from 'react';
import Slider from 'react-slick';
import { Box, IconButton, Img } from '@chakra-ui/react';
import Section1 from './Section1';

const CustomPrevArrow = (props) => {
  const { onClick } = props;
  return (
    <IconButton
      icon={<Img
        w={{ base: '30px', sm: '30px', md: '30px', lg: '50px' }}
        src="left.svg" alt="Previous" />}
      onClick={onClick}
      aria-label="Previous Slide"
      variant="unstyled"
      position="absolute"
      top="50%"
      left={{ base: '-35px', sm: '-38px', md: '-38px', lg: '-70px' }}
      transform="translateY(-50%)"
      zIndex="1"
    />
  );
};

const CustomNextArrow = (props) => {
  const { onClick } = props;
  return (
    <IconButton
      icon={<Img
        w={{ base: '30px', sm: '30px', md: '30px', lg: '50px' }}

        src="right.svg" alt="Previous" />}
      onClick={onClick}
      aria-label="Next Slide"
      variant="unstyled"
      position="absolute"
      top="50%"
      right={{ base: '-43px', sm: '-43px', md: '-43px', lg: '-70px' }}
      transform="translateY(-50%)"
      zIndex="1"
    />
  );
};

const MySlickSlider = () => {
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    prevArrow: <CustomPrevArrow />,
    nextArrow: <CustomNextArrow />,

  };


  return (
    <Box p={{ base: '20px', sm: '20px', md: '20px', lg: '50px' }}
      display={{ base: 'block', sm: 'block', md: 'block', lg: 'block' }}
    >
      <Slider {...settings}>
        <div>
          <Section1
            tesimonalimg="SveaCare_white.svg"
            maintext=" '' Partnering with Asture Consulting has been valuable
             for us at SveaCare. They guided us through a complete
              Microsoft 365 setup, managing everything from data migration
               and account configuration to implementation of MS Teams with 
               emphasise on effective and productive modern-work environment.
                Asture's hands-on training and adoption support have been valuable in guiding
                 our team through the Microsoft 365 onboarding process. We look forward to
                  collaborating with Asture on future IT projects '' "
            widthimg="1300px"
          />
        </div>
        <div>
          <Section1
            tesimonalimg="casusc.svg"
            maintext=" '' Asture has played an important role in our transition to Microsoft 365.
           They handled everything from tenant setup, configuration, 
           device enrollment, and helped us structure our MS Teams environment 
           based on our needs to foster a more effective and productive working environment. 
           Their adoption workstream was a key factor for us to adopt M365 smoothly. 
           Asture also did a thorough review of our current IT landscape, 
           highlighting areas for modernization and optimization. 
           With their guidance, we have streamlined our workflows 
           and replaced numerous manual tasks with automation. This transformation has enhanced our efficiency,
            allowing us to fully focus on what truly matters.''  "
            widthimg="1800px"

          />
        </div>
        <div>
          <Section1
            tesimonalimg="Iansa.svg"
            maintext=" '' We are grateful to Asture for their support in our digital transformation process. 
            Their full-scale implementation of Microsoft 365 has been very valuable. 
            They helped us structure our MS Teams environment in a way
             that significantly boosted our collaboration and productivity. 
             Their adoption activities and ongoing training have been key in enabling 
             our team at Insa to fully leverage the capabilities of Microsoft 365. 
             Asture has also been playing a key role in supporting our business 
             development, workflow and process optimization, and tailoring different 
             automation solutions based on our needs. With their ongoing support in Microsoft 365 
             and Dynamics 365, our operations remain smooth and efficient.''  "
            widthimg="1800px"
          />
        </div>
        <div>
          <Section1
            tesimonalimg="orpas.svg"
            maintext=" '' Asture Consulting helped us move over to Microsoft 365.
             They managed everything from tenant setup, data and email migration, 
             to configuring our SharePoint sites. 
             They structured our MS Teams environment to enhance better communication
              and collaboration with our clients. They helped us set up document templates, 
              and managed device enrollment, ensuring a seamless workflow. Their comprehensive 
              training and adoption support made our transition to M365 smooth and effective.
               What we value most with our collaboration with Asture is their quick response time, 
               ensuring we receive the support needed to keep our operations running smoothly and 
               efficiently''  "
            widthimg="1800px"
          />
        </div>
      </Slider>
    </Box>

  );
};

export default MySlickSlider;
