import { Box } from "@chakra-ui/react";
import { Component } from "react";
import Servicehero from "./Herosection";
import Cardservices from "./Cardservices";

class Servicespage extends Component {
    state = {  } 
    render() { 
        return (
            <Box>
                <Servicehero/>
                <Box px={{base:'20px',sm:'20px' ,md:'20px' , lg:'50px'}}>
<Cardservices/>
</Box>

            </Box>
        );
    }
}
 
export default Servicespage;