import { Box, Img, Text } from "@chakra-ui/react";
import { Component } from "react";
import Banner from "../ContactUspage/Banner";

class EmployeeExp extends Component {
    componentDidMount() {
        window.scrollTo(0, 0); // Scroll to the top of the page when the component mounts
    }
    state = {}
    render() {
        return (

            <Box>

                <Banner
                    imageSrc="Employee Experience 1.jpg"
                    text="Employee Experience
        "
                />


                <Box px={{ base: '20px', sm: '20px', md: '20px', lg: '50px' }}
                    w={{ base: 'none', sm: 'none', md: 'none', lg: '1300px' }}
                    margin="70px auto"

                >
                    <Text fontFamily="Unbounded" fontWeight="700"
                        fontSize={{ base: '35px', sm: '35px', md: '35px', lg: '54px' }}
                        color="#06BCD0"
                        lineHeight={{ base: 'none', sm: 'none', md: 'none', lg: '65px' }}
                        w={{ base: 'none', sm: 'none', md: 'none', lg: '850px' }}
                    >
                        Employee Experience Framework
                    </Text>
                    <Text fontFamily="Poppins" fontWeight="400"
                        fontSize={{ base: '17px', sm: '17px', md: '17px', lg: '20px' }}
                        color="black" lineHeight="28px" mt="30px">
                        Our <b>Employee Experience Framework</b> focuses on creating a connected, engaged, and empowered workforce by optimizing the tools, processes, and culture that support daily work. We believe that a positive employee experience drives productivity, innovation, and overall job satisfaction, helping businesses attract and retain top talent.
                    </Text>

                    <Text fontFamily="Unbounded" fontWeight="700" fontSize="24px"
                        color="black"
                        lineHeight={{ base: '35px', sm: '35px', md: '35px', lg: '65px' }}

                        mt={{ base: '8px', sm: '8px', md: '8px', lg: 'none' }}
                    >
                        Key Components of the Employee Experience Framework
                    </Text>

                    {/* 1st Box  */}
                    <Box>
                        <Text fontFamily="Poppins" fontWeight="600" fontSize="22px" color="black">
                            Digital Workspace Enablement      </Text>
                        <Text fontFamily="Poppins" fontWeight="500" fontSize="18px" color="black" mt="5px" >
                            Equipping employees with modern tools for seamless collaboration       </Text>
                        <Text fontFamily="Poppins" fontWeight="400" fontSize="18px" color="black" mt="5px" >
                            A well-designed digital workspace enhances communication and access to resources, enabling employees to work effectively from anywhere:
                        </Text>
                        <Box display="flex"
                            flexDirection={{ base: 'column', sm: 'column', md: 'column', xl: 'row' }}

                            gap="10px">
                            <Text fontFamily="Poppins" fontWeight="400" fontSize="16px" color="black" mt="5px" >
                                <b>1.Microsoft Teams and SharePoint:   </b>  </Text>
                            <Text fontFamily="Poppins" fontWeight="400" fontSize="16px" color="black"
                                mt={{ base: '-7px', sm: '-7px', md: '-7px', xl: '5px' }} >
                                Centralized communication and document sharing for real-time collaboration.
                            </Text>
                        </Box>
                        <Box display="flex"
                            flexDirection={{ base: 'column', sm: 'column', md: 'column', xl: 'row' }}

                            gap="10px">
                            <Text fontFamily="Poppins" fontWeight="400" fontSize="16px" color="black" mt="5px" >
                                <b>2.Self-Service Portals:   </b>  </Text>
                            <Text fontFamily="Poppins" fontWeight="400" fontSize="16px" color="black"

                                mt={{ base: '-7px', sm: '-7px', md: '-7px', xl: '5px' }} >
                                Access to company resources, FAQs, and service requests at employees’ fingertips.
                            </Text>
                        </Box>
                        <Box display="flex"
                            flexDirection={{ base: 'column', sm: 'column', md: 'column', xl: 'row' }}

                            gap="10px">
                            <Text fontFamily="Poppins" fontWeight="400" fontSize="16px" color="black" mt="5px" >
                                <b>3.Device Flexibility:  </b>  </Text>
                            <Text fontFamily="Poppins" fontWeight="400" fontSize="16px" color="black"
                                mt={{ base: '-7px', sm: '-7px', md: '-7px', xl: '5px' }} >
                                Support for a variety of devices (laptops, tablets, mobile) to promote a hybrid and remote work culture.
                            </Text>
                        </Box>
                        <Text fontFamily="Poppins" fontWeight="400" fontSize="18px" color="black" mt="5px" >
                            By providing employees with intuitive and integrated tools, we ensure they remain productive and connected.
                        </Text>
                    </Box>

                    {/* 2nd Box  */}
                    <Box mt="20px">
                        <Text fontFamily="Poppins" fontWeight="600" fontSize="22px" color="black" >
                            Employee Onboarding and Development     </Text>
                        <Text fontFamily="Poppins" fontWeight="500" fontSize="18px" color="black" mt="5px" >
                            Streamlining the onboarding process and fostering continuous learning     </Text>
                        <Text fontFamily="Poppins" fontWeight="400" fontSize="18px" color="black" mt="5px" >
                            A seamless onboarding experience and access to learning opportunities are crucial for employee growth and satisfaction:
                        </Text>
                        <Box display="flex"
                            flexDirection={{ base: 'column', sm: 'column', md: 'column', xl: 'row' }}

                            gap="10px">
                            <Text fontFamily="Poppins" fontWeight="400" fontSize="16px" color="black" mt="5px" >
                                <b>1.Automated Onboarding Workflows:  </b>  </Text>
                            <Text fontFamily="Poppins" fontWeight="400" fontSize="16px" color="black"
                                mt={{ base: '-7px', sm: '-7px', md: '-7px', xl: '5px' }} >
                                Digital checklists, pre-configured accounts, and virtual orientations.
                            </Text>
                        </Box>
                        <Box display="flex"
                            flexDirection={{ base: 'column', sm: 'column', md: 'column', xl: 'row' }}

                            gap="10px">
                            <Text fontFamily="Poppins" fontWeight="400" fontSize="16px" color="black" mt="5px" >
                                <b>2.Learning Management Systems (LMS):     </b>  </Text>
                            <Text fontFamily="Poppins" fontWeight="400" fontSize="16px" color="black"
                                mt={{ base: '-7px', sm: '-7px', md: '-7px', xl: '5px' }} >
                                Access to training modules and personalized development plans.
                            </Text>
                        </Box>
                        <Box display="flex"
                            flexDirection={{ base: 'column', sm: 'column', md: 'column', xl: 'row' }}

                            gap="10px">
                            <Text fontFamily="Poppins" fontWeight="400" fontSize="16px" color="black" mt="5px" >
                                <b>3.Mentorship and Knowledge Sharing:   </b>  </Text>
                            <Text fontFamily="Poppins" fontWeight="400" fontSize="16px" color="black"
                                mt={{ base: '-7px', sm: '-7px', md: '-7px', xl: '5px' }} >
                                Platforms that facilitate peer mentoring and expert-led sessions.
                            </Text>
                        </Box>
                        <Text fontFamily="Poppins" fontWeight="400" fontSize="18px" color="black" mt="5px" >
                            By simplifying onboarding and promoting upskilling, we create an environment where employees can thrive.
                        </Text>
                    </Box>


                    {/* 3rd Box  */}
                    <Box mt="20px">
                        <Text fontFamily="Poppins" fontWeight="600" fontSize="22px" color="black" >
                            Feedback and Engagement    </Text>
                        <Text fontFamily="Poppins" fontWeight="500" fontSize="18px" color="black" mt="5px" >
                            Encouraging continuous communication and fostering a feedback culture       </Text>
                        <Text fontFamily="Poppins" fontWeight="400" fontSize="18px" color="black" mt="5px" >
                            Employee engagement relies on open communication and an inclusive feedback loop:
                        </Text>
                        <Box display="flex"
                            flexDirection={{ base: 'column', sm: 'column', md: 'column', xl: 'row' }}

                            gap="10px">
                            <Text fontFamily="Poppins" fontWeight="400" fontSize="16px" color="black" mt="5px" >
                                <b>1.Pulse Surveys:   </b>  </Text>
                            <Text fontFamily="Poppins" fontWeight="400" fontSize="16px" color="black"
                                mt={{ base: '-7px', sm: '-7px', md: '-7px', xl: '5px' }} >
                                Regular surveys to gather insights on employee sentiment.
                            </Text>
                        </Box>
                        <Box display="flex"
                            flexDirection={{ base: 'column', sm: 'column', md: 'column', xl: 'row' }}

                            gap="10px">
                            <Text fontFamily="Poppins" fontWeight="400" fontSize="16px" color="black" mt="5px" >
                                <b>2.Employee Experience Dashboards:    </b>  </Text>
                            <Text fontFamily="Poppins" fontWeight="400" fontSize="16px" color="black"
                                mt={{ base: '-7px', sm: '-7px', md: '-7px', xl: '5px' }} >
                                Visualization of feedback trends to inform decision-making.
                            </Text>
                        </Box>
                        <Box display="flex"
                            flexDirection={{ base: 'column', sm: 'column', md: 'column', xl: 'row' }}

                            gap="10px">
                            <Text fontFamily="Poppins" fontWeight="400" fontSize="16px" color="black" mt="5px" >
                                <b>3.Recognition and Rewards Systems:    </b>  </Text>
                            <Text fontFamily="Poppins" fontWeight="400" fontSize="16px" color="black"
                                mt={{ base: '-7px', sm: '-7px', md: '-7px', xl: '5px' }} >
                                Platforms for acknowledging employee achievements and milestones.
                            </Text>
                        </Box>
                        <Text fontFamily="Poppins" fontWeight="400" fontSize="18px" color="black" mt="5px" >
                            These tools foster a culture of transparency and recognition, improving overall morale and engagement.
                        </Text>
                    </Box>

                    {/* 4th Box  */}
                    <Box mt="20px">
                        <Text fontFamily="Poppins" fontWeight="600" fontSize="22px" color="black" >
                            Well-being and Work-Life Balance    </Text>
                        <Text fontFamily="Poppins" fontWeight="500" fontSize="18px" color="black" mt="5px" >
                            Promoting a healthy work environment       </Text>
                        <Text fontFamily="Poppins" fontWeight="400" fontSize="18px" color="black" mt="5px" >
                            Employee well-being is central to a positive workplace experience:
                        </Text>
                        <Box display="flex"
                            flexDirection={{ base: 'column', sm: 'column', md: 'column', xl: 'row' }}

                            gap="10px">
                            <Text fontFamily="Poppins" fontWeight="400" fontSize="16px" color="black" mt="5px" >
                                <b>1.Wellness Programs:    </b>  </Text>
                            <Text fontFamily="Poppins" fontWeight="400" fontSize="16px" color="black"
                                mt={{ base: '-7px', sm: '-7px', md: '-7px', xl: '5px' }} >
                                Initiatives that support mental, physical, and emotional health.
                            </Text>
                        </Box>
                        <Box display="flex"
                            flexDirection={{ base: 'column', sm: 'column', md: 'column', xl: 'row' }}

                            gap="10px">
                            <Text fontFamily="Poppins" fontWeight="400" fontSize="16px" color="black" mt="5px" >
                                <b>2.Flexible Work Arrangements:    </b>  </Text>
                            <Text fontFamily="Poppins" fontWeight="400" fontSize="16px" color="black"
                                mt={{ base: '-7px', sm: '-7px', md: '-7px', xl: '5px' }} >
                                Options for hybrid schedules, remote work, and flexible hours.
                            </Text>
                        </Box>
                        <Box display="flex"
                            flexDirection={{ base: 'column', sm: 'column', md: 'column', xl: 'row' }}

                            gap="10px">
                            <Text fontFamily="Poppins" fontWeight="400" fontSize="16px" color="black" mt="5px" >
                                <b>3.Virtual Collaboration Spaces:    </b>  </Text>
                            <Text fontFamily="Poppins" fontWeight="400" fontSize="16px" color="black"
                                mt={{ base: '-7px', sm: '-7px', md: '-7px', xl: '5px' }} >
                                Tools that encourage virtual social interactions and team-building activities.
                            </Text>
                        </Box>
                        <Text fontFamily="Poppins" fontWeight="400" fontSize="18px" color="black" mt="5px" >
                            By prioritizing well-being, organizations can create an inclusive and supportive culture.
                        </Text>
                    </Box>

                    {/* 5th Box  */}
                    <Box mt="20px">
                        <Text fontFamily="Poppins" fontWeight="600" fontSize="22px" color="black">
                            Performance and Career Growth    </Text>
                        <Text fontFamily="Poppins" fontWeight="500" fontSize="18px" color="black" mt="5px" >
                            Supporting long-term employee success     </Text>
                        <Text fontFamily="Poppins" fontWeight="400" fontSize="18px" color="black" mt="5px" >
                            An effective performance management system aligns employee contributions with organizational goals:
                        </Text>
                        <Box display="flex"
                            flexDirection={{ base: 'column', sm: 'column', md: 'column', xl: 'row' }}

                            gap="10px">
                            <Text fontFamily="Poppins" fontWeight="400" fontSize="16px" color="black" mt="5px" >
                                <b>1.Goal Setting and Tracking:     </b>  </Text>
                            <Text fontFamily="Poppins" fontWeight="400" fontSize="16px" color="black"
                                mt={{ base: '-7px', sm: '-7px', md: '-7px', xl: '5px' }} >
                                Platforms for setting clear objectives and monitoring progress.
                            </Text>
                        </Box>
                        <Box display="flex"
                            flexDirection={{ base: 'column', sm: 'column', md: 'column', xl: 'row' }}

                            gap="10px">
                            <Text fontFamily="Poppins" fontWeight="400" fontSize="16px" color="black" mt="5px" >
                                <b>2.Continuous Feedback:     </b>  </Text>
                            <Text fontFamily="Poppins" fontWeight="400" fontSize="16px" color="black"
                                mt={{ base: '-7px', sm: '-7px', md: '-7px', xl: '5px' }} >
                                Real-time feedback and coaching to support performance improvement.
                            </Text>
                        </Box>
                        <Box display="flex"
                            flexDirection={{ base: 'column', sm: 'column', md: 'column', xl: 'row' }}

                            gap="10px">
                            <Text fontFamily="Poppins" fontWeight="400" fontSize="16px" color="black" mt="5px" >
                                <b>3.Career Path Planning:     </b>  </Text>
                            <Text fontFamily="Poppins" fontWeight="400" fontSize="16px" color="black"
                                mt={{ base: '-7px', sm: '-7px', md: '-7px', xl: '5px' }} >
                                Clear frameworks for internal mobility and promotions.
                            </Text>
                        </Box>
                        <Text fontFamily="Poppins" fontWeight="400" fontSize="18px" color="black" mt="5px" >
                            By investing in career development, businesses foster a sense of purpose and long-term loyalty among their employees.


                        </Text>
                    </Box>

                    <Text fontFamily="Unbounded" fontWeight="700" fontSize="24px"
                        color="black"
                        lineHeight={{ base: '35px', sm: '35px', md: '35px', lg: '65px' }}

                        mt={{ base: '8px', sm: '8px', md: '8px', lg: 'none' }}
                    >
                        Business Outcomes of the Employee Experience Framework         </Text>

                    <Box display="flex"
                        flexDirection={{ base: 'column', sm: 'column', md: 'column', xl: 'row' }}

                        gap="5px">
                        <Text fontFamily="Poppins" fontWeight="400"
                            fontSize={{ base: '16px', sm: '16px', md: '16px', lg: '17px' }}
                            color="black">
                            <b>Increased Employee Engagement:  </b>
                        </Text>
                        <Text fontFamily="Poppins" fontWeight="400"
                            fontSize={{ base: '16px', sm: '16px', md: '16px', lg: '17px' }}
                            color="black">
                            Foster a motivated and empowered workforce.
                        </Text>
                    </Box>


                    <Box display="flex"
                        flexDirection={{ base: 'column', sm: 'column', md: 'column', xl: 'row' }}

                        gap="5px">
                        <Text fontFamily="Poppins" fontWeight="400"
                            fontSize={{ base: '16px', sm: '16px', md: '16px', lg: '17px' }}
                            color="black">
                            <b>Improved Productivity:</b>
                        </Text>
                        <Text fontFamily="Poppins" fontWeight="400"
                            fontSize={{ base: '16px', sm: '16px', md: '16px', lg: '17px' }}
                            color="black">
                            Provide the right tools and support for efficient, high-quality work.
                        </Text>
                    </Box>

                    <Box display="flex"
                        flexDirection={{ base: 'column', sm: 'column', md: 'column', xl: 'row' }}

                        gap="5px">
                        <Text fontFamily="Poppins" fontWeight="400"
                            fontSize={{ base: '16px', sm: '16px', md: '16px', lg: '17px' }}
                            color="black">
                            <b>Higher Talent Retention:</b>
                        </Text>
                        <Text fontFamily="Poppins" fontWeight="400"
                            fontSize={{ base: '16px', sm: '16px', md: '16px', lg: '17px' }}
                            color="black">
                            Reduce turnover by creating a positive, growth-oriented work culture.
                        </Text>
                    </Box>

                    <Box display="flex"
                        flexDirection={{ base: 'column', sm: 'column', md: 'column', xl: 'row' }}

                        gap="5px">
                        <Text fontFamily="Poppins" fontWeight="400"
                            fontSize={{ base: '16px', sm: '16px', md: '16px', lg: '17px' }}
                            color="black">
                            <b>Enhanced Collaboration: </b>
                        </Text>
                        <Text fontFamily="Poppins" fontWeight="400"
                            fontSize={{ base: '16px', sm: '16px', md: '16px', lg: '17px' }}
                            color="black">
                            Enable seamless communication across teams and departments.
                        </Text>
                    </Box>

                    <Box display="flex"
                        flexDirection={{ base: 'column', sm: 'column', md: 'column', xl: 'row' }}

                        gap="5px">
                        <Text fontFamily="Poppins" fontWeight="400"
                            fontSize={{ base: '16px', sm: '16px', md: '16px', lg: '17px' }}
                            color="black">
                            <b>Strengthened Employer Brand:  </b>
                        </Text>
                        <Text fontFamily="Poppins" fontWeight="400"
                            fontSize={{ base: '16px', sm: '16px', md: '16px', lg: '17px' }}
                            color="black">
                            Position your organization as a top employer that values its workforce.
                        </Text>
                    </Box>

                    <Text fontFamily="Unbounded" fontWeight="700" fontSize="24px"
                        color="black"
                        lineHeight={{ base: '35px', sm: '35px', md: '35px', lg: '65px' }}

                        mt={{ base: '8px', sm: '8px', md: '8px', lg: 'none' }}
                    >
                        Why Choose Asture Consulting?
                    </Text>

                    <Text fontFamily="Poppins" fontWeight="400"
                        fontSize={{ base: '17px', sm: '17px', md: '17px', lg: '20px' }}
                        color="black" lineHeight="28px">
                        At <b>Asture Consulting,</b> we understand that exceptional employee experiences drive organizational success. Our Employee Experience Framework is designed to address the unique needs of your workforce by integrating modern tools, processes, and culture-building initiatives.
                    </Text>

                    <Text fontFamily="Poppins" fontWeight="400"
                        fontSize={{ base: '17px', sm: '17px', md: '17px', lg: '20px' }}
                        color="black" lineHeight="28px" mt="30px">
                        From onboarding and performance management to engagement and well-being programs, we provide end-to-end support to ensure your employees feel valued, connected, and equipped to succeed.
                    </Text>

                    <Text fontFamily="Poppins" fontWeight="400"
                        fontSize={{ base: '17px', sm: '17px', md: '17px', lg: '20px' }}
                        color="black" lineHeight="28px" mt="30px">
                        <b> Next Steps:</b> Looking to enhance your employee experience? Contact <b>Asture Consulting</b> today to learn how our tailored framework can help you foster a more engaged and productive workforce.
                    </Text>

                    <Img mt="30px" src="Business Process Automation 2.png" />

                </Box>

            </Box>


        );
    }
}

export default EmployeeExp;