import { Box, Img, Text } from "@chakra-ui/react";
import { Component } from "react";

class Aboutcompany extends Component {
    state = {}
    render() {
        return (<Box display="flex"
            alignItems={{ base: 'none', sm: 'none', md: 'none', lg: 'center', '2xl': 'none' }}
            flexDirection={{ base: 'column', sm: 'column', md: 'column', lg: 'row' }}
            gap={{ base: '20px', sm: '20px', md: '20px', lg: '20px', '2xl': '100px' }}


        >
            <Box>
                <Text fontFamily="Poppins
" fontWeight="500"
                    fontSize={{ base: '16px', sm: '16px', md: '16px', lg: '20px' }}
                    color="black"
                    letterSpacing={{ base: '5px', sm: '5px', md: '5px', lg: '10px' }}
                    textTransform="uppercase"
                >Who we are</Text>
                <Text fontFamily="Unbounded
" fontWeight="700"
                    fontSize={{ base: '26px', sm: '26px', md: '26px', lg: '45px', xl: '45px', '2xl': '54px' }}
                    color="#06BCD0"
                    lineHeight={{ base: '33px', sm: '33px', md: '33px', lg: '64px' }}
                    w={{ base: 'none', sm: 'none', md: 'none', lg: '700px' }}
                    pt={{ base: '10px', sm: '10px', md: '10px', lg: 'none' }}
                >
                    Custom IT Solutions for Your Business</Text>
                <Text fontFamily="Poppins"
                    fontWeight="400"
                    fontSize={{ base: '17px', sm: '17px', md: '17px', lg: '20px' }}
                    color="black"
                    lineHeight="28px"
                    pt={{ base: '20px', sm: '20px', md: '20px', lg: '30px' }}
                    width={{ base: 'none', sm: 'none', md: 'none', lg: '700px', '2xl': '950px' }}
                >
                    At Asture, we are a team of visionaries, strategists, and innovators united by a commitment to excellence. With backgrounds spanning diverse industries and areas of expertise, our team is driven by a shared belief in the power of astute insights and informed action. We pride ourselves on our ability to deeply understand each client’s unique challenges and to deliver solutions that are not only intelligent but transformative. As trusted partners in our clients' success, we are relentless in our pursuit of new perspectives and solutions, always keeping our focus on unlocking growth, efficiency, and value. At Asture, we don’t just solve problems; we shape futures.                 </Text>
                {/* <Text fontFamily="Poppins"
                    fontWeight="400"
                    fontSize={{ base: '17px', sm: '17px', md: '17px', lg: '20px' }}
                    color="black"
                    lineHeight="28px"
                    pt={{ base: '10px', sm: '10px', md: '10px', lg: '30px' }}
                    width={{ base: 'none', sm: 'none', md: 'none', lg: '700px', '2xl': '950px' }}
                >
                    Our team brings deep experience from globally recognized IT companies, excelling in Microsoft 365 implementation, cloud migration, and custom app development. Whether it’s automating business processes through Power Platform, delivering comprehensive Dynamics 365 solutions, or ensuring cybersecurity and IT infrastructure management, we are dedicated to creating secure, scalable, and future-proof environments.
                </Text>

                <Text fontFamily="Poppins"
                    fontWeight="400"
                    fontSize={{ base: '17px', sm: '17px', md: '17px', lg: '20px' }}
                    color="black"
                    lineHeight="28px"
                    pt={{ base: '10px', sm: '10px', md: '10px', lg: '20px' }}
                    width={{ base: 'none', sm: 'none', md: 'none', lg: '700px', '2xl': '950px' }}
                >
                    From managed IT services to hybrid workplace solutions, we tailor our offerings to meet the unique needs of industries across the globe. At Asture Consulting, we don’t just implement technology—we help businesses thrive by aligning IT with strategic goals, ensuring long-term success in the digital age.
                </Text>

                <Text fontFamily="Poppins"
                    fontWeight="400"
                    fontSize={{ base: '17px', sm: '17px', md: '17px', lg: '20px' }}
                    color="black"
                    lineHeight="28px"
                    pt={{ base: '10px', sm: '10px', md: '10px', lg: '20px' }}
                    width={{ base: 'none', sm: 'none', md: 'none', lg: '700px', '2xl': '950px' }}
                >
                    Strategic Vision,Smart Execution —that’s what sets us apart.


                </Text> */}
            </Box>
            <Box>
                <Img
                    pt={{ base: '10px', sm: '10px', md: '10px', lg: 'none' }}
                    src="aboutcompany.png" />
            </Box>
        </Box>
        );
    }
}

export default Aboutcompany;