import { Box, Flex, Img, ListItem, Text, UnorderedList } from "@chakra-ui/react";
import { Component } from "react";
import Banner from "../ContactUspage/Banner";

class Digitalstrategy extends Component {
    componentDidMount() {
        window.scrollTo(0, 0); // Scroll to the top of the page when the component mounts
      }
    state = {  } 
    render() { 
        return (

          <Box>

<Banner 
        imageSrc="Digital Strategy.jpg" 
        text="Digital Strategy" 
      />


<Box px={{base:'20px',sm:'20px' ,md:'20px' , lg:'50px'}}
            w={{base:'none',sm:'none' ,md:'none' , lg:'1300px'}}
            margin="70px auto"
 
             >
                   <Text fontFamily="Unbounded" fontWeight="700" 
                   fontSize={{base:'35px',sm:'35px',md:'35px',lg:'54px'}}
                    color="#06BCD0" lineHeight="65px" 
                    w={{base:'none',sm:'none' ,md:'none' , lg:'850px'}}
                    >
                    Digital Strategy
                 </Text>
                 <Text fontFamily="Poppins" fontWeight="400" 
                  fontSize={{base:'17px',sm:'17px',md:'17px',lg:'20px'}}
                  color="black" lineHeight="28px" mt="30px">
                 In today’s fast-paced digital world, a clear and actionable Digital Strategy is essential for businesses looking to stay ahead of the competition. At Asture Consulting, we help businesses design and implement comprehensive digital strategies that align with their long-term goals. From evaluating current technological capabilities to creating a roadmap for digital transformation, our experts ensure that your business is equipped to succeed in the modern digital landscape. 
                 </Text>
        
 
 
                 <Text fontFamily="Unbounded" fontWeight="700" fontSize="24px"
                    color="black" lineHeight="65px"
                     mt={{base:'8px',sm:'8px',md:'8px',lg:'67px'}}
                     
                     >
                Key Components: 
                 </Text>
 
       <Text fontFamily="Poppins" fontWeight="500" fontSize="20px" color="black">
    Digital Transformation Roadmaps:  </Text>
  <Text fontFamily="Poppins" fontWeight="400" fontSize="16px" color="black"mt="5px" >
  Customized plans to guide your business through adopting and scaling new digital technologies. 
  </Text>

    <Text fontFamily="Poppins" fontWeight="500" fontSize="20px" color="black" pt="20px">
  Technology Evaluation:    </Text>
  <Text fontFamily="Poppins" fontWeight="400" fontSize="16px" color="black" mt="5px" >
  Assess your existing systems and identify opportunities for modernization and optimization.    </Text>

     <Text fontFamily="Poppins" fontWeight="500" fontSize="20px" color="black" pt="20px" >
  Strategic Partnerships:    </Text>
  <Text fontFamily="Poppins" fontWeight="400" fontSize="16px" color="black" mt="5px" >
  We leverage key technology partners like Microsoft to deliver innovative solutions that <br/>enhance productivity and streamline operations.      </Text>


<Text fontFamily="Poppins" fontWeight="400" 
                  fontSize={{base:'17px',sm:'17px',md:'17px',lg:'20px'}}
                  color="black" lineHeight="28px" mt="20px">
Our Digital Strategy services are designed to not only meet your current needs but also future-proof your business. We work closely with your leadership team to ensure that every technological investment maximizes returns and delivers measurable business outcomes. 
                 </Text>
                 <Img mt="30px" src="Digital Strategy1.jpg" />
 
             </Box>
          </Box>

           
        );
    }
}
 
export default Digitalstrategy;