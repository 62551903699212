import { Box, Button, Link, Text } from "@chakra-ui/react";
import { Component } from "react";
import { Link as RouterLink } from 'react-router-dom'; // Import RouterLink from react-router-dom

class Hero extends Component {
    render(){
        const handleScroll = (e) => {
            e.preventDefault();
            const targetId = e.currentTarget.getAttribute("href").substring(1);
            const targetElement = document.getElementById(targetId);
            if (targetElement) {
              targetElement.scrollIntoView({
                behavior: "smooth",
                block: "start",
              });
            }
          };
        return  <Box
        position="relative"
        h={{ base: '430px', sm: '430px', md: '430px', lg: '1080px' }}
        mt={{ base: '0px', sm: '0px', md: '0px', lg: '-92px' }}
        overflow="hidden"
      >
        {/* Background Video */}
        <Box
          as="video"
          autoPlay
          loop
          muted
          playsInline
          position="absolute"
          top="0"
          left="0"
          w="100%"
          h="100%"
          objectFit="cover"
          zIndex="-1"
        >
          <source src="V1.mp4" type="video/mp4" />
          Your browser does not support the video tag.
        </Box>
  
        {/* Content */}
        <Box
          pt="320px"
          pl="50px"
          display={{ base: 'none', sm: 'none', md: 'none', lg: 'block' }}
          position="relative"
          zIndex="1"
        >
          <Text fontFamily="Poppins" fontWeight="500" fontSize="20px" color="#06BCD0" letterSpacing="10px">
            EMPOWERMENT
          </Text>
          <Text fontFamily="Unbounded" fontWeight="400" fontSize="70px" color="white" lineHeight="84px" width="1280px">
            End-to-End Microsoft Consulting Services
          </Text>
          <Text fontFamily="Poppins" fontWeight="400" fontSize="20px" color="white" lineHeight="28px" width="595px" pt="30px">
            We are a full-service leading Web & App Development company offering a wide range of digital solutions.
          </Text>
          <Link _hover="none">
            <Button
              mt="39px"
              fontFamily="Poppins"
              fontWeight="400"
              fontSize="20px"
              color="white"
              lineHeight="24px"
              bgColor="#06BCD0"
              w="179px"
              h="64px"
              rightIcon={<img src="icon_btn.png" alt="icon" />}
              colorScheme="none"
              as={RouterLink}
              to="/Contactpage"
            >
              Let's Talk
            </Button>
          </Link>
        </Box>
      </Box>
    }
}

 
export default Hero;