import { Box, Img, Text } from "@chakra-ui/react";
import { Component, useState } from "react";
import { Link, Link as RouterLink } from "react-router-dom"; // Import RouterLink for Chakra's Link component





class Services extends Component {


    state = {}
    render() {

        return (
            <Box>

                <Box className="textbox"
                    display="flex"
                    flexDirection={{ base: 'column', sm: 'column', md: 'column', lg: 'row' }}

                    justifyContent="space-between"
                    alignItems="center"
                    gap={{ base: '20px', sm: '20px', md: '20px', lg: '30px', '2xl': '100px' }}
                >

                    <Box >
                        <Text fontFamily="Poppins
" fontWeight="500"
                            fontSize={{ base: '16px', sm: '16px', md: '16px', lg: '20px' }}
                            color="black"
                            letterSpacing={{ base: '5px', sm: '5px', md: '5px', lg: '10px' }}
                            textTransform="uppercase"
                            id="services"
                        >WHAT WE’RE OFFERING</Text>
                        <Text fontFamily="Unbounded"
                            fontWeight={{ base: '700', sm: '700', md: '700', lg: '700' }}
                            fontSize={{ base: '26px', sm: '26px', md: '26px', lg: '45px', '2xl': '54px' }}
                            color="#06BCD0"
                            lineHeight={{ base: '33px', sm: '33px', md: '33px', lg: '72px' }}
                            w={{ base: 'none', sm: 'none', md: 'none', lg: '800px', '2xl': '941px' }}

                        >
                            Dealing in all professional
                            IT services.</Text>

                    </Box>

                    <Box>
                        <Text fontFamily="Poppins"
                            fontWeight="400"
                            fontSize={{ base: '17px', sm: '17px', md: '17px', lg: '20px' }}
                            color="black" lineHeight="28px"

                        >
                            One fundamental aspect of IT services is infrastructure management.
                            This involves the design, implementation, and maintenance of the
                            hardware, software, networks, and servers.</Text>
                    </Box>


                </Box>

                {/* For laptop */}

                <Box className="mainBoxes"
                    mt={{ base: '30px', sm: '30px', md: '30px', lg: '80px' }}
                    display={{ base: 'none', sm: 'none', md: 'none', lg: 'flex' }}
                    flexDirection="column"
                    alignItems="center"
                >


                    <Box className="1stbox" display="flex"
                        flexDirection={{ base: 'column', sm: 'column', md: 'column', lg: 'row' }}
                        gap="30px">


                        <Link as={RouterLink} to="/Asture_consult" _hover={{ textDecoration: "none" }}>


                            <Box bg="#F6F8F8"
                                height={{ base: 'none', sm: 'none', md: 'none', lg: '450px' }}
                                w="100%"
                                maxW="600px"
                                _hover={{
                                    bg: '#EBF0EF',
                                    color: '#06BCD0',
                                    img: {
                                        filter: "brightness(0) saturate(100%) invert(70%) sepia(31%) saturate(6836%) hue-rotate(143deg) brightness(95%) contrast(95%)"
                                    },
                                }}

                                p="50px"
                            >

                                <Img
                                    src="Asture Consult.png" />
                                <Text
                                    fontFamily="Unbounded"
                                    fontWeight={{ base: '700', sm: '700', md: '700', lg: '800' }}
                                    fontSize={{ base: '29px', sm: '29px', md: '29px', lg: '36px' }}
                                    lineHeight={{ base: '41px', sm: '41px', md: '41px', lg: '53px' }}
                                    pt="90px"

                                >Asture Consult</Text>

                                <Text fontFamily="Poppins"
                                    fontWeight="400"
                                    fontSize={{ base: '18px', sm: '18px', md: '18px', lg: '20px' }}
                                    lineHeight="28px"
                                    color="#0F1414"
                                    pt={{ base: '5px', sm: '5px', md: '5px', lg: '20px' }}
                                >
                                    Flexible, on-demand IT consulting designed to help your business innovate and grow.</Text>

                            </Box>

                        </Link>


                        <Link as={RouterLink} to="/Digital_strategy" _hover={{ textDecoration: "none" }}>


                            <Box bg="#F6F8F8"
                                height={{ base: 'none', sm: 'none', md: 'none', lg: '450px' }}
                                w="100%"
                                maxW="600px"
                                _hover={{
                                    bg: '#EBF0EF',
                                    color: '#06BCD0',
                                    img: {
                                        filter: "brightness(0) saturate(100%) invert(70%) sepia(31%) saturate(6836%) hue-rotate(143deg) brightness(95%) contrast(95%)"
                                    },
                                }}
                                p="50px"
                            >
                                <Img src="Digital Strategy.png" />
                                <Text fontFamily="Unbounded"
                                    fontWeight={{ base: '700', sm: '700', md: '700', lg: '800' }}
                                    fontSize={{ base: '29px', sm: '29px', md: '29px', lg: '36px' }}
                                    lineHeight={{ base: '41px', sm: '41px', md: '41px', lg: '53px' }}
                                    pt="90px"
                                >Digital Strategy </Text>
                                <Text fontFamily="Poppins"
                                    fontWeight="400"
                                    fontSize={{ base: '18px', sm: '18px', md: '18px', lg: '20px' }}
                                    color="#0F1414" lineHeight="28px"
                                    pt={{ base: '5px', sm: '5px', md: '5px', lg: '20px' }}
                                >
                                    Your tailored roadmap to digital transformation, aligning technology with your vision.</Text>

                            </Box>

                        </Link>


                        <Link as={RouterLink} to="/Powerdetail" _hover={{ textDecoration: "none" }}>


                            <Box bg="#F6F8F8"
                                height={{ base: 'none', sm: 'none', md: 'none', lg: '450px' }}
                                w="100%"
                                maxW="600px"
                                _hover={{
                                    bg: '#EBF0EF'
                                    ,
                                    color: '#06BCD0',
                                    img: {
                                        filter: "brightness(0) saturate(100%) invert(70%) sepia(31%) saturate(6836%) hue-rotate(143deg) brightness(95%) contrast(95%)"
                                    },
                                }}
                                p="50px"
                            >
                                <Img src="Microsoft.svg" />
                                <Text fontFamily="Unbounded"
                                    fontWeight={{ base: '700', sm: '700', md: '700', lg: '800' }}
                                    fontSize={{ base: '29px', sm: '29px', md: '29px', lg: '36px' }}
                                    lineHeight={{ base: '41px', sm: '41px', md: '41px', lg: '53px' }}
                                    pt="90px"
                                >Power Platform</Text>
                                <Text fontFamily="Poppins"
                                    fontWeight="400"
                                    fontSize={{ base: '18px', sm: '18px', md: '18px', lg: '20px' }}
                                    color="#0F1414" lineHeight="28px"
                                    pt={{ base: '5px', sm: '5px', md: '5px', lg: '20px' }}
                                >
                                    Build apps, automate workflows, and harness data insights to elevate productivity.</Text>

                            </Box>

                        </Link>

                    </Box>





                    <Box className="2ndtbox" display="flex"
                        flexDirection={{ base: 'column', sm: 'column', md: 'column', lg: 'row' }}
                        gap="30px" mt="30px">




                        <Link as={RouterLink} to="/Microsoft365" _hover={{ textDecoration: "none" }}>


                            <Box bg="#F6F8F8"
                                height={{ base: 'none', sm: 'none', md: 'none', lg: '450px' }}
                                w="100%"
                                maxW="600px"
                                _hover={{
                                    bg: '#EBF0EF'
                                    ,
                                    color: '#06BCD0',
                                    img: {
                                        filter: "brightness(0) saturate(100%) invert(70%) sepia(31%) saturate(6836%) hue-rotate(143deg) brightness(95%) contrast(95%)"
                                    },
                                }}
                                p="50px"
                            >
                                <Img src="Microsoft 365.png" />
                                <Text fontFamily="Unbounded"
                                    fontWeight={{ base: '700', sm: '700', md: '700', lg: '800' }}
                                    fontSize={{ base: '29px', sm: '29px', md: '29px', lg: '36px' }}
                                    lineHeight={{ base: '41px', sm: '41px', md: '41px', lg: '53px' }}
                                    pt="90px"
                                >Microsoft 365</Text>
                                <Text fontFamily="Poppins"
                                    fontWeight="400"
                                    fontSize={{ base: '18px', sm: '18px', md: '18px', lg: '20px' }}
                                    color="#0F1414" lineHeight="28px"
                                    pt={{ base: '5px', sm: '5px', md: '5px', lg: '20px' }}
                                >
                                    Empowering collaboration, security, and productivity through Microsoft’s leading cloud suite.</Text>

                            </Box>

                        </Link>




                        <Link as={RouterLink} to="/Copilotdetail" _hover={{ textDecoration: "none" }}>


                            <Box bg="#F6F8F8"
                                height={{ base: 'none', sm: 'none', md: 'none', lg: '450px' }}
                                w="100%"
                                maxW="600px"
                                _hover={{
                                    bg: '#EBF0EF'
                                    ,
                                    color: '#06BCD0',
                                    img: {
                                        filter: "brightness(0) saturate(100%) invert(70%) sepia(31%) saturate(6836%) hue-rotate(143deg) brightness(95%) contrast(95%)"
                                    },
                                }}
                                p="50px"
                            >
                                <Img src="copilo.svg" />
                                <Text fontFamily="Unbounded"
                                    fontWeight={{ base: '700', sm: '700', md: '700', lg: '800' }}
                                    fontSize={{ base: '29px', sm: '29px', md: '29px', lg: '36px' }}
                                    lineHeight={{ base: '41px', sm: '41px', md: '41px', lg: '53px' }}
                                    pt="90px"
                                >Microsoft Copilot</Text>
                                <Text fontFamily="Poppins"
                                    fontWeight="400"
                                    fontSize={{ base: '18px', sm: '18px', md: '18px', lg: '20px' }}
                                    color="#0F1414" lineHeight="28px"
                                    pt={{ base: '5px', sm: '5px', md: '5px', lg: '20px' }}
                                >
                                    AI-powered support to streamline work and enhance productivity within Microsoft 365.</Text>

                            </Box>
                        </Link>


                        <Link as={RouterLink} to="/Dynamicsdetail" _hover={{ textDecoration: "none" }}>


                            <Box bg="#F6F8F8"
                                height={{ base: 'none', sm: 'none', md: 'none', lg: '450px' }}
                                w="100%"
                                maxW="600px"
                                _hover={{
                                    bg: '#EBF0EF'
                                    ,
                                    color: '#06BCD0',
                                    img: {
                                        filter: "brightness(0) saturate(100%) invert(70%) sepia(31%) saturate(6836%) hue-rotate(143deg) brightness(95%) contrast(95%)"
                                    },
                                }}
                                p="50px"
                            >
                                <Img src="PowerPlatform.svg" />
                                <Text fontFamily="Unbounded"
                                    fontWeight={{ base: '700', sm: '700', md: '700', lg: '800' }}
                                    fontSize={{ base: '29px', sm: '29px', md: '29px', lg: '36px' }}
                                    lineHeight={{ base: '41px', sm: '41px', md: '41px', lg: '53px' }}
                                    pt="90px"
                                >Dynamic 365 </Text>
                                <Text fontFamily="Poppins"
                                    fontWeight="400"
                                    fontSize={{ base: '18px', sm: '18px', md: '18px', lg: '20px' }}
                                    color="#0F1414" lineHeight="28px"
                                    pt={{ base: '5px', sm: '5px', md: '5px', lg: '20px' }}
                                >
                                    Comprehensive ERP and CRM solutions to drive operational efficiency and customer engagement.</Text>

                            </Box>

                        </Link>

                    </Box>

                </Box>

                {/* For mobile         */}

                <Box className="mainBoxes"
                    mt={{ base: '30px', sm: '30px', md: '30px', lg: '80px' }}
                    display={{ base: 'flex', sm: 'flex', md: 'flex', lg: 'none' }}
                    flexDirection="column"
                    alignItems="center"
                >


                    <Box className="1stbox" display="flex"
                        flexDirection={{ base: 'column', sm: 'column', md: 'column', lg: 'row' }}
                        gap="30px"
                    >


                        <Link as={RouterLink} to="/Asture_consult" _hover={{ textDecoration: "none" }}>


                            <Box bg="#F6F8F8"
                                height={{ base: 'none', sm: 'none', md: 'none', lg: '450px' }}
                                _hover={{
                                    bg: '#EBF0EF',
                                    color: '#06BCD0',
                                    img: {
                                        filter: "brightness(0) saturate(100%) invert(70%) sepia(31%) saturate(6836%) hue-rotate(143deg) brightness(95%) contrast(95%)"
                                    },
                                }}

                                p="25px"
                                display="flex"
                                gap="30px"
                            >

                                <Box>
                                    <Img
                                        src="Asture Consult.png" />
                                </Box>

                                <Box>
                                    <Text
                                        fontFamily="Unbounded"
                                        fontWeight={{ base: '700', sm: '700', md: '700', lg: '800' }}
                                        fontSize={{ base: '24px', sm: '24px', md: '24px', lg: '36px' }}
                                        lineHeight={{ base: '41px', sm: '41px', md: '41px', lg: '53px' }}

                                    >Asture Consult</Text>

                                    <Text fontFamily="Poppins"
                                        fontWeight="400"
                                        fontSize={{ base: '15px', sm: '15px', md: '15px', lg: '20px' }}
                                        lineHeight="28px"
                                        color="#0F1414"
                                        pt={{ base: '5px', sm: '5px', md: '5px', lg: '20px' }}
                                    >
                                        Flexible, on-demand IT consulting designed to help your business innovate and grow.</Text>
                                </Box>


                            </Box>

                        </Link>


                        <Link as={RouterLink} to="/Digital_strategy" _hover={{ textDecoration: "none" }}>


                            <Box bg="#F6F8F8"
                                height={{ base: 'none', sm: 'none', md: 'none', lg: '450px' }}
                                _hover={{
                                    bg: '#EBF0EF',
                                    color: '#06BCD0',
                                    img: {
                                        filter: "brightness(0) saturate(100%) invert(70%) sepia(31%) saturate(6836%) hue-rotate(143deg) brightness(95%) contrast(95%)"
                                    },
                                }}
                                p="25px"
                                display="flex"
                                gap="30px"

                            >
                                <Box>
                                    <Img src="Digital Strategy.png" />

                                </Box>
                                <Box>
                                    <Text fontFamily="Unbounded"
                                        fontWeight={{ base: '700', sm: '700', md: '700', lg: '800' }}
                                        fontSize={{ base: '24px', sm: '24px', md: '24px', lg: '36px' }}
                                        lineHeight={{ base: '41px', sm: '41px', md: '41px', lg: '53px' }}
                                    >Digital Strategy </Text>
                                    <Text fontFamily="Poppins"
                                        fontWeight="400"
                                        fontSize={{ base: '15px', sm: '15px', md: '15px', lg: '20px' }}
                                        color="#0F1414" lineHeight="28px"
                                        pt={{ base: '5px', sm: '5px', md: '5px', lg: '20px' }}
                                    >
                                        Your tailored roadmap to digital transformation, aligning technology with your vision.</Text>
                                </Box>


                            </Box>

                        </Link>


                        <Link as={RouterLink} to="/Powerdetail" _hover={{ textDecoration: "none" }}>


                            <Box bg="#F6F8F8"
                                height={{ base: 'none', sm: 'none', md: 'none', lg: '450px' }}
                                _hover={{
                                    bg: '#EBF0EF'
                                    ,
                                    color: '#06BCD0',
                                    img: {
                                        filter: "brightness(0) saturate(100%) invert(70%) sepia(31%) saturate(6836%) hue-rotate(143deg) brightness(95%) contrast(95%)"
                                    },
                                }}
                                p="25px"
                                display="flex"
                                gap="30px"

                            >
                                <Box>
                                    <Img src="Microsoft.svg" />
                                </Box>

                                <Box>
                                    <Text fontFamily="Unbounded"
                                        fontWeight={{ base: '700', sm: '700', md: '700', lg: '800' }}
                                        fontSize={{ base: '24px', sm: '24px', md: '24px', lg: '20px' }}
                                        lineHeight={{ base: '41px', sm: '41px', md: '41px', lg: '53px' }}
                                    >Power Platform</Text>
                                    <Text fontFamily="Poppins"
                                        fontWeight="400"
                                        fontSize={{ base: '15px', sm: '15px', md: '15px', lg: '20px' }}
                                        color="#0F1414" lineHeight="28px"
                                        pt={{ base: '5px', sm: '5px', md: '5px', lg: '20px' }}
                                    >
                                        Build apps, automate workflows, and harness data insights to elevate productivity.</Text>
                                </Box>


                            </Box>

                        </Link>

                    </Box>





                    <Box className="2ndtbox" display="flex"
                        flexDirection={{ base: 'column', sm: 'column', md: 'column', lg: 'row' }}
                        gap="30px" mt="30px">




                        <Link as={RouterLink} to="/Microsoft365" _hover={{ textDecoration: "none" }}>


                            <Box bg="#F6F8F8"
                                height={{ base: 'none', sm: 'none', md: 'none', lg: '450px' }}
                                _hover={{
                                    bg: '#EBF0EF'
                                    ,
                                    color: '#06BCD0',
                                    img: {
                                        filter: "brightness(0) saturate(100%) invert(70%) sepia(31%) saturate(6836%) hue-rotate(143deg) brightness(95%) contrast(95%)"
                                    },
                                }}
                                p="25px"
                                display="flex"
                                gap="30px"
                            >
                                <Box>
                                    <Img src="Microsoft 365.png" />

                                </Box>
                                <Box>
                                    <Text fontFamily="Unbounded"
                                        fontWeight={{ base: '700', sm: '700', md: '700', lg: '800' }}
                                        fontSize={{ base: '24px', sm: '24px', md: '24px', lg: '20px' }}
                                        lineHeight={{ base: '41px', sm: '41px', md: '41px', lg: '53px' }}
                                    >Microsoft 365</Text>
                                    <Text fontFamily="Poppins"
                                        fontWeight="400"
                                        fontSize={{ base: '15px', sm: '15px', md: '15px', lg: '20px' }}
                                        color="#0F1414" lineHeight="28px"
                                        pt={{ base: '5px', sm: '5px', md: '5px', lg: '20px' }}
                                    >
                                        Empowering collaboration, security, and productivity through Microsoft’s leading cloud suite.</Text>
                                </Box>


                            </Box>

                        </Link>

                        <Link as={RouterLink} to="/Dynamicsdetail" _hover={{ textDecoration: "none" }}>


                            <Box bg="#F6F8F8"
                                height={{ base: 'none', sm: 'none', md: 'none', lg: '450px' }}
                                _hover={{
                                    bg: '#EBF0EF'
                                    ,
                                    color: '#06BCD0',
                                    img: {
                                        filter: "brightness(0) saturate(100%) invert(70%) sepia(31%) saturate(6836%) hue-rotate(143deg) brightness(95%) contrast(95%)"
                                    },
                                }}
                                p="25px"
                                display="flex"
                                gap="30px"                        >

                                <Box>
                                    <Img src="PowerPlatform.svg" />

                                </Box>
                                <Box>
                                    <Text fontFamily="Unbounded"
                                        fontWeight={{ base: '700', sm: '700', md: '700', lg: '800' }}
                                        fontSize={{ base: '24px', sm: '24px', md: '24px', lg: '20px' }}
                                        lineHeight={{ base: '41px', sm: '41px', md: '41px', lg: '53px' }}
                                    >Dynamic 365 </Text>
                                    <Text fontFamily="Poppins"
                                        fontWeight="400"
                                        fontSize={{ base: '15px', sm: '15px', md: '15px', lg: '20px' }}
                                        color="#0F1414" lineHeight="28px"
                                        pt={{ base: '5px', sm: '5px', md: '5px', lg: '20px' }}
                                    >
                                        AI-powered support to streamline work and enhance productivity within Microsoft 365.</Text>
                                </Box>


                            </Box>

                        </Link>


                        <Link as={RouterLink} to="/Copilotdetail" _hover={{ textDecoration: "none" }}>


                            <Box bg="#F6F8F8"
                                height={{ base: 'none', sm: 'none', md: 'none', lg: '450px' }}
                                _hover={{
                                    bg: '#EBF0EF'
                                    ,
                                    color: '#06BCD0',
                                    img: {
                                        filter: "brightness(0) saturate(100%) invert(70%) sepia(31%) saturate(6836%) hue-rotate(143deg) brightness(95%) contrast(95%)"
                                    },
                                }}
                                p="25px"
                                display="flex"
                                gap="30px"                         >


                                <Box>
                                    <Img src="copilo.svg" />

                                </Box>
                                <Box>
                                    <Text fontFamily="Unbounded"
                                        fontWeight={{ base: '700', sm: '700', md: '700', lg: '800' }}
                                        fontSize={{ base: '24px', sm: '24px', md: '24px', lg: '20px' }}
                                        lineHeight={{ base: '41px', sm: '41px', md: '41px', lg: '53px' }}
                                    >Microsoft Copilot</Text>
                                    <Text fontFamily="Poppins"
                                        fontWeight="400"
                                        fontSize={{ base: '15px', sm: '15px', md: '15px', lg: '20px' }}
                                        color="#0F1414" lineHeight="28px"
                                        pt={{ base: '5px', sm: '5px', md: '5px', lg: '20px' }}
                                    >
                                        Comprehensive ERP and CRM solutions to drive operational efficiency and customer engagement.</Text>
                                </Box>


                            </Box>
                        </Link>


                    </Box>

                </Box>

            </Box>
        );
    }
}

export default Services;