import { Box, Text } from "@chakra-ui/react";
import { Component } from "react";

class Servicehero extends Component {
    state = {  } 
    render() { 
        return (
            <Box>
            <Box
              position="relative"
              mt={{ base: '0px', sm: '0px', md: '0px', lg: '-92px' }}
              h={{ base: '0px', sm: '0px', md: '0px', lg: '544px' }}
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              {/* Background Image */}
              <Box
                as="img"
                src="servicesmain.png" // Replace this with the path to your image
                alt="Background Image"
                position="absolute"  
                objectFit="cover"
                zIndex="-1"
              />
          
              {/* Content */}
              <Box
                display={{ base: 'none', sm: 'none', md: 'none', lg: 'block' }}
                position="relative"
                zIndex="1"
              >
              
                <Text fontFamily="Unbounded" fontWeight="400" fontSize="70px" color="white" lineHeight="84px">
                  Services
                </Text>
              </Box>
            </Box>
          </Box>
        );
    }
}
 
export default Servicehero;