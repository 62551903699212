import { Box, ListItem, Text, UnorderedList } from "@chakra-ui/react";
import { Component } from "react";

class Power_platform_consultant extends Component {
    state = {}
    render() {
        return (
            <Box>

                <Text fontFamily="Unbounded" fontWeight="700"
                    fontSize={{ base: '20px', sm: '20px', md: '20px', lg: '24px' }}
                    lineHeight={{ base: '35px', sm: '35px', md: '35px', lg: '65px' }}
                    color="black"
                    mt={{ base: '8px', sm: '8px', md: '8px', lg: '10px' }}

                >
                    Transform Businesses with Data-Driven Solutions as a Power Platform Consultant at Asture Consulting!
                </Text>

                <Text fontFamily="Poppins" fontWeight="400"
                    fontSize={{ base: '17px', sm: '17px', md: '17px', lg: '20px' }}
                    color="black" lineHeight="28px">
                    Are you passionate about empowering businesses with low-code solutions and automation? Do you have expertise in
                    designing and implementing solutions using Microsoft Power Platform tools? If so,
                    <b>Asture Consulting</b> is seeking a <b>Power Platform Consultant</b> to help our clients unlock their
                    potential through innovation and efficiency.
                </Text>

                <Text fontFamily="Unbounded" fontWeight="700"
                    fontSize={{ base: '20px', sm: '20px', md: '20px', lg: '24px' }}
                    lineHeight={{ base: '35px', sm: '35px', md: '35px', lg: '65px' }}
                    color="black"
                    mt={{ base: '8px', sm: '8px', md: '8px', lg: '10px' }}

                >
                    About Asture Consulting                    </Text>


                <Text fontFamily="Poppins" fontWeight="400"
                    fontSize={{ base: '17px', sm: '17px', md: '17px', lg: '20px' }}
                    color="black" lineHeight="28px">
                    Asture Consulting is a trusted partner for businesses seeking technology-driven transformation. We specialize in helping organizations harness the power of tools like Microsoft Power Platform to solve complex challenges, improve operations, and deliver meaningful outcomes.
                </Text>


                <Text fontFamily="Unbounded" fontWeight="700"
                    fontSize={{ base: '20px', sm: '20px', md: '20px', lg: '24px' }}
                    lineHeight={{ base: '35px', sm: '35px', md: '35px', lg: '65px' }}
                    color="black"
                    mt={{ base: '8px', sm: '8px', md: '8px', lg: '10px' }}

                >
                    Role Overview                    </Text>



                <Text fontFamily="Poppins" fontWeight="400"
                    fontSize={{ base: '17px', sm: '17px', md: '17px', lg: '20px' }}
                    color="black" lineHeight="28px">
                    As a <b>Power Platform Consultant,</b> you will design, develop, and implement solutions using Power Platform tools such as Power BI, Power Apps, Power Automate, and Power Virtual Agents. Your role will focus on enabling clients to harness the full potential of their data, streamline workflows, and drive business efficiency through custom, scalable solutions.
                </Text>


                <Text fontFamily="Unbounded" fontWeight="700"
                    fontSize={{ base: '20px', sm: '20px', md: '20px', lg: '24px' }}
                    lineHeight={{ base: '35px', sm: '35px', md: '35px', lg: '65px' }}
                    color="black"

                    mt={{ base: '8px', sm: '8px', md: '8px', lg: '10px' }}

                >
                    Key Responsibilities  </Text>

                <UnorderedList
                    fontFamily="Poppins" fontWeight="500"
                    fontSize={{ base: '15px', sm: '15px', md: '15px', lg: '18px' }}
                    color="black" lineHeight="28px"
                >
                    <ListItem>Collaborate with clients to understand business needs and gather requirements for Power Platform-based solutions.</ListItem>
                    <ListItem>Design and develop custom applications, workflows, and dashboards using Power Apps, Power Automate, and Power BI.</ListItem>
                    <ListItem>Create and deploy virtual agents using Power Virtual Agents to improve client services and operations.</ListItem>
                    <ListItem>Integrate Power Platform tools with existing systems to ensure seamless functionality.</ListItem>
                    <ListItem>Provide training and support to clients, ensuring they can fully utilize the solutions.</ListItem>
                    <ListItem>Stay updated on the latest Power Platform features and capabilities to provide innovative, forward-thinking solutions.</ListItem>
                    <ListItem>Manage projects from inception to completion, ensuring timely delivery and client satisfaction.</ListItem>
                </UnorderedList>

                <Text fontFamily="Unbounded" fontWeight="700"
                    fontSize={{ base: '20px', sm: '20px', md: '20px', lg: '24px' }}
                    lineHeight={{ base: '35px', sm: '35px', md: '35px', lg: '65px' }}
                    color="black"
                    mt={{ base: '8px', sm: '8px', md: '8px', lg: '10px' }}

                >
                    What We’re Looking For </Text>

                {/* Bullets Start */}

                <Text fontFamily="Poppins" fontWeight="400"
                    fontSize={{ base: '15px', sm: '15px', md: '15px', lg: '18px' }}
                    color="black" lineHeight="28px" mt="10px">
                    <b>Educational Background:</b> Bachelor’s degree in Computer Science, Information Systems, or a related field (Microsoft certifications are a plus).
                </Text>

                <Text fontFamily="Poppins" fontWeight="400"
                    fontSize={{ base: '15px', sm: '15px', md: '15px', lg: '18px' }}
                    color="black" lineHeight="28px" mt="10px">
                    <b>Experience:</b> <br></br>
                    <UnorderedList
                        fontFamily="Poppins" fontWeight="400"
                        fontSize={{ base: '15px', sm: '15px', md: '15px', lg: '18px' }}
                        color="black" lineHeight="28px"
                    >
                        <ListItem>Minimum of 3-5 years of experience in designing and implementing Microsoft Power Platform solutions.</ListItem>
                        <ListItem>Proven expertise in creating solutions using Power Apps, Power Automate, Power BI, and Power Virtual Agents.</ListItem>
                    </UnorderedList>
                </Text>

                <Text fontFamily="Poppins" fontWeight="400"
                    fontSize={{ base: '15px', sm: '15px', md: '15px', lg: '18px' }}
                    color="black" lineHeight="28px" mt="10px">
                    <b>Skills: </b> <br></br>
                    <UnorderedList
                        fontFamily="Poppins" fontWeight="400"
                        fontSize={{ base: '15px', sm: '15px', md: '15px', lg: '18px' }}
                        color="black" lineHeight="28px"
                    >
                        <ListItem>Strong understanding of Power Platform architecture and its integration with other Microsoft tools.</ListItem>
                        <ListItem>Proficiency in building custom applications, dashboards, and workflows.</ListItem>
                        <ListItem>Knowledge of relational databases and data modeling concepts.</ListItem>
                        <ListItem>Excellent communication and client-facing skills to deliver tailored solutions.</ListItem>
                        <ListItem>Analytical and problem-solving skills to address complex business challenges.</ListItem>
                    </UnorderedList>
                </Text>


                <Text fontFamily="Poppins" fontWeight="400"
                    fontSize={{ base: '15px', sm: '15px', md: '15px', lg: '18px' }}
                    color="black" lineHeight="28px" mt="10px">
                    <b>Mindset:</b> <br></br>
                    <UnorderedList
                        fontFamily="Poppins" fontWeight="400"
                        fontSize={{ base: '15px', sm: '15px', md: '15px', lg: '18px' }}
                        color="black" lineHeight="28px"
                    >
                        <ListItem>Proactive, innovative, and committed to delivering exceptional client outcomes.</ListItem>
                        <ListItem>Passionate about low-code/no-code technologies and digital transformation.</ListItem>

                    </UnorderedList>
                </Text>

                {/* Bullets End */}

                <Text fontFamily="Unbounded" fontWeight="700"
                    fontSize={{ base: '20px', sm: '20px', md: '20px', lg: '24px' }}
                    lineHeight={{ base: '35px', sm: '35px', md: '35px', lg: '65px' }}
                    color="black"
                    mt={{ base: '8px', sm: '8px', md: '8px', lg: '10px' }}

                >
                    What We Offer </Text>

                <UnorderedList
                    fontFamily="Poppins" fontWeight="400"
                    fontSize={{ base: '15px', sm: '15px', md: '15px', lg: '18px' }}
                    color="black" lineHeight="28px"
                >
                    <ListItem>Competitive salary with performance-based incentives.</ListItem>
                    <ListItem>Opportunities for professional growth and Microsoft certification programs.</ListItem>
                    <ListItem>Exposure to diverse industries and high-impact projects.</ListItem>
                    <ListItem>A collaborative, inclusive, and innovative work environment.</ListItem>
                    <ListItem>Flexibility and support to achieve work-life balance.</ListItem>
                </UnorderedList>

                <Text fontFamily="Unbounded" fontWeight="700"
                    fontSize={{ base: '20px', sm: '20px', md: '20px', lg: '24px' }}
                    lineHeight={{ base: '35px', sm: '35px', md: '35px', lg: '65px' }}
                    color="black"
                    mt={{ base: '8px', sm: '8px', md: '8px', lg: '10px' }}

                >
                    How to Apply </Text>

                <Text fontFamily="Poppins" fontWeight="400"
                    fontSize={{ base: '17px', sm: '17px', md: '17px', lg: '20px' }}
                    color="black" lineHeight="28px">
                    If you’re ready to help businesses leverage the power of low-code solutions, we’d love to hear from you! Submit your resume and a cover letter detailing your
                    experience and enthusiasm for Power Platform consulting to
                </Text>
                <Text fontFamily="Poppins" fontWeight="400"
                    fontSize={{ base: '17px', sm: '17px', md: '17px', lg: '20px' }}
                    color="#06BCD0" lineHeight="28px">
                    <b>
                        <a href="mailto:post@asture.co" > post@asture.co.</a>
                    </b>

                </Text>

                <Text fontFamily="Poppins" fontWeight="400"
                    fontSize={{ base: '17px', sm: '17px', md: '17px', lg: '20px' }}
                    color="black" lineHeight="28px" mt="10px">
                    <b>Asture Consulting is an equal opportunity employer.</b> We celebrate diversity and are
                    committed to creating an inclusive workplace where everyone can thrive.
                </Text>

                <Text fontFamily="Poppins" fontWeight="400"
                    fontSize={{ base: '17px', sm: '17px', md: '17px', lg: '20px' }}
                    color="black" lineHeight="28px" mt="10px">
                    <b>Join Asture Consulting and drive transformation with Microsoft Power Platform – apply now!</b>
                </Text>


            </Box>
        );
    }
}

export default Power_platform_consultant;