import { Box, Img, Text } from "@chakra-ui/react";
import { Component } from "react";

class Workflow extends Component {
    state = {  } 
    render() { 
        return (
        <Box
        bgColor="#0F1414"
        p="50px"
        borderRadius="20px"
        >
            <Text fontFamily="Poppins
" fontWeight="500" fontSize="20px" color="#06BCD0" letterSpacing="10px"
textTransform="uppercase"
>Our Model</Text>
<Text fontFamily="Unbounded"
 fontWeight={{base:'500',sm:'500',md:'500',lg:'700'}}
fontSize={{base:'25px',sm:'25px',md:'25px',lg:'54px'}}
color="white" 
lineHeight={{base:'41px',sm:'41px',md:'41px',lg:'72px'}}
w={{base:'none',sm:'none',md:'none',lg:'781px'}}
>
Our Microsoft Consultation Process</Text>

<Text fontFamily="Poppins"
 fontWeight="400"
fontSize={{base:'14px',sm:'14px',md:'14px',lg:'16px'}}
color="white" lineHeight="28px" 
pt={{base:'5px',sm:'5px',md:'5px',lg:'10px'}} 
 >
Save time and money with our powerful method.</Text>

<Img
display={{base:'none',sm:'none',md:'none',lg:'block'}}
pt={{base:'40px',sm:'40px',md:'40px',lg:'80px'}}
src="workflow.svg"/>
<Img
display={{base:'block',sm:'block',md:'block',lg:'none'}}
pt={{base:'40px',sm:'40px',md:'40px',lg:'80px'}}
src="workflowmobile.svg"/>
        </Box>);
    }
}
 
export default Workflow;