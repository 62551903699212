import { Box, Heading, Image, keyframes } from "@chakra-ui/react";

// Define a keyframes animation for blinking effect
const blink = keyframes`
  0% { opacity: 1; }
  50% { opacity: 0; }
  100% { opacity: 1; }
`;

const ComingSoon = () => {
    return (
        <Box bgColor="#222224" padding="20px" textAlign="center" margin="0 auto">
            <Heading
                color="white"
                animation={`${blink} 1.5s infinite`}
            >
                Hi, There peps
            </Heading>

            <Image src="joker.jpg" h="614px" margin="20px auto" />


        </Box>
    );
};

export default ComingSoon;
