import { Box, Flex, Img, ListItem, Text, UnorderedList } from "@chakra-ui/react";
import { Component } from "react";
import Banner from "../ContactUspage/Banner";

class Cybersecurity extends Component {
   componentDidMount() {
      window.scrollTo(0, 0); // Scroll to the top of the page when the component mounts
   }
   state = {}
   render() {
      return (


         <Box>

            <Banner
               imageSrc="Cyber Security 1.jpg"
               text="Cyber Security"
            />

            <Box px={{ base: '20px', sm: '20px', md: '20px', lg: '50px' }}
               w={{ base: 'none', sm: 'none', md: 'none', lg: '1300px' }}
               margin="70px auto"

            >
               <Text fontFamily="Unbounded" fontWeight="700"
                  fontSize={{ base: '35px', sm: '35px', md: '35px', lg: '54px' }}
                  color="#06BCD0"
                  lineHeight={{ base: 'none', sm: 'none', md: 'none', lg: '65px' }}
                  w={{ base: 'none', sm: 'none', md: 'none', lg: '850px' }}
               >
                  Cyber Security
               </Text>
               <Text fontFamily="Poppins" fontWeight="400"
                  fontSize={{ base: '17px', sm: '17px', md: '17px', lg: '20px' }}
                  color="black" lineHeight="28px" mt="30px">
                  In an era of increasing cyber threats, our <b>Cybersecurity Framework</b> is designed to protect
                  your organization's critical assets, data, and operations with a comprehensive,
                  multi-layered approach. By integrating cutting-edge security technologies,
                  proactive monitoring, and incident response strategies, we help businesses build
                  resilient defense systems that minimize risk and ensure regulatory compliance.
               </Text>


               <Text fontFamily="Unbounded" fontWeight="700" fontSize="24px"
                  color="black"
                  lineHeight={{ base: '35px', sm: '35px', md: '35px', lg: '65px' }}
                  mt={{ base: '8px', sm: '8px', md: '8px', lg: 'none' }}
               >
                  Key Components of the Cybersecurity Framework
               </Text>

               {/* 1st Box  */}
               <Box>
                  <Text fontFamily="Poppins" fontWeight="600" fontSize="22px" color="black">
                     Threat Prevention and Detection   </Text>
                  <Text fontFamily="Poppins" fontWeight="500" fontSize="18px" color="black" mt="5px" >
                     Proactively identifying and mitigating potential threats       </Text>
                  <Text fontFamily="Poppins" fontWeight="400" fontSize="18px" color="black" mt="5px" >
                     We implement robust solutions to prevent unauthorized access, detect anomalies, and
                     respond to potential security events:
                  </Text>
                  <Box display="flex"
                     flexDirection={{ base: 'column', sm: 'column', md: 'column', xl: 'row' }}

                     gap="10px">
                     <Text fontFamily="Poppins" fontWeight="400" fontSize="16px" color="black" mt="5px" >
                        <b>1.Microsoft Defender Suite: </b>  </Text>
                     <Text fontFamily="Poppins" fontWeight="400" fontSize="16px" color="black"
                        mt={{ base: '-7px', sm: '-7px', md: '-7px', xl: '5px' }}  >
                        Comprehensive protection for endpoints, email, and cloud environments.
                     </Text>
                  </Box>
                  <Box display="flex"
                     flexDirection={{ base: 'column', sm: 'column', md: 'column', xl: 'row' }}

                     gap="10px">
                     <Text fontFamily="Poppins" fontWeight="400" fontSize="16px" color="black" mt="5px" >
                        <b>2.Next-Generation Firewalls (NGFW):  </b>  </Text>
                     <Text fontFamily="Poppins" fontWeight="400" fontSize="16px" color="black"
                        mt={{ base: '-7px', sm: '-7px', md: '-7px', xl: '5px' }}  >
                        Advanced firewalls to inspect network traffic and prevent malicious activity.
                     </Text>
                  </Box>
                  <Box display="flex"
                     flexDirection={{ base: 'column', sm: 'column', md: 'column', xl: 'row' }}

                     gap="10px">
                     <Text fontFamily="Poppins" fontWeight="400" fontSize="16px" color="black" mt="5px" >
                        <b>3.Endpoint Detection and Response (EDR): </b>  </Text>
                     <Text fontFamily="Poppins" fontWeight="400" fontSize="16px" color="black"
                        mt={{ base: '-7px', sm: '-7px', md: '-7px', xl: '5px' }}  >
                        Real-time monitoring and threat detection for all connected devices.
                     </Text>
                  </Box>
                  <Box display="flex"
                     flexDirection={{ base: 'column', sm: 'column', md: 'column', xl: 'row' }}

                     gap="10px">
                     <Text fontFamily="Poppins" fontWeight="400" fontSize="16px" color="black" mt="5px" >
                        <b>4.AI-Driven Analytics:  </b>  </Text>
                     <Text fontFamily="Poppins" fontWeight="400" fontSize="16px" color="black"
                        mt={{ base: '-7px', sm: '-7px', md: '-7px', xl: '5px' }}  >
                        Utilization of machine learning to identify behavioral anomalies and emerging threats.
                     </Text>
                  </Box>
                  <Text fontFamily="Poppins" fontWeight="400" fontSize="18px" color="black" mt="5px" >
                     This layered defense ensures that potential vulnerabilities are identified and addressed before they can impact your operations.
                  </Text>
               </Box>

               {/* 2nd Box  */}
               <Box mt="20px">
                  <Text fontFamily="Poppins" fontWeight="600" fontSize="22px" color="black" >
                     Identity and Access Management (IAM)   </Text>
                  <Text fontFamily="Poppins" fontWeight="500" fontSize="18px" color="black" mt="5px" >
                     Strengthening access controls and protecting user identities      </Text>
                  <Text fontFamily="Poppins" fontWeight="400" fontSize="18px" color="black" mt="5px" >
                     Our identity and access management solutions provide secure, role-based access to sensitive resources:
                  </Text>
                  <Box display="flex"
                     flexDirection={{ base: 'column', sm: 'column', md: 'column', xl: 'row' }}

                     gap="10px">
                     <Text fontFamily="Poppins" fontWeight="400" fontSize="16px" color="black" mt="5px" >
                        <b>1.Entra ID (formerly Azure Active Directory): </b>  </Text>
                     <Text fontFamily="Poppins" fontWeight="400" fontSize="16px" color="black"
                        mt={{ base: '-7px', sm: '-7px', md: '-7px', xl: '5px' }} >
                        Centralized identity management with multi-factor authentication (MFA).
                     </Text>
                  </Box>
                  <Box display="flex"
                     flexDirection={{ base: 'column', sm: 'column', md: 'column', xl: 'row' }}

                     gap="10px">
                     <Text fontFamily="Poppins" fontWeight="400" fontSize="16px" color="black" mt="5px" >
                        <b>2.Conditional Access Policies:  </b>  </Text>
                     <Text fontFamily="Poppins" fontWeight="400" fontSize="16px" color="black"
                        mt={{ base: '-7px', sm: '-7px', md: '-7px', xl: '5px' }} >
                        Dynamic access controls based on user location, device, and behavior.
                     </Text>
                  </Box>
                  <Box display="flex"
                     flexDirection={{ base: 'column', sm: 'column', md: 'column', xl: 'row' }}

                     gap="10px">
                     <Text fontFamily="Poppins" fontWeight="400" fontSize="16px" color="black" mt="5px" >
                        <b>3.Privileged Access Management (PAM):  </b>  </Text>
                     <Text fontFamily="Poppins" fontWeight="400" fontSize="16px" color="black"
                        mt={{ base: '-7px', sm: '-7px', md: '-7px', xl: '5px' }} >
                        Enhanced security for accounts with elevated permissions to minimize misuse.
                     </Text>
                  </Box>
                  <Text fontFamily="Poppins" fontWeight="400" fontSize="18px" color="black" mt="5px" >
                     These measures ensure that only authorized users can access critical systems and data, minimizing the risk of unauthorized access.
                  </Text>
               </Box>


               {/* 3rd Box  */}
               <Box mt="20px">
                  <Text fontFamily="Poppins" fontWeight="600" fontSize="22px" color="black" >
                     Zero Trust Security Model    </Text>
                  <Text fontFamily="Poppins" fontWeight="500" fontSize="18px" color="black" mt="5px" >
                     Adopting a verify-everything approach       </Text>
                  <Text fontFamily="Poppins" fontWeight="400" fontSize="18px" color="black" mt="5px" >
                     Our framework follows the principles of the Zero Trust model, where no user or device is automatically trusted:
                  </Text>
                  <Box display="flex"
                     flexDirection={{ base: 'column', sm: 'column', md: 'column', xl: 'row' }}
                     gap="10px">
                     <Text fontFamily="Poppins" fontWeight="400" fontSize="16px" color="black" mt="5px" >
                        <b>1.Continuous Verification:  </b>  </Text>
                     <Text fontFamily="Poppins" fontWeight="400" fontSize="16px" color="black"
                        mt={{ base: '-7px', sm: '-7px', md: '-7px', xl: '5px' }} >
                        Users and devices are continuously verified at every access point.
                     </Text>
                  </Box>
                  <Box display="flex"
                     flexDirection={{ base: 'column', sm: 'column', md: 'column', xl: 'row' }}
                     gap="10px">
                     <Text fontFamily="Poppins" fontWeight="400" fontSize="16px" color="black" mt="5px" >
                        <b>2.Micro-Segmentation:  </b>  </Text>
                     <Text fontFamily="Poppins" fontWeight="400" fontSize="16px" color="black"
                        mt={{ base: '-7px', sm: '-7px', md: '-7px', xl: '5px' }} >
                        Restricting network access to isolate and protect sensitive assets.
                     </Text>
                  </Box>
                  <Box display="flex"
                     flexDirection={{ base: 'column', sm: 'column', md: 'column', xl: 'row' }}
                     gap="10px">
                     <Text fontFamily="Poppins" fontWeight="400" fontSize="16px" color="black" mt="5px" >
                        <b>3.Least Privilege Access:  </b>  </Text>
                     <Text fontFamily="Poppins" fontWeight="400" fontSize="16px" color="black"
                        mt={{ base: '-7px', sm: '-7px', md: '-7px', xl: '5px' }} >
                        Granting users the minimal level of access required to perform their tasks.
                     </Text>
                  </Box>
                  <Text fontFamily="Poppins" fontWeight="400" fontSize="18px" color="black" mt="5px" >
                     The Zero Trust approach significantly reduces the attack surface and enhances overall security.
                  </Text>
               </Box>


               {/* 4th Box  */}
               <Box mt="20px">
                  <Text fontFamily="Poppins" fontWeight="600" fontSize="22px" color="black" >
                     Data Protection and Compliance     </Text>
                  <Text fontFamily="Poppins" fontWeight="500" fontSize="18px" color="black" mt="5px" >
                     Safeguarding sensitive information and meeting regulatory standards       </Text>
                  <Text fontFamily="Poppins" fontWeight="400" fontSize="18px" color="black" mt="5px" >
                     We implement comprehensive data protection measures to ensure data confidentiality, integrity, and availability:
                  </Text>
                  <Box display="flex"
                     flexDirection={{ base: 'column', sm: 'column', md: 'column', xl: 'row' }}

                     gap="10px">
                     <Text fontFamily="Poppins" fontWeight="400" fontSize="16px" color="black" mt="5px" >
                        <b>1.Data Loss Prevention (DLP):  </b>  </Text>
                     <Text fontFamily="Poppins" fontWeight="400" fontSize="16px" color="black"
                        mt={{ base: '-7px', sm: '-7px', md: '-7px', xl: '5px' }} >
                        Policies to prevent unauthorized sharing or transmission of sensitive information.
                     </Text>
                  </Box>
                  <Box display="flex"
                     flexDirection={{ base: 'column', sm: 'column', md: 'column', xl: 'row' }}

                     gap="10px">
                     <Text fontFamily="Poppins" fontWeight="400" fontSize="16px" color="black" mt="5px" >
                        <b>2.Information Rights Management (IRM):  </b>  </Text>
                     <Text fontFamily="Poppins" fontWeight="400" fontSize="16px" color="black"
                        mt={{ base: '-7px', sm: '-7px', md: '-7px', xl: '5px' }} >
                        Control over how data is shared, accessed, and modified.
                     </Text>
                  </Box>
                  <Box display="flex"
                     flexDirection={{ base: 'column', sm: 'column', md: 'column', xl: 'row' }}

                     gap="10px">
                     <Text fontFamily="Poppins" fontWeight="400" fontSize="16px" color="black" mt="5px" >
                        <b>3.Encryption:   </b>  </Text>
                     <Text fontFamily="Poppins" fontWeight="400" fontSize="16px" color="black"
                        mt={{ base: '-7px', sm: '-7px', md: '-7px', xl: '5px' }} >
                        End-to-end encryption for data in transit and at rest to prevent unauthorized interception.
                     </Text>
                  </Box>
                  <Box display="flex"
                     flexDirection={{ base: 'column', sm: 'column', md: 'column', xl: 'row' }}

                     gap="10px">
                     <Text fontFamily="Poppins" fontWeight="400" fontSize="16px" color="black" mt="5px" >
                        <b>4.Compliance Management:   </b>  </Text>
                     <Text fontFamily="Poppins" fontWeight="400" fontSize="16px" color="black"
                        mt={{ base: '-7px', sm: '-7px', md: '-7px', xl: '5px' }} >
                        Alignment with key regulatory frameworks (GDPR, ISO 27001, HIPAA, etc.) to meet legal and industry standards.
                     </Text>
                  </Box>
                  <Text fontFamily="Poppins" fontWeight="400" fontSize="18px" color="black" mt="5px" >
                     These measures help organizations stay compliant and avoid costly breaches or penalties.
                  </Text>
               </Box>


               {/* 5th Box  */}
               <Box mt="20px">
                  <Text fontFamily="Poppins" fontWeight="600" fontSize="22px" color="black" >
                     Incident Response and Recovery    </Text>
                  <Text fontFamily="Poppins" fontWeight="500" fontSize="18px" color="black" mt="5px" >
                     Ensuring rapid response and business continuity        </Text>
                  <Text fontFamily="Poppins" fontWeight="400" fontSize="18px" color="black" mt="5px" >
                     In the event of a security incident, our framework ensures a coordinated and efficient response to minimize impact:
                  </Text>
                  <Box display="flex"
                     flexDirection={{ base: 'column', sm: 'column', md: 'column', xl: 'row' }}
                     gap="10px">
                     <Text fontFamily="Poppins" fontWeight="400" fontSize="16px" color="black" mt="5px" >
                        <b>1.Incident Response Plans:  </b>  </Text>
                     <Text fontFamily="Poppins" fontWeight="400" fontSize="16px" color="black"
                        mt={{ base: '-7px', sm: '-7px', md: '-7px', xl: '5px' }} >
                        Detailed protocols for detecting, containing, and mitigating security incidents.
                     </Text>
                  </Box>
                  <Box display="flex"
                     flexDirection={{ base: 'column', sm: 'column', md: 'column', xl: 'row' }}
                     gap="10px">
                     <Text fontFamily="Poppins" fontWeight="400" fontSize="16px" color="black" mt="5px" >
                        <b>2.Security Operations Center (SOC):   </b>  </Text>
                     <Text fontFamily="Poppins" fontWeight="400" fontSize="16px" color="black"
                        mt={{ base: '-7px', sm: '-7px', md: '-7px', xl: '5px' }} >
                        24/7 monitoring and rapid incident response support.
                     </Text>
                  </Box>
                  <Box display="flex"
                     flexDirection={{ base: 'column', sm: 'column', md: 'column', xl: 'row' }}
                     gap="10px">
                     <Text fontFamily="Poppins" fontWeight="400" fontSize="16px" color="black" mt="5px" >
                        <b>3.Disaster Recovery (DR) and Business Continuity Planning (BCP):   </b>  </Text>
                     <Text fontFamily="Poppins" fontWeight="400" fontSize="16px" color="black"
                        mt={{ base: '-7px', sm: '-7px', md: '-7px', xl: '5px' }} >
                        Comprehensive strategies to restore operations and minimize downtime after an attack.
                     </Text>
                  </Box>
                  <Text fontFamily="Poppins" fontWeight="400" fontSize="18px" color="black" mt="5px" >
                     By preparing for potential incidents, organizations can reduce recovery time and prevent long-term disruptions.
                  </Text>
               </Box>


               <Text fontFamily="Unbounded" fontWeight="700" fontSize="24px"
                  color="black"
                  lineHeight={{ base: '35px', sm: '35px', md: '35px', lg: '65px' }}
                  mt={{ base: '8px', sm: '8px', md: '8px', lg: 'none' }}
               >
                  Business Outcomes of the Cybersecurity Framework         </Text>

               <Box display="flex"
                  flexDirection={{ base: 'column', sm: 'column', md: 'column', xl: 'row' }}
                  gap="5px">
                  <Text fontFamily="Poppins" fontWeight="400"
                     fontSize={{ base: '16px', sm: '16px', md: '16px', lg: '17px' }}
                     color="black">
                     <b>Reduced Cyber Risk: </b>
                  </Text>
                  <Text fontFamily="Poppins" fontWeight="400"
                     fontSize={{ base: '16px', sm: '16px', md: '16px', lg: '17px' }}
                     color="black">
                     Minimize the likelihood and impact of cyber threats with proactive defense measures.
                  </Text>
               </Box>
               <Box display="flex"
                  flexDirection={{ base: 'column', sm: 'column', md: 'column', xl: 'row' }}
                  gap="5px">
                  <Text fontFamily="Poppins" fontWeight="400"
                     fontSize={{ base: '16px', sm: '16px', md: '16px', lg: '17px' }}
                     color="black">
                     <b>Enhanced Compliance: </b>
                  </Text>
                  <Text fontFamily="Poppins" fontWeight="400"
                     fontSize={{ base: '16px', sm: '16px', md: '16px', lg: '17px' }}
                     color="black">
                     Maintain adherence to regulatory standards and avoid costly penalties.
                  </Text>
               </Box>
               <Box display="flex"
                  flexDirection={{ base: 'column', sm: 'column', md: 'column', xl: 'row' }}
                  gap="5px">
                  <Text fontFamily="Poppins" fontWeight="400"
                     fontSize={{ base: '16px', sm: '16px', md: '16px', lg: '17px' }}
                     color="black">
                     <b>Improved User Trust:  </b>
                  </Text>
                  <Text fontFamily="Poppins" fontWeight="400"
                     fontSize={{ base: '16px', sm: '16px', md: '16px', lg: '17px' }}
                     color="black">
                     Protect customer and employee data to foster trust and credibility.
                  </Text>
               </Box>

               <Box display="flex"
                  flexDirection={{ base: 'column', sm: 'column', md: 'column', xl: 'row' }}
                  gap="5px">
                  <Text fontFamily="Poppins" fontWeight="400"
                     fontSize={{ base: '16px', sm: '16px', md: '16px', lg: '17px' }}
                     color="black">
                     <b>Operational Resilience:   </b>
                  </Text>
                  <Text fontFamily="Poppins" fontWeight="400"
                     fontSize={{ base: '16px', sm: '16px', md: '16px', lg: '17px' }}
                     color="black">
                     Ensure business continuity even in the face of security incidents.
                  </Text>
               </Box>

               <Box display="flex"
                  flexDirection={{ base: 'column', sm: 'column', md: 'column', xl: 'row' }}
                  gap="5px">
                  <Text fontFamily="Poppins" fontWeight="400"
                     fontSize={{ base: '16px', sm: '16px', md: '16px', lg: '17px' }}
                     color="black">
                     <b>Data Security Assurance:    </b>
                  </Text>
                  <Text fontFamily="Poppins" fontWeight="400"
                     fontSize={{ base: '16px', sm: '16px', md: '16px', lg: '17px' }}
                     color="black">
                     Protect sensitive information across all endpoints, devices, and environments.
                  </Text>
               </Box>

               <Text fontFamily="Unbounded" fontWeight="700" fontSize="24px"
                  color="black"
                  lineHeight={{ base: '35px', sm: '35px', md: '35px', lg: '65px' }}
                  mt={{ base: '8px', sm: '8px', md: '8px', lg: 'none' }}
               >
                  Why Choose Asture Consulting?
               </Text>

               <Text fontFamily="Poppins" fontWeight="400"
                  fontSize={{ base: '17px', sm: '17px', md: '17px', lg: '20px' }}
                  color="black" lineHeight="28px">
                  At <b>Asture Consulting,</b> we take a holistic approach to cybersecurity, integrating advanced tools, expertise, and best practices to provide end-to-end protection. Our team of certified security professionals helps organizations build and maintain a robust security posture, enabling them to focus on growth without fear of cyber threats.
               </Text>

               <Text fontFamily="Poppins" fontWeight="400"
                  fontSize={{ base: '17px', sm: '17px', md: '17px', lg: '20px' }}
                  color="black" lineHeight="28px" mt="30px">
                  From security assessments and strategy development to continuous monitoring and incident response, we partner with you every step of the way to ensure your organization remains secure and compliant.
               </Text>

               <Text fontFamily="Poppins" fontWeight="400"
                  fontSize={{ base: '17px', sm: '17px', md: '17px', lg: '20px' }}
                  color="black" lineHeight="28px" mt="30px">
                  <b>Next Steps:</b> Looking to strengthen your cybersecurity defenses? Contact <b>Asture Consulting</b> today to learn more about our Cybersecurity Framework and how it can protect your business from evolving threats.
               </Text>

               <Img mt="30px" src="Cyber Security 2.png" />

            </Box>
         </Box>



      );
   }
}

export default Cybersecurity;