import { Box, Text } from "@chakra-ui/react";
import { Component } from "react";

class Social extends Component {
    state = {  } 
    render() { 
        return (
            <Box
            textAlign="center">
                <Text fontSize="40px">Social  Section</Text>
            </Box>
        );
    }
}
 
export default Social;