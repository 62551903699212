import { Box, Img, Text } from "@chakra-ui/react";
import { Component } from "react";
import { Link, Link as RouterLink } from "react-router-dom"; // Import RouterLink for Chakra's Link component


class Cardservices extends Component {
    state = {  } 
    render() { 
        return (



    <Box className="mainBoxes" mt="80px"
            mb="80px"
            display="flex"
            flexDirection="column"
            alignItems="center"
            >



<Box className="1stbox" display="flex" 
                flexDirection={{base:'column',sm:'column',md:'column',lg:'row'}}
                gap="30px">



<Link as={RouterLink} to="/Azuredetail" _hover={{ textDecoration: "none" }}>

                    <Box bg="#F6F8F8" 
                      height={{base:'none',sm:'none',md:'none',lg:'390px'}}
                    _hover={{bg:'#EBF0EF',
                    color:'#06BCD0',
                    img: {
                        filter: "brightness(0) saturate(100%) invert(70%) sepia(31%) saturate(6836%) hue-rotate(143deg) brightness(95%) contrast(95%)"
                      },
                }}
                
                    p="50px"
                    >

                        <Img
                         src="azure.svg"/>
                        <Text
                        fontFamily="Unbounded"
fontWeight={{base:'700',sm:'700',md:'700',lg:'800'}}
fontSize={{base:'29px',sm:'29px',md:'29px',lg:'36px'}}
lineHeight={{base:'41px',sm:'41px',md:'41px',lg:'53px'}}
pt="90px"

>Azure</Text>

<Text fontFamily="Poppins"
fontWeight="400"
fontSize={{base:'18px',sm:'18px',md:'18px',lg:'20px'}}
lineHeight="28px" 
color="#0F1414"
pt={{base:'5px',sm:'5px',md:'5px',lg:'20px'}} 
>
We care success relationships fuel success we love building.</Text>

                    </Box>

                    </Link>

                    <Link as={RouterLink} to="/Copilotdetail" _hover={{ textDecoration: "none" }}>


                    <Box bg="#F6F8F8" 
                    height={{base:'none',sm:'none',md:'none',lg:'390px'}}
                    _hover={{bg:'#EBF0EF',
                    color:'#06BCD0',
                    img: {
                        filter: "brightness(0) saturate(100%) invert(70%) sepia(31%) saturate(6836%) hue-rotate(143deg) brightness(95%) contrast(95%)"
                      },
                }}
                    p="50px"
                    >
                        <Img src="copilot.svg"/>
                        <Text fontFamily="Unbounded"
fontWeight={{base:'700',sm:'700',md:'700',lg:'800'}}
fontSize={{base:'29px',sm:'29px',md:'29px',lg:'36px'}}
lineHeight={{base:'41px',sm:'41px',md:'41px',lg:'53px'}}
pt="90px"
>Copilot</Text>
<Text fontFamily="Poppins"
fontWeight="400"
fontSize={{base:'18px',sm:'18px',md:'18px',lg:'20px'}}
color="#0F1414" lineHeight="28px" 
pt={{base:'5px',sm:'5px',md:'5px',lg:'20px'}} 
>
We care success relationships fuel success we love building</Text>

                    </Box>

                    </Link>

                    <Link as={RouterLink} to="/Dynamicsdetail" _hover={{ textDecoration: "none" }}>


                    <Box bg="#F6F8F8" 
                     height={{base:'none',sm:'none',md:'none',lg:'390px'}}
                    _hover={{bg:'#EBF0EF'
                    ,
                    color:'#06BCD0',
                    img: {
                        filter: "brightness(0) saturate(100%) invert(70%) sepia(31%) saturate(6836%) hue-rotate(143deg) brightness(95%) contrast(95%)"
                      },
                }}
                    p="50px"
                    >
                        <Img src="dynamics365.svg"/>
                        <Text fontFamily="Unbounded"
fontWeight={{base:'700',sm:'700',md:'700',lg:'800'}}
fontSize={{base:'29px',sm:'29px',md:'29px',lg:'36px'}}
lineHeight={{base:'41px',sm:'41px',md:'41px',lg:'53px'}}
pt="90px"
>Dynamics 365</Text>
<Text fontFamily="Poppins"
fontWeight="400"
fontSize={{base:'18px',sm:'18px',md:'18px',lg:'20px'}}
color="#0F1414" lineHeight="28px" 
pt={{base:'5px',sm:'5px',md:'5px',lg:'20px'}} 
>
We care success relationships fuel success we love building</Text>

                    </Box>
                    </Link>

                </Box>

               



                <Box className="2ndtbox" display="flex"
                flexDirection={{base:'column',sm:'column',md:'column',lg:'row'}}
                gap="30px" mt="30px">
               
               
               <Link as={RouterLink} to="/Licensingdetail" _hover={{ textDecoration: "none" }}>

               
                <Box bg="#F6F8F8" 
                height={{base:'none',sm:'none',md:'none',lg:'390px'}}
                    _hover={{bg:'#EBF0EF'
                    ,
                    color:'#06BCD0',
                    img: {
                        filter: "brightness(0) saturate(100%) invert(70%) sepia(31%) saturate(6836%) hue-rotate(143deg) brightness(95%) contrast(95%)"
                      },
                }}
                    p="50px"
                    >
                        <Img src="Microsoft.svg"/>
                        <Text fontFamily="Unbounded"
fontWeight={{base:'700',sm:'700',md:'700',lg:'800'}}
fontSize={{base:'29px',sm:'29px',md:'29px',lg:'36px'}}
lineHeight={{base:'41px',sm:'41px',md:'41px',lg:'53px'}}
pt="90px"
>Licensing</Text>
<Text fontFamily="Poppins"
fontWeight="400"
fontSize={{base:'18px',sm:'18px',md:'18px',lg:'20px'}}
color="#0F1414" lineHeight="28px" 
pt={{base:'5px',sm:'5px',md:'5px',lg:'20px'}} 
>
We care success relationships fuel success we love building.</Text>

                    </Box>

                    </Link>

                    <Link as={RouterLink} to="/Powerdetail" _hover={{ textDecoration: "none" }}>


                <Box bg="#F6F8F8" 
                 height={{base:'none',sm:'none',md:'none',lg:'390px'}}
                    _hover={{bg:'#EBF0EF'
                    ,
                    color:'#06BCD0',
                    img: {
                        filter: "brightness(0) saturate(100%) invert(70%) sepia(31%) saturate(6836%) hue-rotate(143deg) brightness(95%) contrast(95%)"
                      },
                }}
                    p="50px"
                    >
                        <Img src="PowerPlatform.svg"/>
                        <Text fontFamily="Unbounded"
fontWeight={{base:'700',sm:'700',md:'700',lg:'800'}}
fontSize={{base:'29px',sm:'29px',md:'29px',lg:'36px'}}
lineHeight={{base:'41px',sm:'41px',md:'41px',lg:'53px'}}
pt="90px"
>Power Platform </Text>
<Text fontFamily="Poppins"
fontWeight="400"
fontSize={{base:'18px',sm:'18px',md:'18px',lg:'20px'}}
color="#0F1414" lineHeight="28px" 
pt={{base:'5px',sm:'5px',md:'5px',lg:'20px'}} 
>
We care success relationships fuel success we love building.</Text>

                    </Box>

                    </Link>

                    <Link as={RouterLink} to="/Teamsdetail" _hover={{ textDecoration: "none" }}>

                  
                    <Box bg="#F6F8F8" 
                     height={{base:'none',sm:'none',md:'none',lg:'390px'}}
                    _hover={{bg:'#EBF0EF'
                    ,
                    color:'#06BCD0',
                    img: {
                        filter: "brightness(0) saturate(100%) invert(70%) sepia(31%) saturate(6836%) hue-rotate(143deg) brightness(95%) contrast(95%)"
                      },
                }}
                    p="50px"
                    >
                        <Img src="microsoftteams.svg"/>
                        <Text fontFamily="Unbounded"
fontWeight={{base:'700',sm:'700',md:'700',lg:'800'}}
fontSize={{base:'29px',sm:'29px',md:'29px',lg:'36px'}}
lineHeight={{base:'41px',sm:'41px',md:'41px',lg:'53px'}}
pt="90px"
>Teams</Text>
<Text fontFamily="Poppins"
fontWeight="400"
fontSize={{base:'18px',sm:'18px',md:'18px',lg:'20px'}}
color="#0F1414" lineHeight="28px" 
pt={{base:'5px',sm:'5px',md:'5px',lg:'20px'}} 
>
We care success relationships fuel success we love building.</Text>

                    </Box>

                    </Link>
                </Box>

            </Box>




           
        );
    }
}
 
export default Cardservices;