import { Box, ListItem, Text, UnorderedList } from "@chakra-ui/react";
import { Component } from "react";

class Microsoft_dynamics_consultant extends Component {
    state = {}
    render() {
        return (

            <Box>

                <Text fontFamily="Unbounded" fontWeight="700"
                    fontSize={{ base: '20px', sm: '20px', md: '20px', lg: '24px' }}
                    lineHeight={{ base: '35px', sm: '35px', md: '35px', lg: '65px' }}
                    color="black"
                    mt={{ base: '8px', sm: '8px', md: '8px', lg: '10px' }}

                >
                    Drive Business Efficiency and Transformation as a Microsoft Dynamics Consultant at Asture Consulting!                </Text>

                <Text fontFamily="Poppins" fontWeight="400"
                    fontSize={{ base: '17px', sm: '17px', md: '17px', lg: '20px' }}
                    color="black" lineHeight="28px">
                    Are you an experienced professional with a passion for helping businesses optimize their operations
                    and enhance customer engagement through Microsoft Dynamics 365? Do you have the skills to design
                    and implement innovative solutions that align with strategic business goals? If so,
                    <b>Asture Consulting</b> is looking for a <b>Microsoft Dynamics Consultant</b> to join our team and
                    empower our clients to achieve operational excellence.
                </Text>

                <Text fontFamily="Unbounded" fontWeight="700"
                    fontSize={{ base: '20px', sm: '20px', md: '20px', lg: '24px' }}
                    lineHeight={{ base: '35px', sm: '35px', md: '35px', lg: '65px' }}
                    color="black"
                    mt={{ base: '8px', sm: '8px', md: '8px', lg: '10px' }}

                >
                    About Asture Consulting                    </Text>


                <Text fontFamily="Poppins" fontWeight="400"
                    fontSize={{ base: '17px', sm: '17px', md: '17px', lg: '20px' }}
                    color="black" lineHeight="28px">
                    Asture Consulting is a leading provider of technology-driven solutions, helping organizations across industries streamline their operations and drive growth. We specialize in implementing and optimizing Microsoft Dynamics 365 to deliver
                    seamless ERP and CRM solutions that transform how businesses operate.
                </Text>


                <Text fontFamily="Unbounded" fontWeight="700"
                    fontSize={{ base: '20px', sm: '20px', md: '20px', lg: '24px' }}
                    lineHeight={{ base: '35px', sm: '35px', md: '35px', lg: '65px' }}
                    color="black"
                    mt={{ base: '8px', sm: '8px', md: '8px', lg: '10px' }}

                >
                    Role Overview                    </Text>



                <Text fontFamily="Poppins" fontWeight="400"
                    fontSize={{ base: '17px', sm: '17px', md: '17px', lg: '20px' }}
                    color="black" lineHeight="28px">
                    As a <b>Microsoft Dynamics Consultant,</b> you will work with clients to assess their needs, design tailored solutions and implement Microsoft Dynamics 365 for improved business performance. Your focus will include enhancing customer relationship management,
                    streamlining financial processes, and optimizing operational workflows.
                </Text>


                <Text fontFamily="Unbounded" fontWeight="700"
                    fontSize={{ base: '20px', sm: '20px', md: '20px', lg: '24px' }}
                    lineHeight={{ base: '35px', sm: '35px', md: '35px', lg: '65px' }}
                    color="black"
                    mt={{ base: '8px', sm: '8px', md: '8px', lg: '10px' }}

                >
                    Key Responsibilities  </Text>

                <UnorderedList
                    fontFamily="Poppins" fontWeight="500"
                    fontSize={{ base: '15px', sm: '15px', md: '15px', lg: '18px' }}
                    color="black" lineHeight="28px"
                >
                    <ListItem>Collaborate with clients to gather and analyze requirements for Microsoft Dynamics 365 implementations.</ListItem>
                    <ListItem>Design and customize Dynamics 365 solutions, including modules for CRM, ERP, and other business functions.</ListItem>
                    <ListItem>Integrate Dynamics 365 with existing systems and ensure seamless data flow and functionality.</ListItem>
                    <ListItem>Provide expert guidance on best practices for Dynamics 365 usage and adoption.</ListItem>
                    <ListItem>Deliver training sessions and documentation to empower clients to use Dynamics 365 effectively.</ListItem>
                    <ListItem>Troubleshoot and resolve issues related to Dynamics 365 implementation and performance.</ListItem>
                    <ListItem>Stay current with updates and new features in Microsoft Dynamics 365 to deliver innovative solutions.</ListItem>
                </UnorderedList>

                <Text fontFamily="Unbounded" fontWeight="700"
                    fontSize={{ base: '20px', sm: '20px', md: '20px', lg: '24px' }}
                    lineHeight={{ base: '35px', sm: '35px', md: '35px', lg: '65px' }}
                    color="black"
                    mt={{ base: '8px', sm: '8px', md: '8px', lg: '10px' }}

                >
                    What We’re Looking For </Text>

                {/* Bullets Start */}

                <Text fontFamily="Poppins" fontWeight="400"
                    fontSize={{ base: '15px', sm: '15px', md: '15px', lg: '18px' }}
                    color="black" lineHeight="28px" mt="10px">
                    <b>Educational Background:</b> Bachelor's degree in computer science, Information Technology, Business Administration, or a related field (certifications in Microsoft Dynamics 365 are a plus).
                </Text>

                <Text fontFamily="Poppins" fontWeight="400"
                    fontSize={{ base: '15px', sm: '15px', md: '15px', lg: '18px' }}
                    color="black" lineHeight="28px" mt="10px">
                    <b>Experience:</b> <br></br>
                    <UnorderedList
                        fontFamily="Poppins" fontWeight="400"
                        fontSize={{ base: '15px', sm: '15px', md: '15px', lg: '18px' }}
                        color="black" lineHeight="28px"
                    >
                        <ListItem>Minimum of 5 years of experience in implementing and managing Microsoft Dynamics 365 solutions.</ListItem>
                        <ListItem>Proven expertise in CRM and ERP modules of Dynamics 365.</ListItem>
                    </UnorderedList>
                </Text>

                <Text fontFamily="Poppins" fontWeight="400"
                    fontSize={{ base: '15px', sm: '15px', md: '15px', lg: '18px' }}
                    color="black" lineHeight="28px" mt="10px">
                    <b>Skills: </b> <br></br>
                    <UnorderedList
                        fontFamily="Poppins" fontWeight="400"
                        fontSize={{ base: '15px', sm: '15px', md: '15px', lg: '18px' }}
                        color="black" lineHeight="28px"
                    >
                        <ListItem>Strong knowledge of Microsoft Dynamics 365 architecture and its integration with other systems.</ListItem>
                        <ListItem>Experience in configuring and customizing Dynamics 365 to meet client needs.</ListItem>
                        <ListItem>Understanding of business processes related to finance, operations, and customer engagement.</ListItem>
                        <ListItem>Excellent communication and interpersonal skills for client interactions and team collaboration.</ListItem>
                        <ListItem>Analytical and problem-solving skills to address complex business challenges.</ListItem>
                    </UnorderedList>
                </Text>


                <Text fontFamily="Poppins" fontWeight="400"
                    fontSize={{ base: '15px', sm: '15px', md: '15px', lg: '18px' }}
                    color="black" lineHeight="28px" mt="10px">
                    <b>Mindset:</b> <br></br>
                    <UnorderedList
                        fontFamily="Poppins" fontWeight="400"
                        fontSize={{ base: '15px', sm: '15px', md: '15px', lg: '18px' }}
                        color="black" lineHeight="28px"
                    >
                        <ListItem>Passionate about driving digital transformation through technology.</ListItem>
                        <ListItem>Proactive, innovative, and committed to delivering exceptional client outcomes.</ListItem>
                    </UnorderedList>
                </Text>

                {/* Bullets End */}

                <Text fontFamily="Unbounded" fontWeight="700"
                    fontSize={{ base: '20px', sm: '20px', md: '20px', lg: '24px' }}
                    lineHeight={{ base: '35px', sm: '35px', md: '35px', lg: '65px' }}
                    color="black"
                    mt={{ base: '8px', sm: '8px', md: '8px', lg: '10px' }}

                >
                    What We Offer </Text>

                <UnorderedList
                    fontFamily="Poppins" fontWeight="400"
                    fontSize={{ base: '15px', sm: '15px', md: '15px', lg: '18px' }}
                    color="black" lineHeight="28px"
                >
                    <ListItem>Competitive salary with performance-based bonuses.</ListItem>
                    <ListItem>Opportunities for professional development, including Microsoft certifications.</ListItem>
                    <ListItem>Exposure to diverse industries and impactful projects.</ListItem>
                    <ListItem>A collaborative and supportive work environment that values innovation.</ListItem>
                    <ListItem>Flexibility and work-life balance to support your personal and professional goals.</ListItem>
                </UnorderedList>

                <Text fontFamily="Unbounded" fontWeight="700"
                    fontSize={{ base: '20px', sm: '20px', md: '20px', lg: '24px' }}
                    lineHeight={{ base: '35px', sm: '35px', md: '35px', lg: '65px' }}
                    color="black"
                    mt={{ base: '8px', sm: '8px', md: '8px', lg: '10px' }}

                >
                    How to Apply </Text>

                <Text fontFamily="Poppins" fontWeight="400"
                    fontSize={{ base: '17px', sm: '17px', md: '17px', lg: '20px' }}
                    color="black" lineHeight="28px">
                    If you’re ready to leverage your expertise in Microsoft Dynamics 365 to help businesses thrive, we want to hear from you! Submit your resume and a cover letter
                    detailing your experience and enthusiasm for Dynamics consulting to
                </Text>

                <Text fontFamily="Poppins" fontWeight="400"
                    fontSize={{ base: '17px', sm: '17px', md: '17px', lg: '20px' }}
                    color="#06BCD0" lineHeight="28px">
                    <b>
                        <a href="mailto:post@asture.co" > post@asture.co.</a>
                    </b>

                </Text>

                <Text fontFamily="Poppins" fontWeight="400"
                    fontSize={{ base: '17px', sm: '17px', md: '17px', lg: '20px' }}
                    color="black" lineHeight="28px" mt="10px">
                    <b>Asture Consulting is an equal opportunity employer.</b> We celebrate diversity and are committed to creating an inclusive workplace where everyone can thrive.
                </Text>

                <Text fontFamily="Poppins" fontWeight="400"
                    fontSize={{ base: '17px', sm: '17px', md: '17px', lg: '20px' }}
                    color="black" lineHeight="28px" mt="10px">
                    <b>Join Asture Consulting and make an impact with Microsoft Dynamics 365 – apply today!</b>
                </Text>


            </Box>
        );
    }
}

export default Microsoft_dynamics_consultant;