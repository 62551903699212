import { Box, Button, Flex, Img, ListItem, Stack, Text, UnorderedList } from "@chakra-ui/react";
import { Component } from "react";
import { Link as RouterLink } from 'react-router-dom'; // Import RouterLink from react-router-dom
import Banner from "../ContactUspage/Banner";

class Astureconsult extends Component {
  componentDidMount() {
    window.scrollTo(0, 0); // Scroll to the top of the page when the component mounts
  }
  state = {}
  render() {
    return (

      <Box>

        <Banner
          imageSrc="Asture Consult.jpg"
          text="Asture Consulting"
        />
        <Box px={{ base: '20px', sm: '20px', md: '20px', lg: '50px' }}
          w={{ base: 'none', sm: 'none', md: 'none', lg: '1300px' }}
          margin="70px auto"

        >
          <Text fontFamily="Unbounded" fontWeight="700"
            fontSize={{ base: '35px', sm: '35px', md: '35px', lg: '54px' }}
            color="#06BCD0" lineHeight="65px"
            w={{ base: 'none', sm: 'none', md: 'none', lg: '850px' }}
          >
            Asture Consult
          </Text>
          <Text fontFamily="Poppins" fontWeight="400"
            fontSize={{ base: '17px', sm: '17px', md: '17px', lg: '20px' }}
            color="black" lineHeight="28px" mt="30px">
            Asture Consult provides Consultancy as a Service (CaaS), offering on-demand IT consulting tailored to your business’s unique needs. Whether you require expert advice on digital transformation, technology adoption, or operational optimization, Asture Consult delivers personalized consulting without long-term commitments. By partnering with Asture, businesses can access a flexible pool of IT experts who bring industry-leading strategies, actionable insights, and hands-on support to
            help solve critical challenges and drive growth.


          </Text>


          {/* Packages*/}
          <Box display="flex"
            flexDirection={{ base: 'column', sm: 'column', md: 'column', lg: 'row' }}
            justifyContent="space-between" mt="60px" mb="20px"
            gap="15px"
          >

            <Box
              borderRadius="15px"
              padding="20px"
              width={{ base: 'none', sm: 'none', md: 'none', lg: '33%' }}
              boxShadow="0px 6px 4px 5px rgba(0,0,0,0.13);"
            >
              <Text fontFamily="Unbounded" fontWeight="700" fontSize="24px"
                color="black"
                textAlign="center"
              >
                Basic Consultation Package
              </Text>

              <Box display="flex"
                alignItems="center"
                justifyContent="center"
                gap="10px"
                mt="20px"
              >
                <Text fontFamily="Poppins" fontWeight="600" fontSize="28px"
                  textAlign="center"

                  color="#06BCD0">
                  €300 – €500
                </Text>

                <Text fontFamily="Poppins" fontWeight="500" fontSize="16px" color="black">
                  Price/Month
                </Text>
              </Box>




              <Text fontFamily="Poppins" fontWeight="500" fontSize="22px" color="black" mt="20px">
                Ideal for:
              </Text>

              <Text fontFamily="Poppins" fontWeight="400" fontSize="17px" color="black" lineHeight="28px">
                Small businesses or startups needing occasional expert advice for specific IT challenges.
              </Text>

              <Text fontFamily="Poppins" fontWeight="500" fontSize="22px" color="black" mt="20px">
                What’s Included:
              </Text>
              <Box display="flex"
                gap="14px"
                alignItems="flex-start"
                mt="15px"
              >
                <Img src="check.svg" />
                <Text fontFamily="Poppins" fontWeight="400" fontSize="17px" color="black" >
                  5 hours of on-demand consulting per month.
                </Text>
              </Box>

              <Box display="flex"
                gap="14px"
                alignItems="flex-start"
                mt="15px"
              >
                <Img src="check.svg" />
                <Text fontFamily="Poppins" fontWeight="400" fontSize="17px" color="black" >
                  Technology audit: A one-time assessment of your current technology setup to identify improvement areas.

                </Text>
              </Box>

              <Box display="flex"
                gap="14px"
                alignItems="flex-start"
                mt="15px"
              >
                <Img src="check.svg" />
                <Text fontFamily="Poppins" fontWeight="400" fontSize="17px" color="black" >
                  Single-project support: Guidance for addressing a specific issue such as cloud migration, process automation, or IT optimization.

                </Text>
              </Box>


              <Box display="flex"
                gap="14px"
                alignItems="flex-start"
                mt="15px"
              >
                <Img src="check.svg" />
                <Text fontFamily="Poppins" fontWeight="400" fontSize="17px" color="black" >
                  Email Support: Quick follow-up responses for ongoing issues.

                </Text>
              </Box>




              <Text fontFamily="Poppins" fontWeight="500" fontSize="22px" color="black" mt="227px">
                Why Choose This Package:
              </Text>
              <Text fontFamily="Poppins" fontWeight="400" fontSize="17px" color="black" mt="10px" >
                An affordable, entry-level solution for businesses looking to access IT expertise without the overhead of full-time staff.                                 </Text>
              <Box textAlign="center"
                mt="30px"
              >
                <Button as={RouterLink}
                  to="/Contactpage" bgColor="#06BCD0"
                  border="solid 1px" borderRadius="4px" borderColor="#06BCD0"
                  fontFamily="Poppins" fontWeight="500" fontSize="22px"
                  w="220px" h="55px" color="white"
                  _hover="none"
                  mt="20px"
                >
                  Contact Us
                </Button>
              </Box>



            </Box>


            <Box
              borderRadius="15px"
              padding="20px"
              width={{ base: 'none', sm: 'none', md: 'none', lg: '33%' }}
              boxShadow="0px 6px 4px 5px rgba(0,0,0,0.13);"

            >
              <Text fontFamily="Unbounded" fontWeight="700" fontSize="24px"
                color="black"
                textAlign="center"
              >
                Professional Strategy Package                  </Text>




              <Box
                display="flex"
                alignItems="center"
                justifyContent="center"
                gap="10px"
                mt="20px"
              >
                <Text fontFamily="Poppins" fontWeight="600" fontSize="28px"
                  textAlign="center"

                  color="#06BCD0">
                  €800 – €1,400          </Text>
                <Text fontFamily="Poppins" fontWeight="500" fontSize="16px" color="black">
                  Price/Month
                </Text>

              </Box>


              <Text fontFamily="Poppins" fontWeight="500" fontSize="22px" color="black" mt="20px">
                Ideal for:
              </Text>

              <Text fontFamily="Poppins" fontWeight="400" fontSize="17px" color="black" lineHeight="28px">
                Growing businesses that need continuous IT support and a roadmap for digital transformation.                  </Text>

              <Text fontFamily="Poppins" fontWeight="500" fontSize="22px" color="black" mt="20px">
                What’s Included:
              </Text>

              <Box
                display="flex"
                gap="14px"
                alignItems="flex-start"
                mt="15px"
              >
                <Img src="check.svg" />
                <Text fontFamily="Poppins" fontWeight="400" fontSize="17px" color="black" >
                  15 hours of on-demand consulting per month.
                </Text>
              </Box>

              <Box
                display="flex"
                gap="14px"
                alignItems="flex-start"
                mt="15px"
              >
                <Img src="check.svg" />
                <Text fontFamily="Poppins" fontWeight="400" fontSize="17px" color="black" >
                  Digital Transformation Roadmap: A customized strategy to guide your business through technology adoption and process optimization.                               </Text>
              </Box>

              <Box
                display="flex"
                gap="14px"
                alignItems="flex-start"
                mt="15px"
              >
                <Img src="check.svg" />
                <Text fontFamily="Poppins" fontWeight="400" fontSize="17px" color="black" >
                  Multi-project support: Assistance with multiple IT initiatives, such as Microsoft 365 integration, workflow automation, or cybersecurity improvements.
                </Text>
              </Box>


              <Box
                display="flex"
                gap="14px"
                alignItems="flex-start"
                mt="15px"
              >
                <Img src="check.svg" />
                <Text fontFamily="Poppins" fontWeight="400" fontSize="17px" color="black" >
                  Quarterly Review: Regular check-ins to evaluate progress and adjust strategies as needed.
                </Text>
              </Box>



              <Text fontFamily="Poppins" fontWeight="500" fontSize="22px" color="black"
                mt="173px">
                Why Choose This Package:
              </Text>

              <Text fontFamily="Poppins" fontWeight="400" fontSize="17px" color="black" mt="15px" >
                Perfect for businesses seeking more involved guidance to modernize their operations and adopt new technologies.                                </Text>

              <Box textAlign="center"
                mt="29px"
              >
                <Button as={RouterLink}
                  to="/Contactpage" bgColor="#06BCD0"
                  border="solid 1px" borderRadius="4px" borderColor="#06BCD0"
                  fontFamily="Poppins" fontWeight="500" fontSize="22px"
                  w="220px" h="55px" color="white"
                  _hover="none"
                  mt="20px"
                >
                  Contact Us
                </Button>
              </Box>


            </Box>


            <Box
              borderRadius="15px"
              padding="20px"
              width={{ base: 'none', sm: 'none', md: 'none', lg: '33%' }}
              boxShadow="0px 6px 4px 5px rgba(0,0,0,0.13);"

            >
              <Text fontFamily="Unbounded" fontWeight="700" fontSize="24px"
                color="black"
                textAlign="center"
              >
                Enterprise Partnership Package

              </Text>

              <Box
                display="flex"
                alignItems="center"
                justifyContent="center"
                gap="10px"
                mt="20px"
              >
                <Text fontFamily="Poppins" fontWeight="600" fontSize="28px"

                  color="#06BCD0" textAlign="center">
                  Contact us for a pricing quote           </Text>
                {/* <Text fontFamily="Poppins" fontWeight="500" fontSize="16px" color="black">
                   Price/Month
                 </Text>   */}
              </Box>



              <Text fontFamily="Poppins" fontWeight="500" fontSize="22px" color="black" mt="20px">
                Ideal for:
              </Text>

              <Text fontFamily="Poppins" fontWeight="400" fontSize="17px" color="black" >
                Medium to large businesses requiring comprehensive IT consulting and long-term strategic support.
              </Text>


              <Text fontFamily="Poppins" fontWeight="500" fontSize="22px" color="black" mt="20px">
                What’s Included:
              </Text>

              <Box
                display="flex"
                gap="14px"
                alignItems="flex-start"
                mt="15px"
              >                  <Img src="check.svg" />


                <Text fontFamily="Poppins" fontWeight="400" fontSize="17px" color="black" >
                  Flexible hours of on-demand consulting per month.
                </Text>
              </Box>


              <Box
                display="flex"
                gap="14px"
                alignItems="flex-start"
                mt="15px"
              >                  <Img src="check.svg" />


                <Text fontFamily="Poppins" fontWeight="400" fontSize="17px" color="black" >
                  Dedicated IT Consultant: A personalized consultant assigned to your business for in-depth, tailored advice and leadership.
                </Text>
              </Box>

              <Box
                display="flex"
                gap="14px"
                alignItems="flex-start"
                mt="15px"
              >                  <Img src="check.svg" />


                <Text fontFamily="Poppins" fontWeight="400" fontSize="17px" color="black" >
                  End-to-End Project Management: Comprehensive support for large-scale projects such as enterprise-wide cloud migration, AI integration, or advanced business automation.
                </Text>
              </Box>

              <Box
                display="flex"
                gap="14px"
                alignItems="flex-start"
                mt="15px"
              >                  <Img src="check.svg" />


                <Text fontFamily="Poppins" fontWeight="400" fontSize="17px" color="black" >
                  Monthly Progress Reviews: Detailed updates to ensure that your IT strategy remains aligned with your business objectives.
                </Text>
              </Box>


              <Box
                display="flex"
                gap="14px"
                alignItems="flex-start"
                mt="15px"
              >                  <Img src="check.svg" />


                <Text fontFamily="Poppins" fontWeight="400" fontSize="17px" color="black" >
                  Priority Support: Fast responses to urgent issues via email or phone (remote).
                </Text>
              </Box>




              <Text fontFamily="Poppins" fontWeight="500" fontSize="22px" color="black" mt="20px">
                Why Choose This Package:
              </Text>

              <Text fontFamily="Poppins" fontWeight="400" fontSize="17px" color="black" mt="15px" >
                Ideal for businesses looking for a strategic IT partner to drive ongoing initiatives and ensure long-term success.
              </Text>

              <Box textAlign="center" mt="5px">

                <Button as={RouterLink}
                  to="/Contactpage" bgColor="#06BCD0"
                  border="solid 1px" borderRadius="4px" borderColor="#06BCD0"
                  fontFamily="Poppins" fontWeight="500" fontSize="22px"
                  w="220px" h="55px" color="white"
                  _hover="none"
                  mt="20px"
                >
                  Contact Us
                </Button>
              </Box>

            </Box>

          </Box>


          <Text fontFamily="Unbounded" fontWeight="700" fontSize="24px"
            color="black" lineHeight="65px"
            mt={{ base: '0px', sm: '0px', md: '0px', lg: '67px' }}
          >
            Optional Add-Ons for All Packages:  </Text>
          <Text fontFamily="Poppins" fontWeight="500" fontSize="20px" color="black">
            Additional Consulting Hours:  </Text>
          <Text fontFamily="Poppins" fontWeight="600" fontSize="22px" color="#06BCD0" mt="5px">
            Price: €100 – €150/hour
          </Text>
          <Text fontFamily="Poppins" fontWeight="400" fontSize="16px" color="black" mt="5px">
            Extra hours can be purchased as needed to extend your monthly consulting time.
          </Text>

          <Text fontFamily="Poppins" fontWeight="500" fontSize="20px" color="black" mt="20px">
            Custom Training Programs:     </Text>
          <Text fontFamily="Poppins" fontWeight="600" fontSize="22px" color="#06BCD0" mt="5px">
            Price: €500 – €2,000
          </Text>
          <Text fontFamily="Poppins" fontWeight="400" fontSize="16px" color="black" mt="5px">
            Tailored training sessions for your team in areas such as Microsoft 365, Power Platform, cybersecurity, or other IT solutions.
          </Text>

          <Text fontFamily="Unbounded" fontWeight="700" fontSize="24px"
            color="black" lineHeight="65px" mt="67px" >
            Key Features:                  </Text>

          <Text fontFamily="Poppins" fontWeight="500" fontSize="20px" color="black">
            On-Demand IT Expertise: </Text>
          <Text fontFamily="Poppins" fontWeight="400" fontSize="16px" color="black" mt="5px">
            Access a team of IT consultants as and when you need them, without the need for a full-time in-house team.
          </Text>

          <Text fontFamily="Poppins" fontWeight="500" fontSize="20px" color="black" pt="20px">
            Tailored Solutions:  </Text>
          <Text fontFamily="Poppins" fontWeight="400" fontSize="16px" color="black" mt="5px" >
            We design custom strategies that align with your specific business goals and industry needs.   </Text>

          <Text fontFamily="Poppins" fontWeight="500" fontSize="20px" color="black" pt="20px" >
            Flexible Engagements:   </Text>
          <Text fontFamily="Poppins" fontWeight="400" fontSize="16px" color="black" mt="5px" >
            Our service is scalable, allowing you to choose the level of consulting support needed, whether it’s one-off advice <br />or ongoing consulting.
          </Text>


          <Text fontFamily="Poppins" fontWeight="400"
            fontSize={{ base: '17px', sm: '17px', md: '17px', lg: '20px' }}
            color="black" lineHeight="28px" mt="20px">
            With Asture Consult, you benefit from the deep expertise of a full-service IT consulting firm, but with the flexibility and cost-efficiency of consultancy as a service. Let us guide your organization through technology challenges, enabling you to stay competitive and innovative.
          </Text>

          <Img mt="30px" src="Asture Consult1.jpg" />

        </Box>
      </Box>

    );
  }
}

export default Astureconsult;