import { Box, ListItem, Text, UnorderedList } from "@chakra-ui/react";
import { Component } from "react";

class Sales_manager extends Component {
    state = {}
    render() {
        return (
            <Box>

                <Text fontFamily="Unbounded" fontWeight="700"
                    fontSize={{ base: '20px', sm: '20px', md: '20px', lg: '24px' }}
                    lineHeight={{ base: '35px', sm: '35px', md: '35px', lg: '65px' }}
                    color="black"
                    mt={{ base: '8px', sm: '8px', md: '8px', lg: '10px' }}

                >
                    Drive Growth and Success as a Sales Manager at Asture Consulting!
                </Text>

                <Text fontFamily="Poppins" fontWeight="400"
                    fontSize={{ base: '17px', sm: '17px', md: '17px', lg: '20px' }}
                    color="black" lineHeight="28px">
                    Are you a results-driven sales professional with a passion for building relationships and driving revenue growth? Do you
                    have the leadership skills to guide a dynamic team to success? If so, <b>Asture Consulting</b> invites
                    you to join us as a <b>Sales Manager</b> and play a pivotal role in our continued growth and success.
                </Text>

                <Text fontFamily="Unbounded" fontWeight="700"
                    fontSize={{ base: '20px', sm: '20px', md: '20px', lg: '24px' }}
                    lineHeight={{ base: '35px', sm: '35px', md: '35px', lg: '65px' }}
                    color="black"
                    mt={{ base: '8px', sm: '8px', md: '8px', lg: '10px' }}

                >
                    About Asture Consulting                    </Text>


                <Text fontFamily="Poppins" fontWeight="400"
                    fontSize={{ base: '17px', sm: '17px', md: '17px', lg: '20px' }}
                    color="black" lineHeight="28px">
                    Asture Consulting is a leading provider of innovative business and technology solutions, helping organizations across industries achieve their goals. We are known for our commitment to excellence, collaboration, and delivering measurable results. As a Sales Manager, you will be at the forefront of our growth strategy, leading efforts to expand our client base and maximize market opportunities.
                </Text>


                <Text fontFamily="Unbounded" fontWeight="700"
                    fontSize={{ base: '20px', sm: '20px', md: '20px', lg: '24px' }}
                    lineHeight={{ base: '35px', sm: '35px', md: '35px', lg: '65px' }}
                    color="black"
                    mt={{ base: '8px', sm: '8px', md: '8px', lg: '10px' }}

                >
                    Role Overview                    </Text>



                <Text fontFamily="Poppins" fontWeight="400"
                    fontSize={{ base: '17px', sm: '17px', md: '17px', lg: '20px' }}
                    color="black" lineHeight="28px">
                    As a <b>Sales Manager,</b> you will develop and execute sales strategies, manage a high-performing sales team, and build strong relationships with clients. Your focus will be on driving revenue, achieving sales targets, and positioning Asture Consulting as a trusted partner for businesses seeking transformative solutions.
                </Text>


                <Text fontFamily="Unbounded" fontWeight="700" fontSize="24px"
                    color="black" lineHeight="65px"
                    mt={{ base: '8px', sm: '8px', md: '8px', lg: '10px' }}

                >
                    Key Responsibilities  </Text>

                <UnorderedList
                    fontFamily="Poppins" fontWeight="500"
                    fontSize={{ base: '15px', sm: '15px', md: '15px', lg: '18px' }}
                    color="black" lineHeight="28px"
                >
                    <ListItem>Develop and implement sales strategies to achieve business growth and revenue targets.</ListItem>
                    <ListItem>Lead, mentor, and manage a team of sales professionals, fostering a high-performance culture.</ListItem>
                    <ListItem>Identify new business opportunities and build strong, lasting relationships with clients.</ListItem>
                    <ListItem>Oversee the sales pipeline, ensuring accurate forecasting and reporting.</ListItem>
                    <ListItem>Collaborate with marketing and product teams to align sales strategies with business goals.</ListItem>
                    <ListItem>Monitor market trends and competitor activities to identify areas for competitive advantage.</ListItem>
                    <ListItem>Represent Asture Consulting at industry events, conferences, and client meetings.</ListItem>
                </UnorderedList>

                <Text fontFamily="Unbounded" fontWeight="700"
                    fontSize={{ base: '20px', sm: '20px', md: '20px', lg: '24px' }}
                    lineHeight={{ base: '35px', sm: '35px', md: '35px', lg: '65px' }}
                    color="black"
                    mt={{ base: '8px', sm: '8px', md: '8px', lg: '10px' }}

                >
                    What We’re Looking For </Text>

                {/* Bullets Start */}

                <Text fontFamily="Poppins" fontWeight="400"
                    fontSize={{ base: '15px', sm: '15px', md: '15px', lg: '18px' }}
                    color="black" lineHeight="28px" mt="10px">
                    <b>Educational Background:</b> Bachelor’s degree in Business, Marketing, or a related field (MBA preferred).
                </Text>

                <Text fontFamily="Poppins" fontWeight="400"
                    fontSize={{ base: '15px', sm: '15px', md: '15px', lg: '18px' }}
                    color="black" lineHeight="28px" mt="10px">
                    <b>Experience:</b> <br></br>
                    <UnorderedList
                        fontFamily="Poppins" fontWeight="400"
                        fontSize={{ base: '15px', sm: '15px', md: '15px', lg: '18px' }}
                        color="black" lineHeight="28px"
                    >
                        <ListItem>Minimum of 5 years of sales experience, with at least 2 years in a managerial or leadership role.</ListItem>
                        <ListItem>Proven track record of meeting or exceeding sales targets.</ListItem>
                    </UnorderedList>
                </Text>

                <Text fontFamily="Poppins" fontWeight="400"
                    fontSize={{ base: '15px', sm: '15px', md: '15px', lg: '18px' }}
                    color="black" lineHeight="28px" mt="10px">
                    <b>Skills: </b> <br></br>
                    <UnorderedList
                        fontFamily="Poppins" fontWeight="400"
                        fontSize={{ base: '15px', sm: '15px', md: '15px', lg: '18px' }}
                        color="black" lineHeight="28px"
                    >
                        <ListItem>Strong leadership and team management abilities.</ListItem>
                        <ListItem>Excellent communication, negotiation, and presentation skills.</ListItem>
                        <ListItem>Strategic thinking with the ability to analyze data and make informed decisions.</ListItem>
                        <ListItem>Proficiency in CRM tools and sales management software.</ListItem>
                    </UnorderedList>
                </Text>


                <Text fontFamily="Poppins" fontWeight="400"
                    fontSize={{ base: '15px', sm: '15px', md: '15px', lg: '18px' }}
                    color="black" lineHeight="28px" mt="10px">
                    <b>Mindset:</b> <br></br>
                    <UnorderedList
                        fontFamily="Poppins" fontWeight="400"
                        fontSize={{ base: '15px', sm: '15px', md: '15px', lg: '18px' }}
                        color="black" lineHeight="28px"
                    >
                        <ListItem>Results-driven and motivated by achieving goals.</ListItem>
                        <ListItem>A proactive, collaborative, and client-focused approach to sales.</ListItem>
                        <ListItem>Adaptable and innovative in a fast-paced business environment.</ListItem>

                    </UnorderedList>
                </Text>

                {/* Bullets End */}

                <Text fontFamily="Unbounded" fontWeight="700"
                    fontSize={{ base: '20px', sm: '20px', md: '20px', lg: '24px' }}
                    lineHeight={{ base: '35px', sm: '35px', md: '35px', lg: '65px' }}
                    color="black"
                    mt={{ base: '8px', sm: '8px', md: '8px', lg: '10px' }}

                >
                    What We Offer </Text>

                <UnorderedList
                    fontFamily="Poppins" fontWeight="400"
                    fontSize={{ base: '15px', sm: '15px', md: '15px', lg: '18px' }}
                    color="black" lineHeight="28px"
                >
                    <ListItem>Competitive salary with performance-based incentives and bonuses.</ListItem>
                    <ListItem>Opportunities for professional development and career advancement.</ListItem>
                    <ListItem>A collaborative, dynamic work environment where your contributions are valued.</ListItem>
                    <ListItem>Exposure to diverse industries and innovative solutions.</ListItem>
                    <ListItem>Work-life balance and supportive company culture.</ListItem>
                </UnorderedList>

                <Text fontFamily="Unbounded" fontWeight="700"
                    fontSize={{ base: '20px', sm: '20px', md: '20px', lg: '24px' }}
                    lineHeight={{ base: '35px', sm: '35px', md: '35px', lg: '65px' }}
                    color="black"
                    mt={{ base: '8px', sm: '8px', md: '8px', lg: '10px' }}

                >
                    How to Apply </Text>

                <Text fontFamily="Poppins" fontWeight="400"
                    fontSize={{ base: '17px', sm: '17px', md: '17px', lg: '20px' }}
                    color="black" lineHeight="28px">
                    If you’re ready to take your sales career to the next level and be a part of a thriving organization, we’d love to hear from you! Submit your resume and a cover letter highlighting your achievements
                    and how you can contribute to Asture Consulting’s success to
                </Text>
                <Text fontFamily="Poppins" fontWeight="400"
                    fontSize={{ base: '17px', sm: '17px', md: '17px', lg: '20px' }}
                    color="#06BCD0" lineHeight="28px">
                    <b>
                        <a href="mailto:post@asture.co" > post@asture.co.</a>
                    </b>

                </Text>

                <Text fontFamily="Poppins" fontWeight="400"
                    fontSize={{ base: '17px', sm: '17px', md: '17px', lg: '20px' }}
                    color="black" lineHeight="28px" mt="10px">
                    <b>Asture Consulting is an equal opportunity employer.</b> We celebrate diversity and are
                    committed to creating an inclusive workplace where everyone can thrive.
                </Text>

                <Text fontFamily="Poppins" fontWeight="400"
                    fontSize={{ base: '17px', sm: '17px', md: '17px', lg: '20px' }}
                    color="black" lineHeight="28px" mt="10px">
                    <b>Join Asture Consulting and lead the charge in driving business success – apply now!</b>
                </Text>


            </Box>
        );
    }
}

export default Sales_manager;