import { Box, ListItem, Text, UnorderedList } from "@chakra-ui/react";
import { Component } from "react";

class Modern_workplace_consultant extends Component {
    state = {}
    render() {
        return (
            <Box>

                <Text fontFamily="Unbounded" fontWeight="700"
                    fontSize={{ base: '20px', sm: '20px', md: '20px', lg: '24px' }}
                    lineHeight={{ base: '35px', sm: '35px', md: '35px', lg: '65px' }}
                    color="black"
                    mt={{ base: '8px', sm: '8px', md: '8px', lg: '10px' }}

                >
                    Empower Businesses to Thrive in the Digital Age as a Modern Workplace Consultant at Asture Consulting!
                </Text>

                <Text fontFamily="Poppins" fontWeight="400"
                    fontSize={{ base: '17px', sm: '17px', md: '17px', lg: '20px' }}
                    color="black" lineHeight="28px">
                    Are you passionate about leveraging modern technologies to transform the way businesses operate? Do you have expertise in implementing cutting-edge workplace solutions that enhance collaboration,
                    productivity, and employee experience? If so, <b>Asture Consulting</b> is looking for a <b>Modern Workplace Consultant</b> to join our innovative team.
                </Text>

                <Text fontFamily="Unbounded" fontWeight="700"
                    fontSize={{ base: '20px', sm: '20px', md: '20px', lg: '24px' }}
                    lineHeight={{ base: '35px', sm: '35px', md: '35px', lg: '65px' }}
                    color="black"
                    mt={{ base: '8px', sm: '8px', md: '8px', lg: '10px' }}

                >
                    About Asture Consulting                    </Text>


                <Text fontFamily="Poppins" fontWeight="400"
                    fontSize={{ base: '17px', sm: '17px', md: '17px', lg: '20px' }}
                    color="black" lineHeight="28px">
                    Asture Consulting is a leader in providing technology-driven solutions that empower organizations to excel in today’s fast-evolving business landscape. We specialize in helping businesses adopt modern workplace technologies, ensuring seamless digital transformations and fostering efficiency, innovation, and collaboration.
                </Text>


                <Text fontFamily="Unbounded" fontWeight="700"
                    fontSize={{ base: '20px', sm: '20px', md: '20px', lg: '24px' }}
                    lineHeight={{ base: '35px', sm: '35px', md: '35px', lg: '65px' }}
                    color="black"
                    mt={{ base: '8px', sm: '8px', md: '8px', lg: '10px' }}

                >
                    Role Overview                    </Text>



                <Text fontFamily="Poppins" fontWeight="400"
                    fontSize={{ base: '17px', sm: '17px', md: '17px', lg: '20px' }}
                    color="black" lineHeight="28px">
                    As a <b>Modern Workplace Consultant,</b> you will work with clients to design, implement, and optimize workplace solutions using the latest tools and technologies. Your goal will be to enable businesses to create flexible, collaborative, and highly productive environments that align with their strategic goals.
                </Text>


                <Text fontFamily="Unbounded" fontWeight="700"
                    fontSize={{ base: '20px', sm: '20px', md: '20px', lg: '24px' }}
                    lineHeight={{ base: '35px', sm: '35px', md: '35px', lg: '65px' }}
                    color="black"
                    mt={{ base: '8px', sm: '8px', md: '8px', lg: '10px' }}

                >
                    Key Responsibilities  </Text>

                <UnorderedList
                    fontFamily="Poppins" fontWeight="500"
                    fontSize={{ base: '15px', sm: '15px', md: '15px', lg: '18px' }}
                    color="black" lineHeight="28px"
                >
                    <ListItem>Collaborate with clients to assess their workplace needs and develop tailored digital transformation strategies.</ListItem>
                    <ListItem>Implement and optimize workplace solutions such as Microsoft 365, Microsoft Teams, SharePoint, and other collaboration tools.</ListItem>
                    <ListItem>Provide expertise in leveraging AI-powered tools like Microsoft Copilot to enhance workplace productivity.</ListItem>
                    <ListItem>Conduct training sessions and workshops to empower teams to effectively use modern workplace tools.</ListItem>
                    <ListItem>Troubleshoot and resolve challenges related to digital workplace adoption and integration.</ListItem>
                    <ListItem>Stay updated on the latest trends and advancements in workplace technology to provide clients with forward-thinking solutions.</ListItem>
                    <ListItem>Ensure successful project delivery within scope, timeline, and budget while maintaining high client satisfaction.</ListItem>
                </UnorderedList>

                <Text fontFamily="Unbounded" fontWeight="700"
                    fontSize={{ base: '20px', sm: '20px', md: '20px', lg: '24px' }}
                    lineHeight={{ base: '35px', sm: '35px', md: '35px', lg: '65px' }}
                    color="black"
                    mt={{ base: '8px', sm: '8px', md: '8px', lg: '10px' }}

                >
                    What We’re Looking For </Text>

                {/* Bullets Start */}

                <Text fontFamily="Poppins" fontWeight="400"
                    fontSize={{ base: '15px', sm: '15px', md: '15px', lg: '18px' }}
                    color="black" lineHeight="28px" mt="10px">
                    <b>Educational Background:</b>Bachelor’s degree in IT, Business Administration, or a related field (certifications in Microsoft technologies are a plus).
                </Text>

                <Text fontFamily="Poppins" fontWeight="400"
                    fontSize={{ base: '15px', sm: '15px', md: '15px', lg: '18px' }}
                    color="black" lineHeight="28px" mt="10px">
                    <b>Experience:</b> <br></br>
                    <UnorderedList
                        fontFamily="Poppins" fontWeight="400"
                        fontSize={{ base: '15px', sm: '15px', md: '15px', lg: '18px' }}
                        color="black" lineHeight="28px"
                    >
                        <ListItem>Minimum of 3-5 years of experience in workplace consulting, IT consulting, or a related role.</ListItem>
                        <ListItem>Hands-on experience with implementing and managing Microsoft 365 solutions.</ListItem>
                    </UnorderedList>
                </Text>

                <Text fontFamily="Poppins" fontWeight="400"
                    fontSize={{ base: '15px', sm: '15px', md: '15px', lg: '18px' }}
                    color="black" lineHeight="28px" mt="10px">
                    <b>Skills: </b> <br></br>
                    <UnorderedList
                        fontFamily="Poppins" fontWeight="400"
                        fontSize={{ base: '15px', sm: '15px', md: '15px', lg: '18px' }}
                        color="black" lineHeight="28px"
                    >
                        <ListItem>Strong knowledge of modern workplace tools, including Microsoft 365, Microsoft Teams, SharePoint, and Power Platform.</ListItem>
                        <ListItem>Excellent communication and interpersonal skills for collaborating with clients and teams.</ListItem>
                        <ListItem>Analytical and problem-solving abilities to address complex workplace challenges.</ListItem>
                        <ListItem>Project management skills to ensure successful solution delivery.</ListItem>
                    </UnorderedList>
                </Text>


                <Text fontFamily="Poppins" fontWeight="400"
                    fontSize={{ base: '15px', sm: '15px', md: '15px', lg: '18px' }}
                    color="black" lineHeight="28px" mt="10px">
                    <b>Mindset:</b> <br></br>
                    <UnorderedList
                        fontFamily="Poppins" fontWeight="400"
                        fontSize={{ base: '15px', sm: '15px', md: '15px', lg: '18px' }}
                        color="black" lineHeight="28px"
                    >
                        <ListItem>Passionate about digital transformation and driving change through technology.</ListItem>
                        <ListItem>Proactive, adaptable, and committed to delivering exceptional client outcomes.</ListItem>

                    </UnorderedList>
                </Text>

                {/* Bullets End */}

                <Text fontFamily="Unbounded" fontWeight="700"
                    fontSize={{ base: '20px', sm: '20px', md: '20px', lg: '24px' }}
                    lineHeight={{ base: '35px', sm: '35px', md: '35px', lg: '65px' }}
                    color="black"
                    mt={{ base: '8px', sm: '8px', md: '8px', lg: '10px' }}

                >
                    What We Offer </Text>

                <UnorderedList
                    fontFamily="Poppins" fontWeight="400"
                    fontSize={{ base: '15px', sm: '15px', md: '15px', lg: '18px' }}
                    color="black" lineHeight="28px"
                >
                    <ListItem>Competitive salary with performance-based incentives.</ListItem>
                    <ListItem>Opportunities for continuous learning and professional development, including certifications.</ListItem>
                    <ListItem>Exposure to diverse industries and innovative projects.</ListItem>
                    <ListItem>A supportive, collaborative work environment that values creativity and growth.</ListItem>
                    <ListItem>Flexibility and work-life balance to support your personal and professional goals.</ListItem>
                </UnorderedList>

                <Text fontFamily="Unbounded" fontWeight="700"
                    fontSize={{ base: '20px', sm: '20px', md: '20px', lg: '24px' }}
                    lineHeight={{ base: '35px', sm: '35px', md: '35px', lg: '65px' }}
                    color="black"
                    mt={{ base: '8px', sm: '8px', md: '8px', lg: '10px' }}

                >
                    How to Apply </Text>

                <Text fontFamily="Poppins" fontWeight="400"
                    fontSize={{ base: '17px', sm: '17px', md: '17px', lg: '20px' }}
                    color="black" lineHeight="28px">
                    If you’re ready to help businesses unlock the potential of modern workplace technologies, we’d love to hear from you! Submit your resume and a
                    cover letter detailing your experience and passion for modern workplace solutions to
                    <b>
                        <a href="mailto:post@asture.co"> post@asture.co.</a>
                    </b>
                </Text>

                <Text fontFamily="Poppins" fontWeight="400"
                    fontSize={{ base: '17px', sm: '17px', md: '17px', lg: '20px' }}
                    color="black" lineHeight="28px" mt="10px">
                    <b>Asture Consulting is an equal opportunity employer.</b> We celebrate diversity and are
                    committed to creating an inclusive workplace where everyone can thrive.
                </Text>

                <Text fontFamily="Poppins" fontWeight="400"
                    fontSize={{ base: '17px', sm: '17px', md: '17px', lg: '20px' }}
                    color="black" lineHeight="28px" mt="10px">
                    <b>Join us at Asture Consulting and be a driving force in shaping the workplaces of tomorrow – apply today!</b>
                </Text>


            </Box>
        );
    }
}

export default Modern_workplace_consultant;