import { Box, Img, Text } from "@chakra-ui/react";
import { Component } from "react";

class Teamsdetail extends Component {
    state = {  } 
    render() { 
        return (
            <Box px={{base:'20px',sm:'20px' ,md:'20px' , lg:'50px'}}
            w="1300px"
            margin="70px auto"
 
             >
                   <Text fontFamily="Unbounded" fontWeight="700" fontSize="54px"
                    color="#06BCD0" lineHeight="65px" width="850px">
Teamsdetail
                 </Text>
                 <Text fontFamily="Poppins" fontWeight="400" fontSize="20px" color="black" lineHeight="28px" mt="30px">
                 Microsoft Azure is an enterprise solution that offers infrastructure as a service 
                 (IaaS), platform as a service (Paas), software as a service (SaaS), and serverless cloud 
                 computing options.
                 </Text>
                 <Text fontFamily="Poppins" fontWeight="400" fontSize="20px" color="black" lineHeight="28px" mt="20px">
                 Highly scalable, Azure allows you to only pay for the services that you need while keeping your data secure.
                 The challenge is, how do you know which services you need?
                 </Text>
                 <Text fontFamily="Poppins" fontWeight="400" fontSize="20px" color="black" lineHeight="28px" mt="20px">
                 As a Microsoft Solutions Partner for Azure — in the areas of Data & AI, Infrastructure, and Digital & App Innovation, our team of professionals collaborate with you to truly understand your business and technical requirements.
                 </Text>
                 <Text fontFamily="Poppins" fontWeight="400" fontSize="20px" color="black" lineHeight="28px" mt="20px">
                 We can then help you accelerate your modern software delivery via cloud technologies.</Text>
                 <Text fontFamily="Poppins" fontWeight="400" fontSize="20px" color="black" lineHeight="28px" mt="20px">
                 Together, we can build solutions that allow you to migrate to the cloud safely and efficiently — on your own terms.
                 </Text>
 
 
                 <Text fontFamily="Unbounded" fontWeight="700" fontSize="24px"
                    color="black" lineHeight="65px" mt="67px" >
                     OUR MICROSOFT AZURE CONSULTING SERVICES
                 </Text>
 
                 <Text fontFamily="Poppins" fontWeight="400" fontSize="20px" color="black" lineHeight="28px" mt="20px">
                 We can help you create and implement the business and technology strategies necessary for your organization to succeed in the cloud with Microsoft Azure. Our Microsoft consultants have the best practices, documentation and tools that cloud architects, IT professionals and business decision-makers need to successfully 
                 achieve short-term and long-term objectives.
                 </Text>
 
                 <Img mt="30px" src="detailpageimg.png" />
 
             </Box>
        );
    }
}
 
export default Teamsdetail;