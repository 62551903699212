import { Box, Flex, Img, ListItem, Text, UnorderedList } from "@chakra-ui/react";
import { Component } from "react";
import Banner from "../ContactUspage/Banner";

class Dynamicsdetail extends Component {
    componentDidMount() {
        window.scrollTo(0, 0); // Scroll to the top of the page when the component mounts
      }
    state = {  } 
    render() { 
        return (

          <Box>

<Banner 
        imageSrc="Dynamic 365.jpg" 
        text="Dynamic 365 " 
      />

<Box px={{base:'20px',sm:'20px' ,md:'20px' , lg:'50px'}}
            w={{base:'none',sm:'none' ,md:'none' , lg:'1300px'}}
            margin="70px auto"
 
             >
                   <Text fontFamily="Unbounded" fontWeight="700"
                   fontSize={{base:'35px',sm:'35px' ,md:'35px',lg:'54px'}}
                   color="#06BCD0" lineHeight="65px"
                    w={{base:'none',sm:'none' ,md:'none' , lg:'850px'}}
                     >
                    Dynamic 365
                 </Text>
                 <Text fontFamily="Poppins" fontWeight="400" 
                  fontSize={{base:'17px',sm:'17px' ,md:'17px',lg:'20px'}}
                  color="black" lineHeight="28px" mt="30px">
                 Microsoft Dynamics 365 is an all-in-one solution for managing customer relationships, operations, and financials. Asture Consulting specializes in the implementation, customization, and integration of Dynamics 365, helping businesses streamline operations and enhance customer engagement through a unified platform that covers ERP and CRM functionality. 
                 </Text>
       
 
 
                 <Text fontFamily="Unbounded" fontWeight="700" fontSize="24px"
                    color="black" lineHeight="65px" 
                    mt={{base:'8px',sm:'8px' ,md:'8px',lg:'67px'}}
                     >
                        Key Features: 
                 </Text>

    <Text fontFamily="Poppins" fontWeight="500" fontSize="20px" color="black">
    ERP and CRM Solutions:    </Text>
  <Text fontFamily="Poppins" fontWeight="400" fontSize="16px" color="black"mt="5px" >
  Manage your entire business from sales and marketing to operations and finance, all within Dynamics 365. 
        </Text>

  
     <Text fontFamily="Poppins" fontWeight="500" fontSize="20px" color="black" pt="20px">
  Custom Integrations:       </Text>
  <Text fontFamily="Poppins" fontWeight="400" fontSize="16px" color="black" mt="5px" >
  Integrate Dynamics 365 with existing systems to create a seamless and unified business environment.     </Text>

     <Text fontFamily="Poppins" fontWeight="500" fontSize="20px" color="black" pt="20px" >
  AI-Driven Insights:    </Text>
  <Text fontFamily="Poppins" fontWeight="400" fontSize="16px" color="black" mt="5px" >
  Use built-in AI capabilities to gain actionable insights, improve decision-making, and drive innovation. s          </Text>



                 <Text fontFamily="Poppins" fontWeight="400"
                  fontSize={{base:'17px',sm:'17px' ,md:'17px',lg:'20px'}}
                  color="black" lineHeight="28px" mt="20px">
                 Our expertise in Microsoft Dynamics 365 enables us to tailor the platform to your specific business needs, ensuring you get the most out of this powerful solution. Whether you're looking to enhance customer relationships or streamline operations, we provide end-to-end services to support your journey. 
                 </Text>
 
                 <Img mt="30px" src="Dynamic 3651.jpg" />
 
             </Box>


          </Box>
          
        );
    }
}
 
export default Dynamicsdetail;