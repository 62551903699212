import { Box, Flex, Img, ListItem, Text, UnorderedList } from "@chakra-ui/react";
import { Component } from "react";
import Banner from "../ContactUspage/Banner";

class Whoweare extends Component {
    componentDidMount() {
        window.scrollTo(0, 0); // Scroll to the top of the page when the component mounts
    }
    state = {}
    render() {
        return (

            <Box>

                <Banner
                    imageSrc="Who We Are.jpg"
                    text="Who we are"
                />


                <Box px={{ base: '20px', sm: '20px', md: '20px', lg: '50px' }}
                    w={{ base: 'none', sm: 'none', md: 'none', lg: '1300px' }}
                    margin="70px auto"

                >
                    <Text fontFamily="Unbounded" fontWeight="700"
                        fontSize={{ base: '35px', sm: '35px', md: '35px', lg: '54px' }}
                        color="#06BCD0"
                        lineHeight={{ base: '45px', sm: '45px', md: '45px', lg: '65px' }}
                        w={{ base: 'none', sm: 'none', md: 'none', lg: '850px' }}
                    >
                        Asture: The Essence of Astuteness in a Modern Company                     </Text>
                    <Text fontFamily="Poppins" fontWeight="400"
                        fontSize={{ base: '17px', sm: '17px', md: '17px', lg: '20px' }}
                        color="black" lineHeight="28px" mt="30px">
                        At Asture Consulting, our name is more than a brand—it’s a reflection of who we are and how we operate. Derived from the word astute, Asture embodies keen insight, sharp judgment, and strategic acumen. These qualities are the foundation of everything we do, enabling us to
                        navigate complex challenges and deliver meaningful, results-driven solutions.

                    </Text>

                    <Text fontFamily="Poppins" fontWeight="400"
                        fontSize={{ base: '17px', sm: '17px', md: '17px', lg: '20px' }}
                        color="black" lineHeight="28px" mt="30px">
                        Just as an astute mind quickly comprehends situations and capitalizes on opportunities, Asture Consulting thrives on insight-driven growth, agility, and smart, effective strategies that make a lasting impact.

                    </Text>

                    <Text fontFamily="Unbounded" fontWeight="700" fontSize="24px"
                        color="black" lineHeight="65px"
                        mt={{ base: '8px', sm: '8px', md: '8px', lg: '10px' }}

                    >
                        Who We Are                      </Text>

                    <Text fontFamily="Poppins" fontWeight="400"
                        fontSize={{ base: '17px', sm: '17px', md: '17px', lg: '20px' }}
                        color="black" lineHeight="28px" mt="20px">
                        At Asture, we are a team of visionaries, strategists, and innovators united by a commitment to excellence. With backgrounds spanning diverse industries and areas of expertise, our team is
                        driven by a shared belief in the power of astute insights and informed action.
                    </Text>

                    <Text fontFamily="Poppins" fontWeight="400"
                        fontSize={{ base: '17px', sm: '17px', md: '17px', lg: '20px' }}
                        color="black" lineHeight="28px" mt="20px">
                        We pride ourselves on our ability to deeply understand each client’s unique challenges and to deliver solutions that are not only intelligent but transformative. As trusted partners in our clients' success, we are relentless in our pursuit of new perspectives and innovative
                        solutions, always keeping our focus on unlocking growth, efficiency, and value.
                    </Text>

                    <Text fontFamily="Poppins" fontWeight="400"
                        fontSize={{ base: '17px', sm: '17px', md: '17px', lg: '20px' }}
                        color="black" lineHeight="28px" mt="20px">
                        At Asture, we don’t just solve problems—we shape futures.
                    </Text>

                    <Text fontFamily="Unbounded" fontWeight="700" fontSize="24px"
                        color="black" lineHeight="65px"
                        mt={{ base: '8px', sm: '8px', md: '8px', lg: '67px' }}

                    >
                        Our Core Principles                     </Text>

                    <Text fontFamily="Poppins" fontWeight="500" fontSize="20px" color="black">
                        Insightfulness:  </Text>
                    <Text fontFamily="Poppins" fontWeight="400" fontSize="16px" color="black" mt="5px" >
                        We go beyond surface-level analysis to truly understand our clients’ needs and anticipate market shifts. Our approach is grounded in precision, helping us uncover key insights that drive informed decisions.
                    </Text>

                    <Text fontFamily="Poppins" fontWeight="500" fontSize="20px" color="black" pt="20px">
                        Strategic Foresight:    </Text>
                    <Text fontFamily="Poppins" fontWeight="400" fontSize="16px" color="black" mt="5px" >
                        Astute decisions are never impulsive—they are intentional, forward-thinking, and informed by data and experience. We craft solutions that not only address today’s challenges but also prepare our clients for future opportunities.
                    </Text>

                    <Text fontFamily="Poppins" fontWeight="500" fontSize="20px" color="black" pt="20px" >
                        Sophisticated Approach:    </Text>
                    <Text fontFamily="Poppins" fontWeight="400" fontSize="16px" color="black" mt="5px" >
                        At Asture Consulting, excellence is non-negotiable. Our refined methodologies and commitment to quality ensure that every project is executed with thoughtfulness, depth, and precision.
                    </Text>

                    <Text fontFamily="Poppins" fontWeight="500" fontSize="20px" color="black" pt="20px" >
                        Competitive Advantage:    </Text>
                    <Text fontFamily="Poppins" fontWeight="400" fontSize="16px" color="black" mt="5px" >
                        To be astute is to gain an edge through innovative thinking and strategic discernment. We don’t just keep pace with industry trends—we set new benchmarks by delivering solutions that elevate performance and create sustained success.
                    </Text>


                    <Text fontFamily="Unbounded" fontWeight="700" fontSize="24px"
                        color="black" lineHeight="65px"
                        mt={{ base: '8px', sm: '8px', md: '8px', lg: '10px' }}

                    >
                        Our Purpose                       </Text>

                    <Text fontFamily="Poppins" fontWeight="400"
                        fontSize={{ base: '17px', sm: '17px', md: '17px', lg: '20px' }}
                        color="black" lineHeight="28px" mt="20px">
                        Asture Consulting stands for more than knowledge and strategy—we stand for the wisdom to apply them effectively. Whether it’s solving complex problems or spearheading transformative initiatives, we are committed to being the trusted partner that helps organizations turn insight into action and achieve excellence in every endeavor.
                    </Text>

                    <Img mt="30px" src="whoweare.jpg" />






                </Box>
            </Box>


        );
    }
}

export default Whoweare;