import React, { useState } from "react";
import {
    Box,
    Button,
    Flex,
    Link,
    List,
    ListItem,
    Collapse,
    Img,
    Link as ChakraLink,
} from "@chakra-ui/react";
import { Link as RouterLink } from 'react-router-dom'; // Import RouterLink from react-router-dom

const NavBar = () => {
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [openSubMenuIndex, setOpenSubMenuIndex] = useState(null); // Track which submenu is open

    const handleMenuToggle = () => {
        setIsMenuOpen(!isMenuOpen);
    };

    const handleSubMenuToggle = (index) => {
        setOpenSubMenuIndex((prevIndex) => (prevIndex === index ? null : index)); // Toggle submenu state
    };

    return (
        <Box>
            {/* Header Section */}
            <Box position="relative" zIndex={10}>
                <Flex alignItems="center" justifyContent="space-between">
                    {/* Toggle Button */}
                    <Button
                        onClick={handleMenuToggle}
                        bg="#06BCD0"
                        color="white"
                        fontSize="sm"
                        fontWeight="bold"
                        height="50px"
                        width="50px"
                        _hover={{ bg: "#06BCD0" }}
                    >
                        <Img src="hamburgericon.svg" />
                    </Button>
                </Flex>
            </Box>

            {/* Nav Menu (absolute position) */}
            <Collapse in={isMenuOpen} animateOpacity>
                <Box
                    position="absolute"
                    top="60px"  // Adjust to your desired position below the header
                    left={0}
                    right={0}
                    bg="White"
                    boxShadow="lg"
                    zIndex={9}  // Lower than header zIndex to overlay properly
                    onClick={() => setIsMenuOpen(false)}
                >
                    <List spacing={3} p={4}>
                        <ListItem>
                            <ChakraLink
                                fontFamily="unbounded"
                                fontWeight="700"
                                fontSize={{ xl: '14px', '2xl': '18px' }}
                                color="black"
                                as={RouterLink} to="/">
                                Home
                            </ChakraLink>
                        </ListItem>

                        {/* Dropdown Menu for About Us */}
                        <ListItem>
                            <Link
                                href="#"
                                onClick={(e) => {
                                    e.stopPropagation(); // Prevent closing the main menu
                                    handleSubMenuToggle(0); // Toggle submenu for About Us
                                }}
                                fontFamily="unbounded"
                                fontWeight="700"
                                fontSize={{ xl: '14px', '2xl': '18px' }}
                                color="black"
                            >
                                About Us
                            </Link>
                            <Collapse in={openSubMenuIndex === 0} animateOpacity>
                                <List spacing={2} bg="#06BCD0" p={2}>
                                    <ListItem>
                                        <ChakraLink
                                            fontFamily="unbounded"
                                            fontWeight="500"
                                            fontSize="14px"
                                            color="white"
                                            as={RouterLink} to="whoweare">
                                            Who we are
                                        </ChakraLink>
                                    </ListItem>
                                    <ListItem>
                                        <ChakraLink
                                            fontFamily="unbounded"
                                            fontWeight="500"
                                            fontSize="14px"
                                            color="white"
                                            as={RouterLink} to="ourpartner">
                                            Our Partner
                                        </ChakraLink>
                                    </ListItem>
                                    <ListItem>
                                        <ChakraLink
                                            fontFamily="unbounded"
                                            fontWeight="500"
                                            fontSize="14px"
                                            color="white"
                                            as={RouterLink} to="Ourmisson">

                                            Our Mission
                                        </ChakraLink>
                                    </ListItem>

                                </List>
                            </Collapse>
                        </ListItem>

                        {/* Dropdown Menu for Services */}
                        <ListItem>
                            <Link
                                href="#"
                                onClick={(e) => {
                                    e.stopPropagation(); // Prevent closing the main menu
                                    handleSubMenuToggle(1); // Toggle submenu for Services
                                }}
                                fontFamily="unbounded"
                                fontWeight="700"
                                fontSize={{ xl: '14px', '2xl': '18px' }}
                                color="black"
                            >
                                Services
                            </Link>
                            <Collapse in={openSubMenuIndex === 1} animateOpacity>
                                <List spacing={2} bg="#06BCD0" p={2}>
                                    <ListItem>
                                        <ChakraLink
                                            fontFamily="unbounded"
                                            fontWeight="500"
                                            fontSize="14px"
                                            color="white"
                                            as={RouterLink} to="Asture_consult">
                                            Asture Consulting
                                        </ChakraLink>
                                    </ListItem>
                                    <ListItem>
                                        <ChakraLink
                                            fontFamily="unbounded"
                                            fontWeight="500"
                                            fontSize="14px"
                                            color="white"
                                            as={RouterLink} to="Digital_strategy">

                                            Digital Strategy
                                        </ChakraLink>
                                    </ListItem>
                                    <ListItem>
                                        <ChakraLink
                                            fontFamily="unbounded"
                                            fontWeight="500"
                                            fontSize="14px"
                                            color="white"
                                            as={RouterLink} to="Microsoft365">

                                            Microsoft 365
                                        </ChakraLink>
                                    </ListItem>
                                    <ListItem>
                                        <ChakraLink
                                            fontFamily="unbounded"
                                            fontWeight="500"
                                            fontSize="14px"
                                            color="white"
                                            as={RouterLink} to="Copilotdetail">

                                            Mircosoft Copilot
                                        </ChakraLink>
                                    </ListItem>
                                    <ListItem>
                                        <ChakraLink
                                            fontFamily="unbounded"
                                            fontWeight="500"
                                            fontSize="14px"
                                            color="white"
                                            as={RouterLink} to="Dynamicsdetail">

                                            Dynamic 365
                                        </ChakraLink>
                                    </ListItem>
                                    <ListItem>
                                        <ChakraLink
                                            fontFamily="unbounded"
                                            fontWeight="500"
                                            fontSize="14px"
                                            color="white"
                                            as={RouterLink} to="Powerdetail">

                                            Power Platform
                                        </ChakraLink>
                                    </ListItem>

                                </List>
                            </Collapse>
                        </ListItem>

                        {/* Dropdown Menu for Frameworks */}
                        <ListItem>
                            <Link
                                href="#"
                                onClick={(e) => {
                                    e.stopPropagation(); // Prevent closing the main menu
                                    handleSubMenuToggle(2); // Toggle submenu for Frameworks
                                }}
                                fontFamily="unbounded"
                                fontWeight="700"
                                fontSize={{ xl: '14px', '2xl': '18px' }}
                                color="black"
                            >
                                Frameworks
                            </Link>
                            <Collapse in={openSubMenuIndex === 2} animateOpacity>
                                <List spacing={2} bg="#06BCD0" p={2}>
                                    <ListItem>
                                        <ChakraLink
                                            fontFamily="unbounded"
                                            fontWeight="500"
                                            fontSize="14px"
                                            color="white"
                                            as={RouterLink} to="Modern_Workplace">

                                            Modern Workplace
                                        </ChakraLink>
                                    </ListItem>
                                    <ListItem>
                                        <ChakraLink
                                            fontFamily="unbounded"
                                            fontWeight="500"
                                            fontSize="14px"
                                            color="white"
                                            as={RouterLink} to="Digital_Transformation">

                                            Digital Transformation
                                        </ChakraLink>
                                    </ListItem>
                                    <ListItem>
                                        <ChakraLink
                                            fontFamily="unbounded"
                                            fontWeight="500"
                                            fontSize="14px"
                                            color="white"
                                            as={RouterLink} to="Cybersecurity">

                                            Cyber Security
                                        </ChakraLink>
                                    </ListItem>
                                    <ListItem>
                                        <ChakraLink
                                            fontFamily="unbounded"
                                            fontWeight="500"
                                            fontSize="14px"
                                            color="white"
                                            as={RouterLink} to="CloudMigration">

                                            Cloud Migration
                                        </ChakraLink>
                                    </ListItem>
                                    <ListItem>
                                        <ChakraLink
                                            fontFamily="unbounded"
                                            fontWeight="500"
                                            fontSize="14px"
                                            color="white"
                                            as={RouterLink} to="Business_Process_Automation">

                                            Business Process Automation
                                        </ChakraLink>
                                    </ListItem>
                                    <ListItem>
                                        <ChakraLink
                                            fontFamily="unbounded"
                                            fontWeight="500"
                                            fontSize="14px"
                                            color="white"
                                            as={RouterLink} to="employee_experience">

                                            Employee Experience
                                        </ChakraLink>
                                    </ListItem>
                                </List>
                            </Collapse>
                        </ListItem>

                        <ListItem>
                            <ChakraLink

                                fontFamily="unbounded"
                                fontWeight="700"
                                fontSize={{ xl: '14px', '2xl': '18px' }}
                                color="black"
                                as={RouterLink} to="Contactpage">

                                Contact
                            </ChakraLink>
                        </ListItem>
                        <ListItem>
                            <ChakraLink

                                fontFamily="unbounded"
                                fontWeight="700"
                                fontSize={{ xl: '14px', '2xl': '18px' }}
                                color="black"
                                as={RouterLink} to="Careers">

                                Careers
                            </ChakraLink>
                        </ListItem>

                    </List>
                </Box>
            </Collapse>
        </Box>
    );
};

export default NavBar;
