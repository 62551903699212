import { Box, Img,Link as ChakraLink } from "@chakra-ui/react";
import { Component } from "react";
import Hero from "../Herosection/Hero";
import Company from "../Company/Company";
import Aboutcompany from "../Aboutcompany/Aboutcompany";
import Workflow from "../Workflow/Workflow";
import Services from "../Services/Services";
import Social from "../Social/Socialsection";
import Team from "../Team/Team";
import MySlickSlider from "../Slidersection/Slidersection";
import { Link as RouterLink } from 'react-router-dom'; // Import RouterLink from react-router-dom


class Homepage extends Component {
  componentDidMount() {
    window.scrollTo(0, 0); // Scroll to the top of the page when the component mounts
  }
    state = {  } 
    render() { 
        return (
            <Box>


<ChakraLink as={RouterLink} to="/Contactpage">
<Box

    as="a" // Use this if the icon should be clickable, otherwise remove it
    position="fixed"
    bottom="100px"
    right="20px"
    zIndex="1000" // Ensure the icon appears above other elements
    bg="white" // Background color for the icon
    p="10px"
    borderRadius="50%"
    borderColor="#06BCD0"
    boxShadow="lg"
    _hover={{ bg: "black" }} // Hover effect
    padding="15px"
    borderWidth="1px"
  >
    <Img src="booking.png" h="40px" w="40px"/>
  </Box>
  </ChakraLink>


                {/* Hero Section  */}
<Box h= {{base:'450px',sm:'450px' ,md:'450px' , lg:'1080px'}}>
<Hero/>
</Box>
{/* Company Section */}
<Box px={{base:'20px',sm:'20px' ,md:'20px' , lg:'50px'}}
 py={{base:'10px',sm:'10px' ,md:'10px' , lg:'40px'}}>
<Company/>
</Box>
{/* About Company */}
<Box px={{base:'20px',sm:'20px' ,md:'20px' , lg:'50px'}}
 py={{base:'10px',sm:'10px' ,md:'10px' , lg:'60px'}}
>
<Aboutcompany/>

</Box>
{/* Work Flow */}
{/* <Box px={{base:'20px',sm:'20px' ,md:'20px' , lg:'50px'}}
 py={{base:'10px',sm:'10px' ,md:'10px' , lg:'50px'}}>
  <Workflow/>
</Box> */}
{/* Service */}
<Box px={{base:'20px',sm:'20px' ,md:'20px' , lg:'50px'}}
 py={{base:'10px',sm:'10px' ,md:'10px' , lg:'50px'}}>
<Services/>
</Box>
{/* Slider Section */}
<Box px={{base:'20px',sm:'20px' ,md:'20px' , lg:'50px'}}
 >
<MySlickSlider/>
</Box>
{/* <Box  px={{base:'20px',sm:'20px' ,md:'20px' , lg:'50px'}}
 py={{base:'10px',sm:'10px' ,md:'10px' , lg:'50px'}}>
  <Social/>
</Box> */}
{/* Our Team Section */}

            </Box>
        );
    }
}
 
export default Homepage;