import { Box, Img, Text } from "@chakra-ui/react";
import { Component } from "react";

class Section1 extends Component {
    render() {
        const {
            tesimonalimg,
            maintext,
            widthimg
        } = this.props
        return (
            <Box
                bg="#0F1414"
                borderRadius="10px"
                mx="10px"
            >
                <Box
                    bgColor="white"
                    maxW="max-content"
                    m="0 auto"
                    p="10px"
                    borderRadius="10px"
                    display="none"
                >


                    <Text fontFamily="Unbounded"
                        fontWeight={{ base: '700', sm: '700', md: '700', lg: '700' }}
                        fontSize={{ base: '13px', sm: '13px', md: '13px', lg: '30px', '2xl': '35px' }}
                        color="#06BCD0"

                    >
                        Hear from Our Satisfied Partners
                    </Text>

                </Box>


                <Box



                    h={{ base: 'none', sm: 'none', md: 'none', lg: 'none' }}
                    display="flex"
                    flexDirection={{ base: 'column', sm: 'column', md: 'column', lg: 'row' }}
                    gap="40px"
                    padding={{ base: '30px', sm: '30px', md: '20px', lg: 'none' }}
                    alignItems={{ base: 'none', sm: 'none', md: 'none', lg: 'center' }}

                >


                    <Box>

                        <Img w={widthimg} src={tesimonalimg} />

                    </Box>

                    <Box>

                        {/* <Box bgColor="white"
    width="116px"
    px={{ base: 'none', sm: 'none', md: 'none', lg: '22px' }}
    py={{ base: 'none', sm: 'none', md: 'none', lg: '7px' }}
    borderRadius="25px"
>
    <Text
        fontFamily="Poppins"
        fontSize={{ base: '14px', sm: '14px', md: '14px', lg: '12px' }}
        lineHeight={{ base: '16px', sm: '16px', md: '16px', lg: '18px' }}
        fontWeight="500"
        color="#06BCD0"
        padding={{ base: '7px', sm: '7px', md: '7px', lg: '0px' }}
        textAlign={{ base: 'center', sm: 'center', md: 'center', lg: 'none' }}
    >Testimonal
    </Text>
</Box> */}


                        <Box>

                            <Text
                                fontFamily="Unbounded"
                                fontSize={{ base: '14px', sm: '14px', md: '14px', lg: '18px', '2xl': '20px' }}
                                // lineHeight={{ base: '16px', sm: '16px', md: '16px', lg: '28px', '2xl': '48px' }}
                                fontWeight="400"
                                color="#F6F8F8"
                                mt="-10px"
                            >{maintext}
                            </Text>
                        </Box>




                        {/* <Text
pt="39px"
 fontFamily="Unbounded" 
fontSize={{base:'14px',sm:'14px',md:'14px',lg:'18px'}}
lineHeight={{base:'16px',sm:'16px',md:'16px',lg:'18px'}}
fontWeight="400" 
color="#F6F8F8"
>Daniyal Sultan</Text>
     <Text
                         pt="20px"

 fontFamily="Poppins" 
fontSize={{base:'14px',sm:'14px',md:'14px',lg:'16px'}}
lineHeight={{base:'16px',sm:'16px',md:'16px',lg:'16px'}}
fontWeight="400" 
color="#F6F8F8"
>Founder & CEO</Text> */}

                    </Box>

                </Box>





            </Box>
        );
    }
}

export default Section1;