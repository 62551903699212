import { AspectRatio, Box } from "@chakra-ui/react";
import { Component } from "react";

class Bookingsection extends Component {
    state = {  } 
    render() { 
        return (
            <Box>
              
              <AspectRatio maxW='100%'>
              <iframe 
              src='https://outlook.office365.com/owa/calendar/Asture@asture.co/bookings/' 
              width='100%' height='100%' />

</AspectRatio>

             

            </Box>
        );
    }
}
 
export default Bookingsection;