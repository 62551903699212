import { Box, Flex, Img, ListItem, Text, UnorderedList } from "@chakra-ui/react";
import { Component } from "react";
import Banner from "../ContactUspage/Banner";

class Business_Process_Automation extends Component {
  componentDidMount() {
    window.scrollTo(0, 0); // Scroll to the top of the page when the component mounts
  }
  state = {}
  render() {
    return (


      <Box>

        <Banner
          imageSrc="Business Process Automation 1.jpg"
          text="Business Process Automation"
        />

        <Box px={{ base: '20px', sm: '20px', md: '20px', lg: '50px' }}
          w={{ base: 'none', sm: 'none', md: 'none', lg: '1300px' }}
          margin="70px auto"

        >
          <Text fontFamily="Unbounded" fontWeight="700"
            fontSize={{ base: '35px', sm: '35px', md: '35px', lg: '54px' }}
            color="#06BCD0"
            lineHeight={{ base: 'none', sm: 'none', md: 'none', lg: '65px' }}
            w={{ base: 'none', sm: 'none', md: 'none', lg: '850px' }}
          >
            Business Process Automation
          </Text>
          <Text fontFamily="Poppins" fontWeight="400"
            fontSize={{ base: '17px', sm: '17px', md: '17px', lg: '20px' }}
            color="black" lineHeight="28px" mt="30px">
            Our <b>Business Process Automation (BPA) Framework</b> is designed to streamline operations, enhance productivity, and reduce manual workloads by leveraging automation technologies. Through the integration of intelligent workflows and low-code solutions, we help organizations eliminate inefficiencies, improve accuracy, and enable their workforce to focus on higher-value tasks.
          </Text>

          <Text fontFamily="Unbounded" fontWeight="700" fontSize="24px"
            color="black"
            lineHeight={{ base: '35px', sm: '35px', md: '35px', lg: '65px' }}

            mt={{ base: '8px', sm: '8px', md: '8px', lg: 'none' }}
          >
            Key Components of the Business Process Automation Framework
          </Text>

          {/* 1st Box  */}
          <Box>
            <Text fontFamily="Poppins" fontWeight="600" fontSize="22px" color="black" >
              Process Assessment and Mapping     </Text>
            <Text fontFamily="Poppins" fontWeight="500" fontSize="18px" color="black" mt="5px" >
              Identifying opportunities for automation       </Text>
            <Text fontFamily="Poppins" fontWeight="400" fontSize="18px" color="black" mt="5px" >
              The first step to successful automation is a thorough understanding of existing processes:
            </Text>
            <Box display="flex"
              flexDirection={{ base: 'column', sm: 'column', md: 'column', xl: 'row' }}

              gap="10px">
              <Text fontFamily="Poppins" fontWeight="400" fontSize="16px" color="black" mt="5px" >
                <b>1.Process Mapping:  </b>  </Text>
              <Text fontFamily="Poppins" fontWeight="400" fontSize="16px" color="black"
                mt={{ base: '-7px', sm: '-7px', md: '-7px', xl: '5px' }} >
                Comprehensive documentation of workflows, identifying dependencies and bottlenecks.
              </Text>
            </Box>
            <Box display="flex"
              flexDirection={{ base: 'column', sm: 'column', md: 'column', xl: 'row' }}

              gap="10px">
              <Text fontFamily="Poppins" fontWeight="400" fontSize="16px" color="black" mt="5px" >
                <b>2.Automation Readiness Analysis:  </b>  </Text>
              <Text fontFamily="Poppins" fontWeight="400" fontSize="16px" color="black"
                mt={{ base: '-7px', sm: '-7px', md: '-7px', xl: '5px' }} >
                Assessment of processes to determine automation potential and ROI.
              </Text>
            </Box>
            <Box display="flex"
              flexDirection={{ base: 'column', sm: 'column', md: 'column', xl: 'row' }}

              gap="10px">
              <Text fontFamily="Poppins" fontWeight="400" fontSize="16px" color="black" mt="5px" >
                <b>3.Prioritization Framework:  </b>  </Text>
              <Text fontFamily="Poppins" fontWeight="400" fontSize="16px" color="black"
                mt={{ base: '-7px', sm: '-7px', md: '-7px', xl: '5px' }} >
                Identification of high-impact processes that will benefit most from automation.
              </Text>
            </Box>
            <Text fontFamily="Poppins" fontWeight="400" fontSize="18px" color="black" mt="5px" >
              By analyzing and mapping processes, we create a clear roadmap for automation initiatives.
            </Text>
          </Box>

          {/* 2nd Box  */}
          <Box mt="20px">
            <Text fontFamily="Poppins" fontWeight="600" fontSize="22px" color="black" >
              Automation Strategy and Design    </Text>
            <Text fontFamily="Poppins" fontWeight="500" fontSize="18px" color="black" mt="5px" >
              Crafting tailored automation solutions     </Text>
            <Text fontFamily="Poppins" fontWeight="400" fontSize="18px" color="black" mt="5px" >
              We design automation solutions that align with your business objectives:
            </Text>
            <Box display="flex"
              flexDirection={{ base: 'column', sm: 'column', md: 'column', xl: 'row' }}

              gap="10px">
              <Text fontFamily="Poppins" fontWeight="400" fontSize="16px" color="black" mt="5px" >
                <b>1.Low-Code/No-Code Solutions:  </b>  </Text>
              <Text fontFamily="Poppins" fontWeight="400" fontSize="16px" color="black"
                mt={{ base: '-7px', sm: '-7px', md: '-7px', xl: '5px' }} >
                Implementation of tools such as <b>Power Automate</b> for user-friendly automation.
              </Text>
            </Box>
            <Box display="flex"
              flexDirection={{ base: 'column', sm: 'column', md: 'column', xl: 'row' }}

              gap="10px">
              <Text fontFamily="Poppins" fontWeight="400" fontSize="16px" color="black" mt="5px" >
                <b>2.Custom Automation Flows:    </b>  </Text>
              <Text fontFamily="Poppins" fontWeight="400" fontSize="16px" color="black"
                mt={{ base: '-7px', sm: '-7px', md: '-7px', xl: '5px' }} >
                Creation of complex workflows that integrate with existing systems.
              </Text>
            </Box>
            <Box display="flex"
              flexDirection={{ base: 'column', sm: 'column', md: 'column', xl: 'row' }}

              gap="10px">
              <Text fontFamily="Poppins" fontWeight="400" fontSize="16px" color="black" mt="5px" >
                <b>3.Scalable Frameworks:   </b>  </Text>
              <Text fontFamily="Poppins" fontWeight="400" fontSize="16px" color="black"
                mt={{ base: '-7px', sm: '-7px', md: '-7px', xl: '5px' }} >
                Ensuring that automation solutions can grow with your organization’s evolving needs.
              </Text>
            </Box>
            <Text fontFamily="Poppins" fontWeight="400" fontSize="18px" color="black" mt="5px" >
              This ensures that automation solutions are adaptable, efficient, and aligned with operational goals.
            </Text>
          </Box>


          {/* 3rd Box  */}
          <Box mt="20px">
            <Text fontFamily="Poppins" fontWeight="600" fontSize="22px" color="black" >
              Implementation and Integration   </Text>
            <Text fontFamily="Poppins" fontWeight="500" fontSize="18px" color="black" mt="5px" >
              Deploying automation solutions seamlessly      </Text>
            <Text fontFamily="Poppins" fontWeight="400" fontSize="18px" color="black" mt="5px" >
              Our team ensures smooth implementation of automated workflows with minimal disruption:
            </Text>
            <Box display="flex"
              flexDirection={{ base: 'column', sm: 'column', md: 'column', xl: 'row' }}

              gap="10px">
              <Text fontFamily="Poppins" fontWeight="400" fontSize="16px" color="black" mt="5px" >
                <b>1.Seamless Integration:   </b>  </Text>
              <Text fontFamily="Poppins" fontWeight="400" fontSize="16px" color="black"
                mt={{ base: '-7px', sm: '-7px', md: '-7px', xl: '5px' }} >
                Connecting automation tools with core business platforms such as ERP, CRM, and collaboration tools.
              </Text>
            </Box>
            <Box display="flex"
              flexDirection={{ base: 'column', sm: 'column', md: 'column', xl: 'row' }}

              gap="10px">
              <Text fontFamily="Poppins" fontWeight="400" fontSize="16px" color="black" mt="5px" >
                <b>2.Testing and Validation:    </b>  </Text>
              <Text fontFamily="Poppins" fontWeight="400" fontSize="16px" color="black"
                mt={{ base: '-7px', sm: '-7px', md: '-7px', xl: '5px' }} >
                Rigorous testing to ensure workflows function as expected.
              </Text>
            </Box>
            <Box display="flex"
              flexDirection={{ base: 'column', sm: 'column', md: 'column', xl: 'row' }}

              gap="10px">
              <Text fontFamily="Poppins" fontWeight="400" fontSize="16px" color="black" mt="5px" >
                <b>3.Phased Rollout:    </b>  </Text>
              <Text fontFamily="Poppins" fontWeight="400" fontSize="16px" color="black"
                mt={{ base: '-7px', sm: '-7px', md: '-7px', xl: '5px' }} >
                Gradual implementation to mitigate risks and address feedback.
              </Text>
            </Box>
            <Text fontFamily="Poppins" fontWeight="400" fontSize="18px" color="black" mt="5px" >
              We ensure that automation solutions are deployed in a way that aligns with existing infrastructure and business processes.
            </Text>
          </Box>

          {/* 4th Box  */}
          <Box mt="20px">
            <Text fontFamily="Poppins" fontWeight="600" fontSize="22px" color="black" >
              Intelligent Workflow Automation    </Text>
            <Text fontFamily="Poppins" fontWeight="500" fontSize="18px" color="black" mt="5px" >
              Enabling smarter processes through advanced technologies      </Text>
            <Text fontFamily="Poppins" fontWeight="400" fontSize="18px" color="black" mt="5px" >
              We incorporate intelligent automation tools to enhance workflows:
            </Text>
            <Box display="flex"
              flexDirection={{ base: 'column', sm: 'column', md: 'column', xl: 'row' }}

              gap="10px">
              <Text fontFamily="Poppins" fontWeight="400" fontSize="16px" color="black" mt="5px" >
                <b>1.Robotic Process Automation (RPA):   </b>  </Text>
              <Text fontFamily="Poppins" fontWeight="400" fontSize="16px" color="black"
                mt={{ base: '-7px', sm: '-7px', md: '-7px', xl: '5px' }} >
                Automating repetitive, rule-based tasks.
              </Text>
            </Box>
            <Box display="flex"
              flexDirection={{ base: 'column', sm: 'column', md: 'column', xl: 'row' }}

              gap="10px">
              <Text fontFamily="Poppins" fontWeight="400" fontSize="16px" color="black" mt="5px" >
                <b>2.AI and Machine Learning:    </b>  </Text>
              <Text fontFamily="Poppins" fontWeight="400" fontSize="16px" color="black"
                mt={{ base: '-7px', sm: '-7px', md: '-7px', xl: '5px' }} >
                Enabling predictive decision-making and intelligent data processing.
              </Text>
            </Box>
            <Box display="flex"
              flexDirection={{ base: 'column', sm: 'column', md: 'column', xl: 'row' }}

              gap="10px">
              <Text fontFamily="Poppins" fontWeight="400" fontSize="16px" color="black" mt="5px" >
                <b>3.Process Orchestration:    </b>  </Text>
              <Text fontFamily="Poppins" fontWeight="400" fontSize="16px" color="black"
                mt={{ base: '-7px', sm: '-7px', md: '-7px', xl: '5px' }} >
                Coordinating complex, cross-functional processes.
              </Text>
            </Box>
            <Text fontFamily="Poppins" fontWeight="400" fontSize="18px" color="black" mt="5px" >
              These intelligent workflows help businesses achieve greater accuracy, speed, and agility.
            </Text>
          </Box>

          {/* 5th Box  */}
          <Box mt="20px">
            <Text fontFamily="Poppins" fontWeight="600" fontSize="22px" color="black" >
              Monitoring and Optimization    </Text>
            <Text fontFamily="Poppins" fontWeight="500" fontSize="18px" color="black" mt="5px" >
              Ensuring continuous improvement      </Text>
            <Text fontFamily="Poppins" fontWeight="400" fontSize="18px" color="black" mt="5px" >
              Automation is not a one-time initiative; it requires ongoing optimization to deliver sustainable value:
            </Text>
            <Box display="flex"
              flexDirection={{ base: 'column', sm: 'column', md: 'column', xl: 'row' }}

              gap="10px">
              <Text fontFamily="Poppins" fontWeight="400" fontSize="16px" color="black" mt="5px" >
                <b>1.Performance Monitoring:    </b>  </Text>
              <Text fontFamily="Poppins" fontWeight="400" fontSize="16px" color="black"
                mt={{ base: '-7px', sm: '-7px', md: '-7px', xl: '5px' }} >
                Real-time dashboards to track workflow performance and identify improvement areas.
              </Text>
            </Box>
            <Box display="flex"
              flexDirection={{ base: 'column', sm: 'column', md: 'column', xl: 'row' }}

              gap="10px">
              <Text fontFamily="Poppins" fontWeight="400" fontSize="16px" color="black" mt="5px" >
                <b>2.Feedback Loops:     </b>  </Text>
              <Text fontFamily="Poppins" fontWeight="400" fontSize="16px" color="black"
                mt={{ base: '-7px', sm: '-7px', md: '-7px', xl: '5px' }} >
                Incorporation of user feedback to refine and enhance workflows.
              </Text>
            </Box>
            <Box display="flex"
              flexDirection={{ base: 'column', sm: 'column', md: 'column', xl: 'row' }}

              gap="10px">
              <Text fontFamily="Poppins" fontWeight="400" fontSize="16px" color="black" mt="5px" >
                <b>3.Continuous Improvement:     </b>  </Text>
              <Text fontFamily="Poppins" fontWeight="400" fontSize="16px" color="black" mt="5px" >
                Iterative updates to ensure automation remains aligned with business goals.
              </Text>
            </Box>
            <Text fontFamily="Poppins" fontWeight="400" fontSize="18px" color="black"

              mt={{ base: '-7px', sm: '-7px', md: '-7px', xl: '5px' }} >
              With robust monitoring and optimization, organizations can continually refine their processes for maximum efficiency.
            </Text>
          </Box>

          <Text fontFamily="Unbounded" fontWeight="700" fontSize="24px"
            color="black"
            lineHeight={{ base: '35px', sm: '35px', md: '35px', lg: '65px' }}

            mt={{ base: '8px', sm: '8px', md: '8px', lg: 'none' }}
          >
            Business Outcomes of the BPA Framework          </Text>

          <Box display="flex"
            flexDirection={{ base: 'column', sm: 'column', md: 'column', xl: 'row' }}

            gap="5px">
            <Text fontFamily="Poppins" fontWeight="400"
              fontSize={{ base: '16px', sm: '16px', md: '16px', lg: '17px' }}
              color="black">
              <b>Increased Operational Efficiency: </b>
            </Text>
            <Text fontFamily="Poppins" fontWeight="400"
              fontSize={{ base: '16px', sm: '16px', md: '16px', lg: '17px' }}
              color="black">
              Reduce manual effort and accelerate task completion with automated workflows.
            </Text>
          </Box>


          <Box display="flex"
            flexDirection={{ base: 'column', sm: 'column', md: 'column', xl: 'row' }}

            gap="5px">
            <Text fontFamily="Poppins" fontWeight="400"
              fontSize={{ base: '16px', sm: '16px', md: '16px', lg: '17px' }}
              color="black">
              <b>Enhanced Accuracy and Compliance:</b>
            </Text>
            <Text fontFamily="Poppins" fontWeight="400"
              fontSize={{ base: '16px', sm: '16px', md: '16px', lg: '17px' }}
              color="black">
              Minimize human errors and ensure adherence to regulatory standards.
            </Text>
          </Box>

          <Box display="flex"
            flexDirection={{ base: 'column', sm: 'column', md: 'column', xl: 'row' }}

            gap="5px">
            <Text fontFamily="Poppins" fontWeight="400"
              fontSize={{ base: '16px', sm: '16px', md: '16px', lg: '17px' }}
              color="black">
              <b>Cost Savings:</b>
            </Text>
            <Text fontFamily="Poppins" fontWeight="400"
              fontSize={{ base: '16px', sm: '16px', md: '16px', lg: '17px' }}
              color="black">
              Lower operational costs by automating repetitive, time-consuming tasks.
            </Text>
          </Box>

          <Box display="flex"
            flexDirection={{ base: 'column', sm: 'column', md: 'column', xl: 'row' }}

            gap="5px">
            <Text fontFamily="Poppins" fontWeight="400"
              fontSize={{ base: '16px', sm: '16px', md: '16px', lg: '17px' }}
              color="black">
              <b>Improved Employee Productivity: </b>
            </Text>
            <Text fontFamily="Poppins" fontWeight="400"
              fontSize={{ base: '16px', sm: '16px', md: '16px', lg: '17px' }}
              color="black">
              Free up employee time to focus on strategic, value-driven tasks.
            </Text>
          </Box>

          <Box display="flex"
            flexDirection={{ base: 'column', sm: 'column', md: 'column', xl: 'row' }}

            gap="5px">
            <Text fontFamily="Poppins" fontWeight="400"
              fontSize={{ base: '16px', sm: '16px', md: '16px', lg: '17px' }}
              color="black">
              <b>Scalability:  </b>
            </Text>
            <Text fontFamily="Poppins" fontWeight="400"
              fontSize={{ base: '16px', sm: '16px', md: '16px', lg: '17px' }}
              color="black">
              Easily scale automated processes to accommodate growth and increased demand.
            </Text>
          </Box>

          <Text fontFamily="Unbounded" fontWeight="700" fontSize="24px"
            color="black"
            lineHeight={{ base: '35px', sm: '35px', md: '35px', lg: '65px' }}

            mt={{ base: '8px', sm: '8px', md: '8px', lg: 'none' }}
          >
            Why Choose Asture Consulting?
          </Text>

          <Text fontFamily="Poppins" fontWeight="400"
            fontSize={{ base: '17px', sm: '17px', md: '17px', lg: '20px' }}
            color="black" lineHeight="28px">
            At <b>Asture Consulting,</b> we bring expertise in business process automation to help you transform your operations with minimal complexity and maximum impact. Our team works closely with you to understand your unique workflows and deliver tailored automation solutions that drive measurable results.
          </Text>

          <Text fontFamily="Poppins" fontWeight="400"
            fontSize={{ base: '17px', sm: '17px', md: '17px', lg: '20px' }}
            color="black" lineHeight="28px" mt="30px">
            We provide end-to-end support—from process discovery and solution design to implementation and ongoing optimization—ensuring that your automation journey is smooth and successful.
          </Text>

          <Text fontFamily="Poppins" fontWeight="400"
            fontSize={{ base: '17px', sm: '17px', md: '17px', lg: '20px' }}
            color="black" lineHeight="28px" mt="30px">
            <b> Next Steps:</b> Ready to unlock the power of automation? Contact <b>Asture Consulting </b>today to discover how our Business Process Automation Framework can help you achieve operational excellence and long-term growth.
          </Text>

          <Img mt="30px" src="Business Process Automation 2.png" />

        </Box>
      </Box>

    );
  }
}

export default Business_Process_Automation;