import { Box, Button, Checkbox, Divider, FormControl, FormLabel, Img, Input, Text } from "@chakra-ui/react";
import { Component } from "react";

class Contactform extends Component {
    state = {  } 
    render() { 
        return (
            <Box
            display={{base:'block',sm:'block',md:'block',lg:'flex'}}
            // flexDirection={{base:'column',sm:'column',md:'column',lg:'row'}}
            justifyContent="center"
            gap="24px"
            id="contact"
            >
                <Box bg="#F6F8F8" className="contactbox" 
                px={{base:'15px',sm:'15px',md:'15px',lg:'83px'}}
                py={{base:'15px',sm:'15px',md:'15px',lg:'100px'}}
                borderRadius="10px"
                width={{base:'none',sm:'none',md:'none',lg:'711px'}}
                >

                <Text fontFamily="Poppins
" fontWeight="500" 
fontSize={{base:'17px',sm:'17px',md:'17px',lg:'20px'}}
color="#06BCD0" 
letterSpacing={{base:'5px',sm:'5px',md:'5px',lg:'10px'}}
textTransform="uppercase"
>Contact</Text>

<Text fontFamily="Unbounded"
 fontWeight={{base:'700',sm:'700',md:'700',lg:'800'}}
fontSize={{base:'24px',sm:'24px',md:'24px',lg:'36px'}}
color="#1C2624" 
lineHeight={{base:'41px',sm:'41px',md:'41px',lg:'50px'}}
mt="20px"
>
Lets get in touch</Text>
<Box display="flex" mt="34px" 
gap={{base:'2px',sm:'2px',md:'2px',lg:'5px'}}
>
<Text fontFamily="Poppins"
 fontWeight="400"
fontSize={{base:'13px',sm:'13px',md:'13px',lg:'16px'}}
color="black" 
lineHeight={{base:'none',sm:'none',md:'none',lg:'28px'}} 

 >
You can reach us anytime via</Text>
<Text fontFamily="Poppins"
 fontWeight="400"
 fontSize={{base:'13px',sm:'13px',md:'13px',lg:'16px'}}
 color="#06BCD0" 
 lineHeight={{base:'none',sm:'none',md:'none',lg:'28px'}} 

 >info@asture.co</Text>

</Box>
<FormControl mt="72px">
<Box display="flex" 
flexDirection={{base:'column',sm:'column',md:'column',lg:'row'}}
gap="40px">
<Box>
<FormLabel>First Name</FormLabel>
  <Input type='text' placeholder="First Name"
  bg="white"
  w={{base:'100%', sm:'100%',md:'100%',lg:'253px'}}
  _focusVisible="none"

  />
 
</Box>
<Box>
<FormLabel>Last Name</FormLabel>
<Input type='text' placeholder="Last Name"
 _focusVisible="none"
 bg="white"
 w={{base:'100%', sm:'100%',md:'100%',lg:'253px'}}
 />
 
</Box>
</Box>


<Box mt="28px">
<FormLabel>Email</FormLabel>
<Input type='email' placeholder="Your Email"
 bg="white"
 _focusVisible="none"

/>
</Box>


<Box display="flex"
flexDirection={{base:'column',sm:'column',md:'column',lg:'row'}}

gap="40px" mt="28px">
<Box>
<FormLabel>Phone Number</FormLabel>
  <Input type='number' placeholder="Phone Number"
   bg="white"
   w={{base:'100%', sm:'100%',md:'100%',lg:'253px'}}
   _focusVisible="none"

  />
 
</Box>
<Box>
<FormLabel>Country</FormLabel>
<Input type='text' placeholder="Your Country" 
 colorScheme="none"
 bg="white"
 w={{base:'100%', sm:'100%',md:'100%',lg:'253px'}}
 _focusVisible="none"

/>
 
</Box>
</Box>



<Box mt="28px">
<FormLabel>Message</FormLabel>
<Input h="130px"
 type='text'
 bg="white"
 _active="none"
 _focusVisible="none"
 />
</Box>


<Box display="flex" mt="28px">

<Checkbox size="lg" mr="5px"
sx={{
  "& .chakra-checkbox__control": {
    width: "24px", // Adjust the size of the checkbox control
    height: "24px", // Adjust the size of the checkbox control
    borderColor: "#06BCD0", // Border color when unchecked
    _checked: {
      bg: "#06BCD0", // Background color when checked
      borderColor: "#06BCD0", // Border color when checked
    },
  },
  "& .chakra-checkbox__icon": {
    color: "white", // Color of the checkmark
  },
}}
></Checkbox>

<Text fontFamily="Poppins"
 fontWeight="400"
fontSize={{base:'13px',sm:'13px',md:'13px',lg:'16px'}}
color="black"
pt={{base:'3px',sm:'3px',md:'3px',lg:'none'}}

 >You agree to our </Text>
 <Text fontFamily="Poppins"
 fontWeight="500"
 fontSize={{base:'13px',sm:'13px',md:'13px',lg:'16px'}}
 color="black"
 pt={{base:'3px',sm:'3px',md:'3px',lg:'none'}}
 pl={{base:'3px',sm:'3px',md:'3px',lg:'none'}}

 >terms and conditions.</Text>
</Box>



<Button
            mt="28px"
            
            type='submit'
            bg="#06BCD0"
            w={{base:'100%', sm:'100%',md:'100%',lg:'545px'}}
            h="40px"
            colorScheme="none"
            fontSize={{base:'14px',sm:'14px',md:'14px',lg:'16px'}}
            fontFamily="Poppins"
            fontWeight="500"
          >
            Get Started
          </Button>

</FormControl>




                </Box>


                {/* <Box bg="#F6F8F8" className="statsbox"
                px="104px"
                py="150px"
                width="400px"
                display={{base:'none',sm:'none',md:'none',lg:'flex'}}
                flexDirection="column"
                gap="15px"

                >
                
                <Box className="1st">
                <Box display="flex" alignItems="center" gap="10px">
                <Text fontFamily="Unbounded
" fontWeight="700" fontSize="24px" color="black" 
>22+</Text>
<Text fontFamily="Poppins"
 fontWeight={{base:'700',sm:'700',md:'700',lg:'400'}}
fontSize={{base:'36px',sm:'36px',md:'36px',lg:'20px'}}
color="black" 
>
Years</Text>
                </Box>
                <Text fontFamily="Poppins"
 fontWeight={{base:'700',sm:'700',md:'700',lg:'500'}}
fontSize={{base:'36px',sm:'36px',md:'36px',lg:'18px'}}
color="#06BCD0" 
>Field Experience</Text>
                </Box>
                <Divider borderColor="#1C1C1C33" py="15px" />

                <Box className="2nd">
                <Box display="flex" alignItems="center" gap="10px">
                <Text fontFamily="Unbounded
" fontWeight="700" fontSize="24px" color="black" 
>950+</Text>
<Text fontFamily="Poppins"
 fontWeight={{base:'700',sm:'700',md:'700',lg:'400'}}
fontSize={{base:'36px',sm:'36px',md:'36px',lg:'20px'}}
color="black" 
>
Projects</Text>
                </Box>
                <Text fontFamily="Poppins"
 fontWeight={{base:'700',sm:'700',md:'700',lg:'500'}}
 fontSize={{base:'36px',sm:'36px',md:'36px',lg:'18px'}}
color="#06BCD0" 
>Done Around World</Text>
                </Box>
                <Divider borderColor="#1C1C1C33" py="15px" />

                <Box className="3rd">
                <Box display="flex" alignItems="center" gap="10px">
                <Text fontFamily="Unbounded
" fontWeight="700" fontSize="24px" color="black" 
>99%</Text>

                </Box>
                <Text fontFamily="Poppins"
 fontWeight={{base:'700',sm:'700',md:'700',lg:'500'}}
 fontSize={{base:'36px',sm:'36px',md:'36px',lg:'18px'}}
color="#06BCD0" 
>Client Satisfaction</Text>
                </Box>

                <Divider borderColor="#1C1C1C33" py="15px" />

                <Box className="4th">
                <Box display="flex" alignItems="center" gap="10px">
                <Text fontFamily="Unbounded
" fontWeight="700" fontSize="24px" color="black" 
>1995+</Text>
<Text fontFamily="Yantramanav"
 fontWeight={{base:'700',sm:'700',md:'700',lg:'400'}}
fontSize={{base:'36px',sm:'36px',md:'36px',lg:'20px'}}
color="black" 
>
Year</Text>
                </Box>
                <Text fontFamily="Poppins"
 fontWeight={{base:'700',sm:'700',md:'700',lg:'500'}}
 fontSize={{base:'36px',sm:'36px',md:'36px',lg:'18px'}}
color="#06BCD0" 
>Established On</Text>
                </Box>

                <Divider borderColor="#1C1C1C33" py="15px" />


                <Box className="5th">
                <Box display="flex" alignItems="center" gap="10px">
                <Text fontFamily="Unbounded
" fontWeight="700" fontSize="24px" color="black" 
>2</Text>
<Text fontFamily="Poppins"
 fontWeight={{base:'700',sm:'700',md:'700',lg:'400'}}
fontSize={{base:'36px',sm:'36px',md:'36px',lg:'20px'}}
color="black" 
>
Mins</Text>
                </Box>
                <Text fontFamily="Poppins"
 fontWeight={{base:'700',sm:'700',md:'700',lg:'500'}}
 fontSize={{base:'36px',sm:'36px',md:'36px',lg:'18px'}}
color="#06BCD0" 
>Response Time</Text>
                </Box>

                </Box> */}


                {/* <Box bg="#F6F8F8" className="contactinfo"
                   px="60px"
                   py="160px"
                   width="400px"
                   display={{base:'none',sm:'none',md:'none',lg:'flex'}}
                   flexDirection="column"
                gap="20px"             
                  >

                <Box className="1st" display="flex"
                flexDirection="column"
                gap="20px"
                >
                <Img src="support.svg" h="50px" w="50px"/>
<Text fontFamily="Poppins"
 fontWeight={{base:'700',sm:'700',md:'700',lg:'700'}}
fontSize={{base:'36px',sm:'36px',md:'36px',lg:'20px'}}
color="black" 
>
Contact Info</Text>
                <Text fontFamily="Poppins"
 fontWeight={{base:'700',sm:'700',md:'700',lg:'400'}}
fontSize={{base:'36px',sm:'36px',md:'36px',lg:'16px'}}
color="black" 
>+92 319 6780744<br/>
info@asture.co</Text>
                </Box>

                <Divider borderColor="#1C1C1C33" py="20px" /> */}


                {/* <Box className="2nd" display="flex"
                flexDirection="column"
                gap="20px">
                <Img src="map.svg" w="50px" h="50px" />
<Text fontFamily="Poppins"
 fontWeight={{base:'700',sm:'700',md:'700',lg:'700'}}
fontSize={{base:'36px',sm:'36px',md:'36px',lg:'20px'}}
color="black" 
>
Visit our office</Text>
                <Text fontFamily="Poppins"
 fontWeight={{base:'700',sm:'700',md:'700',lg:'400'}}
fontSize={{base:'36px',sm:'36px',md:'36px',lg:'16px'}}
color="black" 
>65-Z Block, Phase 3, DHA,<br/> Lahore, Lahore,<br/>Pakistan, Punjab 54810, PK</Text>
                </Box>


                <Divider borderColor="#1C1C1C33" py="20px" /> */}


                {/* <Box className="3rd" display="flex"
                flexDirection="column"
                gap="20px">

                    <Box display="flex" alignItems="center" gap="10px">
                    <Img src="facebook.svg"/>
                    <Text fontFamily="Yantramanav"
 fontWeight={{base:'700',sm:'700',md:'700',lg:'400'}}
fontSize={{base:'36px',sm:'36px',md:'36px',lg:'18px'}}
color="black" 
>Facebook</Text>
                    </Box>


                    <Box display="flex" alignItems="center" gap="10px">
                    <Img src="instagram.svg"/>
                    <Text fontFamily="Yantramanav"
 fontWeight={{base:'700',sm:'700',md:'700',lg:'400'}}
fontSize={{base:'36px',sm:'36px',md:'36px',lg:'18px'}}
color="black" 
>Instagram</Text>
                    </Box>

                    <Box display="flex" alignItems="center" gap="10px">
                    <Img src="linkedin.svg"/>
                    <Text fontFamily="Yantramanav"
 fontWeight={{base:'700',sm:'700',md:'700',lg:'400'}}
fontSize={{base:'36px',sm:'36px',md:'36px',lg:'18px'}}
color="black" 
>linkedin</Text>
                    </Box>


                </Box> */}

                {/* </Box> */}
                
            </Box>
        );
    }
}
 
export default Contactform;