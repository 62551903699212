import { Box, Button, Divider, FormControl, Img, Input, Link, Stack, Text } from "@chakra-ui/react";
import { Component } from "react";
import { Link as RouterLink } from 'react-router-dom'; // Import RouterLink from react-router-dom


class Foot extends Component {
     render() {
          return (
               <Box >

                    <Box className="uprfooter" display="flex"
                         flexDirection={{ base: 'column', sm: 'column', md: 'column', lg: 'row' }}
                         justifyContent="space-between"
                         alignItems="center">

                         <Box className="1st">
                              <Img h="130px" w="178" src="logo.svg" />


                         </Box>


                         <Box>

                              {/* <Text fontFamily="unbounded
" fontWeight="700" fontSize="24px" color="#06BCD0"
                                   pt={{ base: '20px', sm: '20px', md: '20px', lg: 'none' }}
                                   textAlign="center"
                              >Strategic Vision, Smart Execution</Text> */}


                              {/* <Text fontFamily="Poppins
" fontWeight="400" fontSize="17px" color="#F6F8F8"
                                   w={{ base: 'none', sm: 'none', md: 'none', lg: '340px' }}
                                   pt="49px"
                                   pb="20px"
                              >We provide the expertise and support to
                                   propel your business forward.</Text>
                              <FormControl display="flex" flexDirection={{ base: 'row', sm: 'row', md: 'row', lg: 'row' }}
                                   gap="10px" >

                                   <Input borderColor="#577570" type='email' placeholder="Enter Your Email" color="#799A95"
                                        colorScheme="none"
                                        w={{ base: '220px', sm: '220px', md: '220px', lg: '260px' }}
                                        h="46px"
                                        outline="none" _highlighted="none" _focusVisible="none"
                                   />
                                   <Button type='submit' bgColor="#06BCD0" fontFamily="Poppins"
                                        fontSize={{ base: '15px', sm: '15px', md: '15px', lg: '16px' }}
                                        fontWeight="400"
                                        w={{ base: '120px', sm: '120px', md: '120px', lg: '146px' }}
                                        h="46px" colorScheme="none" color="#1C2624"
                                   >
                                        Get Started
                                   </Button>
                              </FormControl> */}

                         </Box>

                         <Box className="2ndbox" maxW="100%" pt={{ base: '20px', sm: '20px', md: '20px', lg: '0px' }} >
                              <Text fontFamily="Unbounded
" fontWeight="700" fontSize="24px" color="#F6F8F8"

                              >Let’s get started on something great</Text>
                              <Text fontFamily="Poppins
" fontWeight="400" fontSize="17px" color="#F6F8F8"
                                   w="490px" maxW="100%" pt="20px" pb="35px"
                              >Our team of IT experts looks forward to meeting with you
                                   and providing valuable insights tailored to your business.</Text>

                              <Link as={RouterLink} to="/Contactpage"
                              >
                                   <Button bgColor="#06BCD0" fontFamily="Poppins" fontSize="16px"
                                        fontWeight="400" w="236px" maxW="100%" h="46px" colorScheme="none" color="#1C2624"
                                   >Get an appointment now</Button>
                              </Link>


                         </Box>



                    </Box>

                    <Divider borderColor="#1C2624" mt="30px" mb="30px" colorScheme="none" />


                    <Box className="middlefooter" display="flex" justifyContent="space-between">

                         <Box
                              display="flex"
                              flexDirection={{ base: 'column', sm: 'column', md: 'column', lg: 'row' }}
                              justifyContent="space-between"
                              w={{ base: 'none', sm: 'none', md: 'none', lg: '1920px' }}
                         >

                              <Box className="servicesbox"
                                   display="flex"
                                   flexDirection="column"
                                   gap="8px"
                              >
                                   <Text fontFamily="Poppins"
                                        fontWeight="500"
                                        fontSize="24px"
                                        color="#06BCD0"
                                   >Services</Text>
                                   <Text fontFamily="Poppins
" fontWeight="400"
                                        fontSize={{ lg: '14px', '2xl': '17px' }}
                                        color="#F6F8F8"
                                        as={RouterLink} to="/Asture_consult"
                                   >Asture Consult</Text>
                                   <Text fontFamily="Poppins
" fontWeight="400"
                                        fontSize={{ lg: '14px', '2xl': '17px' }}
                                        color="#F6F8F8"
                                        as={RouterLink} to="/Digital_strategy"
                                   >Digital Strategy </Text>


                                   <Text fontFamily="Poppins
" fontWeight="400"
                                        fontSize={{ lg: '14px', '2xl': '17px' }}
                                        color="#F6F8F8"
                                        as={RouterLink} to="/Copilotdetail"
                                   >Microsoft Copilot</Text>

                                   <Text fontFamily="Poppins
" fontWeight="400"
                                        fontSize={{ lg: '14px', '2xl': '17px' }}
                                        color="#F6F8F8"
                                        as={RouterLink} to="/Powerdetail"
                                   >Power Platform</Text>
                                   <Text fontFamily="Poppins
" fontWeight="400"
                                        fontSize={{ lg: '14px', '2xl': '17px' }}
                                        color="#F6F8F8"
                                        as={RouterLink} to="/Dynamicsdetail"
                                   >Dynamic 365 </Text>
                                   <Text fontFamily="Poppins
" fontWeight="400"
                                        fontSize={{ lg: '14px', '2xl': '17px' }}
                                        color="#F6F8F8"
                                        as={RouterLink} to="/Microsoft365"
                                   >Microsoft 365</Text>
                              </Box>

                              <Box className="framework"
                                   display="flex"
                                   flexDirection="column"
                                   gap="8px"
                                   pt={{ base: '16px', sm: '16px', md: '16px', lg: '0px' }}
                              >
                                   <Text fontFamily="Poppins"
                                        fontWeight="500"
                                        fontSize="24px"
                                        color="#06BCD0"
                                   >Frameworks</Text>
                                   <Text fontFamily="Poppins
" fontWeight="400"
                                        fontSize={{ lg: '14px', '2xl': '17px' }}
                                        color="#F6F8F8"
                                        as={RouterLink} to="/Modern_Workplace"
                                   >Modern Workplace </Text>
                                   <Text fontFamily="Poppins
" fontWeight="400"
                                        fontSize={{ lg: '14px', '2xl': '17px' }}
                                        color="#F6F8F8"
                                        as={RouterLink} to="/Digital_Transformation"
                                   >Digital Transformation </Text>
                                   <Text fontFamily="Poppins
" fontWeight="400"
                                        fontSize={{ lg: '14px', '2xl': '17px' }}
                                        color="#F6F8F8"
                                        as={RouterLink} to="/Cybersecurity"
                                   >Cyber Security </Text>


                                   <Text fontFamily="Poppins
" fontWeight="400"
                                        fontSize={{ lg: '14px', '2xl': '17px' }}
                                        color="#F6F8F8"
                                        as={RouterLink} to="/CloudMigration"
                                   >Cloud Migration </Text>
                                   <Text fontFamily="Poppins
" fontWeight="400"
                                        fontSize={{ lg: '14px', '2xl': '17px' }}
                                        color="#F6F8F8"
                                        as={RouterLink} to="/Business_Process_Automation"
                                   >Business Process Automation </Text>

                                   <Text fontFamily="Poppins
" fontWeight="400"
                                        fontSize={{ lg: '14px', '2xl': '17px' }}
                                        color="#F6F8F8"
                                        as={RouterLink} to="/employee_experience"
                                   >Employee Experience
                                   </Text>
                              </Box>

                              <Box className="Comapny"
                                   display="flex"
                                   flexDirection="column"
                                   gap="8px"
                                   pt={{ base: '16px', sm: '16px', md: '16px', lg: '0px' }}
                              >
                                   <Text fontFamily="Poppins"
                                        fontWeight="500"
                                        fontSize="24px"
                                        color="#06BCD0"
                                   >Company</Text>
                                   <Text fontFamily="Poppins
" fontWeight="400"
                                        fontSize={{ lg: '14px', '2xl': '17px' }}
                                        color="#F6F8F8"
                                        as={RouterLink} to="/whoweare"
                                   >Who we are</Text>
                                   <Text fontFamily="Poppins
" fontWeight="400"
                                        fontSize={{ lg: '14px', '2xl': '17px' }}
                                        color="#F6F8F8"
                                        as={RouterLink} to="/ourpartner"
                                   >Our Partner</Text>
                                   <Text fontFamily="Poppins
" fontWeight="400"
                                        fontSize={{ lg: '14px', '2xl': '17px' }}
                                        color="#F6F8F8"
                                        as={RouterLink} to="/Ourmisson"
                                   >Our Misson</Text>
                                   <Text fontFamily="Poppins
" fontWeight="400"
                                        fontSize={{ lg: '14px', '2xl': '17px' }}
                                        color="#F6F8F8"
                                        as={RouterLink} to="/Contactpage"
                                   >Contact</Text>

                                   <Text fontFamily="Poppins
" fontWeight="400"
                                        fontSize={{ lg: '14px', '2xl': '17px' }}
                                        color="#F6F8F8"
                                        as={RouterLink} to="/Careers"
                                   >Careers</Text>
                                   <Text fontFamily="Poppins
" fontWeight="400"
                                        fontSize={{ lg: '14px', '2xl': '17px' }}
                                        color="#F6F8F8"
                                        as={RouterLink} to="/privacypolicy"
                                   >Privacy Policy</Text>
                              </Box>

                              {/* <Box className="Phone"
                                   pt={{ base: '16px', sm: '16px', md: '16px', lg: '0px' }}

                              >
                                   <Text
                                        fontFamily="Poppins"
                                        fontWeight="500"
                                        fontSize="24px"
                                        color="#06BCD0"

                                   >Phone </Text>
                                   <Text
                                        fontFamily="Poppins
 " fontWeight="400"
                                        fontSize={{ lg: '14px', '2xl': '17px' }}
                                        color="#F6F8F8"
                                        pt="10px"

                                   >
                                        +92 319 6780744
                                   </Text>
                              </Box> */}

                              <Box className="Email"
                                   pt={{ base: '16px', sm: '16px', md: '16px', lg: '0px' }}

                              >
                                   <Text
                                        ontFamily="Poppins"
                                        fontWeight="500"
                                        fontSize="24px"
                                        color="#06BCD0"

                                   >Mail</Text>
                                   <Text
                                        fontFamily="Poppins
 " fontWeight="400"
                                        fontSize={{ lg: '14px', '2xl': '17px' }}
                                        color="#F6F8F8"
                                        pt="10px"

                                   >
                                        post@asture.co
                                   </Text>
                              </Box>


                         </Box>


                    </Box>

                    <Divider borderColor="#1C2624" mt="30px" mb="30px" colorScheme="none" />

                    <Box display="flex"
                         flexDirection="column"
                         alignItems="center"
                         justifyContent={{ base: 'center', sm: 'center', md: 'center', lg: 'center' }}>

                         <Box
                              display="flex"
                              gap="5px"
                         >
                              <Img
                                   h={{ base: '30px', sm: '30px', md: '30px', lg: '40px' }}
                                   w={{ base: '30px', sm: '30px', md: '30px', lg: '40px' }}
                                   src="foot1.svg" />
                              <Img
                                   h={{ base: '30px', sm: '30px', md: '30px', lg: '40px' }}
                                   w={{ base: '30px', sm: '30px', md: '30px', lg: '40px' }}
                                   src="foot2.svg" />
                              <Img
                                   h={{ base: '30px', sm: '30px', md: '30px', lg: '40px' }}
                                   w={{ base: '30px', sm: '30px', md: '30px', lg: '40px' }}
                                   src="foot3.svg" />
                              <Img
                                   h={{ base: '30px', sm: '30px', md: '30px', lg: '40px' }}
                                   w={{ base: '30px', sm: '30px', md: '30px', lg: '40px' }}
                                   src="foot4.svg" />
                              <Img
                                   h={{ base: '30px', sm: '30px', md: '30px', lg: '40px' }}
                                   w={{ base: '30px', sm: '30px', md: '30px', lg: '40px' }}
                                   src="foot5.svg" />
                              <Img
                                   h={{ base: '30px', sm: '30px', md: '30px', lg: '40px' }}
                                   w={{ base: '30px', sm: '30px', md: '30px', lg: '40px' }}
                                   src="foot6.svg" />
                              <Img
                                   h={{ base: '30px', sm: '30px', md: '30px', lg: '40px' }}
                                   w={{ base: '30px', sm: '30px', md: '30px', lg: '40px' }}
                                   src="foot7.svg" />
                              <Img
                                   h={{ base: '30px', sm: '30px', md: '30px', lg: '40px' }}
                                   w={{ base: '30px', sm: '30px', md: '30px', lg: '40px' }}
                                   src="foot8.svg" />
                              <Img
                                   h={{ base: '30px', sm: '30px', md: '30px', lg: '40px' }}
                                   w={{ base: '30px', sm: '30px', md: '30px', lg: '40px' }}
                                   src="foot9.svg" />
                              <Img
                                   h={{ base: '30px', sm: '30px', md: '30px', lg: '40px' }}
                                   w={{ base: '30px', sm: '30px', md: '30px', lg: '40px' }}
                                   src="foot10.svg" />


                         </Box>
                         <Text fontFamily="Poppins
" fontWeight="400" fontSize="17px" color="#799A95"
                              mt="15px"
                         >© 2025 All rights reserved by Asture.co</Text>
                    </Box>

               </Box>






          );
     }
}

export default Foot;