import { Box, Flex, Img, ListItem, Text, UnorderedList } from "@chakra-ui/react";
import { Component } from "react";
import Banner from "../ContactUspage/Banner";

class Copilotdetail extends Component {
  componentDidMount() {
    window.scrollTo(0, 0); // Scroll to the top of the page when the component mounts
  }
  state = {}
  render() {
    return (

      <Box>


        <Banner
          imageSrc="Mircosoft Copilot.jpg"
          text="Mircosoft Copilot

        "
        />


        <Box px={{ base: '20px', sm: '20px', md: '20px', lg: '50px' }}
          w={{ base: 'none', sm: 'none', md: 'none', lg: '1300px' }}
          margin="70px auto"

        >
          <Text fontFamily="Unbounded" fontWeight="700"
            fontSize={{ base: '35px', sm: '35px', md: '35px', lg: '54px' }}
            color="#06BCD0"
            lineHeight={{ base: 'none', sm: 'none', md: 'none', lg: '65px' }}
            w={{ base: 'none', sm: 'none', md: 'none', lg: '850px' }}

          >
            Mircosoft Copilot
          </Text>
          <Text fontFamily="Poppins" fontWeight="400"
            fontSize={{ base: '17px', sm: '17px', md: '17px', lg: '20px' }}

            color="black" lineHeight="28px" mt="30px">
            Microsoft Copilot is an AI-powered tool designed to integrate seamlessly with Microsoft 365, providing intelligent assistance to users by automating repetitive tasks, offering insights, and enhancing productivity. Asture Consulting helps businesses implement and optimize Microsoft Copilot for their specific needs, enabling employees to work smarter and more efficiently.
          </Text>



          <Text fontFamily="Unbounded" fontWeight="700"
            fontSize="24px"
            color="black" lineHeight="65px"
            mt={{ base: '8px', sm: '8px', md: '8px', lg: '67px' }}
          >
            Key Features:
          </Text>



          <Text fontFamily="Poppins" fontWeight="500" fontSize="20px" color="black">
            AI-Driven Automation:    </Text>
          <Text fontFamily="Poppins" fontWeight="400" fontSize="16px" color="black" mt="5px" >
            Automate routine tasks, such as data entry, reporting, and scheduling, allowing your team to focus<br /> on high-value activities.        </Text>

          <Text fontFamily="Poppins" fontWeight="500" fontSize="20px" color="black" pt="20px">
            Contextual Support:       </Text>
          <Text fontFamily="Poppins" fontWeight="400" fontSize="16px" color="black" mt="5px" >
            Microsoft Copilot provides in-app suggestions and automates workflows within tools like Word, Excel, and <br />  Teams, reducing manual effort.      </Text>


          <Text fontFamily="Poppins" fontWeight="500" fontSize="20px" color="black" pt="20px" >
            Tailored AI Solutions:    </Text>
          <Text fontFamily="Poppins" fontWeight="400" fontSize="16px" color="black" mt="5px" >
            Customize Copilot to fit your business processes and ensure it aligns with your operational goals.    </Text>


          <Text fontFamily="Poppins"
            fontWeight="400"
            fontSize={{ base: '17px', sm: '17px', md: '17px', lg: '20px' }}
            s color="black" lineHeight="28px" mt="20px">
            Asture Consulting helps you integrate Microsoft Copilot to enhance your business's productivity through AI. Our expert team ensures a smooth setup and ongoing optimization so that your team can make the most of this innovative technology.
          </Text>

          <Img mt="30px" src="Mircosoft Copilot.webp" />

        </Box>
      </Box>

    );
  }
}

export default Copilotdetail;