import { Box, Button, Img, Text } from "@chakra-ui/react";
import { Component } from "react";
import Banner from "../ContactUspage/Banner";
import { Link as RouterLink } from 'react-router-dom'; // Import RouterLink from react-router-dom

class OurTeam extends Component {
  componentDidMount() {
    window.scrollTo(0, 0); // Scroll to the top of the page when the component mounts
  }
  state = {}
  render() {
    return (
      <Box>
        <Banner
          imageSrc="Our Team.jpg"
          text="Our Partner"
        />

        <Box
          px={{ base: '20px', sm: '20px', md: '20px', lg: '50px' }}
          w={{ base: 'none', sm: 'none', md: 'none', lg: '1300px' }}
          margin="0px auto"
        >

          {/* Extra Content */}

          {/* <Box>


<Text fontFamily="Poppins
" fontWeight="500" fontSize="20px" color="black" letterSpacing="10px"
textTransform="uppercase" 
mt="20px"
>Who we are</Text>
<Text fontFamily="Unbounded
" fontWeight="700" 
fontSize={{xl:'45px','2xl':'54px'}}
color="#06BCD0" 
lineHeight="64px"
>
    Custom IT Solutions for Your Business</Text>
<Text fontFamily="Poppins"
 fontWeight="400" fontSize="16px" color="black" lineHeight="28px" pt="30px" 
 width={{base:'none',sm:'none',md:'none',lg:'700px','2xl':'950px'}}
 ></Text>

<Text fontFamily="Poppins"
 fontWeight="400" fontSize="16px" color="black" lineHeight="28px" pt="10px" 
 >
  At <b>Asture Consulting</b>, we believe in combining <b>Strategic Vision</b> with <b>Smart Execution</b> to deliver cutting-edge IT solutions that drive business success. As a dynamic startup with a growing international client base, we specialize in guiding organizations through the complexities of digital transformation.
 </Text>
    <Text fontFamily="Poppins"
 fontWeight="400" fontSize="16px" color="black" lineHeight="28px" pt="20px" 
 >
  Our team brings deep experience from globally recognized IT companies, excelling in Microsoft 365 implementation, cloud migration, and custom app development. Whether it’s automating business processes through Power Platform, delivering comprehensive Dynamics 365 solutions, or ensuring cybersecurity and IT infrastructure management, we are dedicated to creating secure, scalable, and future-proof environments.
 </Text>

 <Text fontFamily="Poppins"
 fontWeight="400" fontSize="16px" color="black" lineHeight="28px" pt="20px" 
 >
From managed IT services to hybrid workplace solutions, we tailor our offerings to meet the unique needs of industries across the globe. At Asture Consulting, we don’t just implement technology—we help businesses thrive by aligning IT with strategic goals, ensuring long-term success in the digital age.
  </Text>

 <Text fontFamily="Poppins"
 fontWeight="400" fontSize="16px" color="black" lineHeight="28px" pt="20px" 
 pb="20px"
 >
<b>Strategic Vision,</b> <b>Smart Execution</b> —that’s what sets us apart.


</Text>

</Box> */}


          <Box display="flex"
            alignItems="center"
            gap={{ base: 'none', sm: 'none', md: 'none', lg: '30px' }}
            flexDirection={{ base: 'column-reverse', sm: 'column-reverse', md: 'column-reverse', lg: 'row' }}
          >


            <Text fontFamily="Poppins"
              fontWeight="400"
              fontSize={{ base: '17px', sm: '17px', md: '17px', lg: '20px' }}
              color="black" lineHeight="28px"
              w={{ base: 'none', sm: 'none', md: 'none', lg: '800px' }}
            >
              At Asture Consulting, we are dedicated to empowering businesses through innovative IT consulting services that address the challenges and opportunities of today’s digital landscape. As a forward-thinking startup, Asture specializes in digital transformation, Microsoft 365 implementation, and business process automation, delivering customized IT solutions
              that streamline operations, optimize resources, and drive measurable growth
            </Text>

            <Img w="200px" h="200px" src="Astureblack.svg" />
          </Box>

          <Box display="flex"
            alignItems="center"
            gap="30px"
            mt="30px"
            flexDirection={{ base: 'column', sm: 'column', md: 'column', lg: 'row' }}

          >

            <Img w="200px" h="200px" src="webringlogo.png" />

            <Text fontFamily="Poppins"
              fontWeight="400"
              fontSize={{ base: '17px', sm: '17px', md: '17px', lg: '20px' }}
              color="black"
              lineHeight="28px"
              w={{ base: 'none', sm: 'none', md: 'none', lg: '800px' }}
            >
              Our partnership with WeBring LTD, an accomplished IT software house with a focus on development services, enhances our ability to offer comprehensive, end-to-end IT solutions. With a team of skilled developers and design experts, WeBring brings expertise in everything from software development to technical implementation. Together, we blend Asture’s strategic consulting prowess with WeBring’s development and technical capabilities, creating a powerhouse team uniquely
              equipped to handle every facet of a client’s IT needs.
            </Text>

          </Box>

          <Text fontFamily="Poppins"
            fontWeight="400"
            fontSize={{ base: '17px', sm: '17px', md: '17px', lg: '20px' }}
            color="black" lineHeight="28px"
            mt="40px"
          >
            Whether it’s managed IT services, hybrid workplace solutions, or
            fully customized development projects, our collaboration ensures seamless support from initial strategy through to technical implementation. We don’t just deliver solutions—we align technology with strategic business objectives, providing the digital foundations that empower our clients’ long-term success. Through our partnership, Asture Consulting and WeBring LTD are committed to building a future where technology is a catalyst for growth, resilience, and sustained innovation.
          </Text>
          <Box
            textAlign="center"
            py="20px"
          >

            <Button
              fontFamily="Poppins"
              fontWeight="400"
              fontSize="20px"
              color="white"
              lineHeight="24px"
              bgColor="#06BCD0"
              h="64px"
              rightIcon={<img src="icon_btn.png" alt="icon" />}
              colorScheme="none"
              as={RouterLink}
              to="https://webring.ltd"
            >
              Visit Webring
            </Button>
          </Box>

          {/* <Team/> */}
        </Box>
      </Box>
    );
  }
}

export default OurTeam;