import { Box, Text } from "@chakra-ui/react";
import { Component } from "react";
import Banner from "../ContactUspage/Banner";
import MySlickSlider from "../Slidersection/Slidersection";

class Casestudy extends Component {
    componentDidMount() {
        window.scrollTo(0, 0); // Scroll to the top of the page when the component mounts
      }
    state = {  } 
    render() { 
        return (
            <Box>
                <Banner 
        imageSrc="Case Study.jpg" 
        text="Case Study" 
      />

<Box  px={{base:'20px',sm:'20px' ,md:'20px' , lg:'50px'}}
            w={{base:'none',sm:'none' ,md:'none' , lg:'1300px'}}
            margin="50px auto">

<Text fontFamily="Poppins" fontWeight="400" fontSize="20px" color="black"  >
At <b>Asture Consulting</b>
, we believe that the best way to showcase our expertise is through the success of our clients. Our case studies highlight real-world examples of how we’ve helped businesses across industries achieve digital transformation, streamline their operations, and unlock their full potential through cutting-edge technology solutions.
                 </Text>

                 <Text fontFamily="Poppins" fontWeight="400" fontSize="20px" color="black" mt="15px"  >
                 From implementing <b>Microsoft 365</b> to driving efficiency with <b>Power Platform,</b> and optimizing 
                 operations with <b>Dynamics 365,</b> each case study offers insights into the challenges our 
                 clients faced and how we delivered tailored, innovative solutions to meet their unique needs.
                 </Text>

                 <Text fontFamily="Poppins" fontWeight="400" fontSize="20px" color="black" mt="15px"  >
                 Explore our case studies to see how <b>Strategic Vision</b> and <b>Smart Execution </b>
                 come together to drive success.                 </Text>
 </Box>




    <MySlickSlider/>
            </Box>
        );
    }
}
 
export default Casestudy;