import { Box, ListItem, Text, UnorderedList } from "@chakra-ui/react";
import { Component } from "react";

class Privacypolicy extends Component {
    componentDidMount() {
        window.scrollTo(0, 0); // Scroll to the top of the page when the component mounts
      }
    state = {  } 
    render() { 
        return (
        <Box 
        px={{base:'20px',sm:'20px' ,md:'20px' , lg:'50px'}}
            w={{base:'none',sm:'none' ,md:'none' , lg:'1300px'}}

            margin="70px auto"
        >
    <Text fontFamily="Unbounded" fontWeight="700" 
    fontSize={{base:'35px',sm:'35px' ,md:'35px' , lg:'54px'}}
                    color="#06BCD0" lineHeight="65px" 
                    w={{base:'none',sm:'none' ,md:'none' , lg:'850px'}}
                    >
                        Privacy Policy 
                 </Text>


                 <Text fontFamily="Unbounded" fontWeight="500" 
                 fontSize={{base:'20px',sm:'20px' ,md:'20px' , lg:'40px'}}

                    color="black" lineHeight="65px"
                     mt={{base:'none',sm:'none' ,md:'none' , lg:'30px'}}
                     >
Information We Collect
                 </Text>

                 <UnorderedList spacing="20px">
  <ListItem>
<Text fontFamily="Poppins" fontWeight="400" 
                 fontSize={{base:'17px',sm:'17px' ,md:'17px' , lg:'20px'}}
                 color="black">
IP address, login details, email, and password.
   </Text>

    </ListItem>
    <ListItem>
<Text fontFamily="Poppins" fontWeight="400" 
                 fontSize={{base:'17px',sm:'17px' ,md:'17px' , lg:'20px'}}
                 color="black">
Computer and connection data.
   </Text>

    </ListItem>
    <ListItem>
<Text fontFamily="Poppins" fontWeight="400" 
                 fontSize={{base:'17px',sm:'17px' ,md:'17px' , lg:'20px'}}
                 color="black">
Session information (e.g., page response times, visit duration, interactions, browsing methods).
   </Text>

    </ListItem>
    <ListItem>
<Text fontFamily="Poppins" fontWeight="400" 
                 fontSize={{base:'17px',sm:'17px' ,md:'17px' , lg:'20px'}}
                 color="black">
Personal details like name, email, password, communication history, feedback, and reviews.
   </Text>

    </ListItem>



</UnorderedList>


<Text fontFamily="Unbounded" fontWeight="500"
                 fontSize={{base:'20px',sm:'20px' ,md:'20px' , lg:'40px'}}
                 color="black" lineHeight="65px" mt="30px">
How We Collect Information
                 </Text>

                 <Text fontFamily="Poppins" fontWeight="400"
                 fontSize={{base:'17px',sm:'17px' ,md:'17px' , lg:'20px'}}
                 color="black">
                 When you make a transaction on our site, we collect personal information such as your name, address, and email. This data is used solely for the reasons specified in this policy.

</Text>


<Text fontFamily="Unbounded" fontWeight="500" 
                 fontSize={{base:'20px',sm:'20px' ,md:'20px' , lg:'40px'}}
                 color="black" lineHeight="65px" mt="30px">
Why We Collect Information
                 </Text>

                 <Text fontFamily="Poppins" fontWeight="400"
                 fontSize={{base:'17px',sm:'17px' ,md:'17px' , lg:'20px'}}
                  color="black">
                 We collect both personal and non-personal data for the following purposes:


</Text>

<UnorderedList spacing="20px" mt="20px">
  <ListItem>
<Text fontFamily="Poppins" fontWeight="400"
                 fontSize={{base:'17px',sm:'17px' ,md:'17px' , lg:'20px'}}
                 color="black">
To provide and maintain our services.
   </Text>

    </ListItem>
    <ListItem>
<Text fontFamily="Poppins"
 fontWeight="400"
 fontSize={{base:'17px',sm:'17px' ,md:'17px' , lg:'20px'}}
 color="black">
To offer customer support and technical assistance.
   </Text>

    </ListItem>
    <ListItem>
<Text fontFamily="Poppins" fontWeight="400"
                 fontSize={{base:'17px',sm:'17px' ,md:'17px' , lg:'20px'}}
                 color="black">
To send service updates and promotional messages.
   </Text>

    </ListItem>
    <ListItem>
<Text fontFamily="Poppins" fontWeight="400" 
                 fontSize={{base:'17px',sm:'17px' ,md:'17px' , lg:'20px'}}
                 color="black">
To generate aggregated statistics for improving our services.
   </Text>

    </ListItem>

    <ListItem>
<Text fontFamily="Poppins" fontWeight="400"
                 fontSize={{base:'17px',sm:'17px' ,md:'17px' , lg:'20px'}}
                 color="black">
To comply with legal and regulatory obligations.
   </Text>

    </ListItem>

</UnorderedList>


<Text fontFamily="Unbounded" fontWeight="500" 
                 fontSize={{base:'20px',sm:'20px' ,md:'20px' , lg:'40px'}}
                 color="black" lineHeight="65px" mt="30px">
Data Storage and Security
                 </Text>

                 <Text fontFamily="Poppins" fontWeight="400" 
                 fontSize={{base:'17px',sm:'17px' ,md:'17px' , lg:'20px'}}
                 color="black">
                 Our website is hosted on Wix.com, which securely stores your data on their servers protected by firewalls. Payment gateways adhere to PCI-DSS standards, ensuring your payment details are handled securely.

</Text>

<Text fontFamily="Unbounded" fontWeight="500" 
                 fontSize={{base:'20px',sm:'20px' ,md:'20px' , lg:'40px'}}
                 color="black" lineHeight="65px" mt="30px">
Communication                 </Text>

<Text fontFamily="Poppins" fontWeight="400" 
                 fontSize={{base:'17px',sm:'17px' ,md:'17px' , lg:'20px'}}
                 color="black">
We may contact you via email, phone, text, or mail regarding:


</Text>

<UnorderedList spacing="20px" mt="20px">
  <ListItem>
<Text fontFamily="Poppins" fontWeight="400"
                 fontSize={{base:'17px',sm:'17px' ,md:'17px' , lg:'20px'}}
                 color="black">
Account updates.
   </Text>

    </ListItem>
    <ListItem>
<Text fontFamily="Poppins" fontWeight="400" 
                 fontSize={{base:'17px',sm:'17px' ,md:'17px' , lg:'20px'}}
                 color="black">
Troubleshooting and dispute resolution.
   </Text>

    </ListItem>
    <ListItem>
<Text fontFamily="Poppins" fontWeight="400" 
                 fontSize={{base:'17px',sm:'17px' ,md:'17px' , lg:'20px'}}
                 color="black">
Surveys, company news, and legal matters.
   </Text>

    </ListItem>
   

</UnorderedList>

<Text fontFamily="Unbounded" fontWeight="500" 
                 fontSize={{base:'20px',sm:'20px' ,md:'20px' , lg:'40px'}}
                 color="black" lineHeight="65px" mt="30px">
Withdrawing Consent
                 </Text>

                 <Text fontFamily="Poppins" fontWeight="400" 
                 fontSize={{base:'17px',sm:'17px' ,md:'17px' , lg:'20px'}}
                 color="black">
                 You can withdraw your consent to data processing by contacting us at info@asture.co or by unsubscribing through the provided links in our emails.

</Text>
        </Box>
        );
    }
}
 
export default  Privacypolicy;