import { Box, Divider, Img, Text } from "@chakra-ui/react";
import { Component } from "react";
import { Link } from "react-router-dom";

class Contactinfo extends Component {
    state = {}
    render() {
        return (
            <Box>
                <Box bg="#F6F8F8" className="contactinfo"
                    px="60px"
                    pt="20px"
                    pb="100px"
                    width="400px"
                    display={{ base: 'block', sm: 'none', md: 'none', lg: 'flex' }}
                    flexDirection="column"
                    gap="20px"
                >

                    <Box className="1st" display="flex"
                        flexDirection="column"
                        gap="20px"
                    >
                        <Img src="support-icon.svg.png" h="50px" w="50px" />
                        <Text fontFamily="Poppins"
                            fontWeight={{ base: '700', sm: '700', md: '700', lg: '700' }}
                            fontSize={{ base: '18px', sm: '18px', md: '18px', lg: '20px' }}
                            color="black"
                        >
                            Contact Info</Text>
                        <Text fontFamily="Poppins"
                            fontWeight={{ base: '700', sm: '700', md: '700', lg: '400' }}
                            fontSize={{ base: '18px', sm: '18px', md: '18px', lg: '16px' }}
                            color="black"
                        >
                            {/* +92 319 6780744<br /> */}
                            post@asture.co</Text>
                    </Box>

                    <Divider borderColor="#1C1C1C33" py="20px" />


                    {/* <Box className="2nd" display="flex"
         flexDirection="column"
         gap="20px">
         <Img src="map.svg" w="50px" h="50px" />
<Text fontFamily="Poppins"
fontWeight={{base:'700',sm:'700',md:'700',lg:'700'}}
fontSize={{base:'36px',sm:'36px',md:'36px',lg:'20px'}}
color="black" 
>
Visit our office</Text>
         <Text fontFamily="Poppins"
fontWeight={{base:'700',sm:'700',md:'700',lg:'400'}}
fontSize={{base:'36px',sm:'36px',md:'36px',lg:'16px'}}
color="black" 
>65-Z Block, Phase 3, DHA,<br/> Lahore, Lahore,<br/>Pakistan, Punjab 54810, PK</Text>
         </Box>


         <Divider borderColor="#1C1C1C33" py="20px" /> */}


                    <Box className="3rd" display="flex"
                        flexDirection="column"
                        gap="20px"
                        mt="15px"
                    >
                        <a href="https://www.facebook.com/Asture.co">
                            <Box display="flex" alignItems="center" gap="10px">
                                <Img h="25px" w="25px" src="facebook.svg" />
                                <Text fontFamily="Yantramanav"
                                    fontWeight={{ base: '700', sm: '700', md: '700', lg: '400' }}
                                    fontSize={{ base: '18px', sm: '18px', md: '18px', lg: '18px' }}
                                    color="black"
                                >Facebook</Text>
                            </Box>
                        </a>

                        <a href="https://www.instagram.com/asture.co/">

                            <Box display="flex" alignItems="center" gap="10px">
                                <Img h="25px" w="25px" src="instagram.svg" />
                                <Text fontFamily="Yantramanav"
                                    fontWeight={{ base: '700', sm: '700', md: '700', lg: '400' }}
                                    fontSize={{ base: '18px', sm: '18px', md: '18px', lg: '18px' }}
                                    color="black"
                                >Instagram</Text>
                            </Box>
                        </a>
                        <a href="https://www.linkedin.com/company/asture-consulting/about/">
                            <Box display="flex" alignItems="center" gap="10px">
                                <Img h="25px" w="25px" src="linkedin.svg" />
                                <Text fontFamily="Yantramanav"
                                    fontWeight={{ base: '700', sm: '700', md: '700', lg: '400' }}
                                    fontSize={{ base: '18px', sm: '18px', md: '18px', lg: '18px' }}
                                    color="black"
                                >linkedin</Text>
                            </Box>
                        </a>
                        <a href="https://x.com/Asture_co">
                            <Box display="flex" alignItems="center" gap="10px">
                                <Img h="25px" w="25px" src="twitter.svg" />
                                <Text fontFamily="Yantramanav"
                                    fontWeight={{ base: '700', sm: '700', md: '700', lg: '400' }}
                                    fontSize={{ base: '18px', sm: '18px', md: '18px', lg: '18px' }}
                                    color="black"
                                >Twitter</Text>
                            </Box>
                        </a>
                        <a href="https://www.tiktok.com/@asture.co">
                            <Box display="flex" alignItems="center" gap="10px">
                                <Img h="25px" w="25px" src="tiktok.svg" />
                                <Text fontFamily="Yantramanav"
                                    fontWeight={{ base: '700', sm: '700', md: '700', lg: '400' }}
                                    fontSize={{ base: '18px', sm: '18px', md: '18px', lg: '18px' }}
                                    color="black"
                                >Tiktok</Text>
                            </Box>
                        </a>
                        <a href="https://www.youtube.com/@AstureConsulting">
                            <Box display="flex" alignItems="center" gap="10px">
                                <Img h="25px" w="25px" src="youtube.svg" />
                                <Text fontFamily="Yantramanav"
                                    fontWeight={{ base: '700', sm: '700', md: '700', lg: '400' }}
                                    fontSize={{ base: '18px', sm: '18px', md: '18px', lg: '18px' }}
                                    color="black"
                                >Youtube</Text>
                            </Box>
                        </a>
                        <a href="https://www.snapchat.com/add/asture.co">
                            <Box display="flex" alignItems="center" gap="10px">
                                <Img h="25px" w="25px" src="snapchat.svg" />
                                <Text fontFamily="Yantramanav"
                                    fontWeight={{ base: '700', sm: '700', md: '700', lg: '400' }}
                                    fontSize={{ base: '18px', sm: '18px', md: '18px', lg: '18px' }}
                                    color="black"
                                >Snapchat</Text>
                            </Box>
                        </a>

                    </Box>

                </Box>
            </Box>

        );
    }
}

export default Contactinfo;