import { Box, Text } from "@chakra-ui/react";
import { Component } from "react";
import Banner from "../ContactUspage/Banner";

class Ourmisson extends Component {
    componentDidMount() {
        window.scrollTo(0, 0); // Scroll to the top of the page when the component mounts
      }
    state = {  } 
    render() { 
        return (
            <Box>
                              <Banner 
        imageSrc="Our Misson.jpg" 
        text="Our Misson" 
      />

                <Box
                 px={{base:'20px',sm:'20px' ,md:'20px' , lg:'50px'}}
                 w={{base:'none',sm:'none' ,md:'none' , lg:'1300px'}}
                 margin="70px auto"
                >
                  <Text fontFamily="Unbounded" fontWeight="700" 
                  fontSize={{base:'35px',sm:'35px' ,md:'35px' , lg:'54px'}}
                    color="#06BCD0" 
                    lineHeight={{base:'none',sm:'none' ,md:'none' , lg:'65px'}}
                    w={{base:'none',sm:'none' ,md:'none' , lg:'850px'}}
                     >
                        Mission Statement 
                 </Text>


                 <Text fontFamily="Poppins" 
                 fontWeight="400" 
                 fontSize={{base:'17px',sm:'17px' ,md:'17px' , lg:'20px'}}
                 color="black" mt="20px">
                 At Asture Consulting, our mission is to empower businesses by delivering comprehensive and innovative IT solutions that drive digital transformation. We believe in unlocking the full potential of technology to help organizations streamline operations, enhance productivity, and achieve long-term growth. Through strategic partnerships, cutting-edge tools, and industry-leading expertise, we are dedicated to creating value for our clients by guiding them through the evolving digital landscape. Our goal is to not only meet but exceed client expectations, positioning them for success in an ever-changing global marketplace.    </Text>

                 <Text fontFamily="Unbounded" fontWeight="600" 
                 fontSize={{base:'30px',sm:'30px' ,md:'30px' , lg:'35px'}}
                    color="black" 
                    lineHeight={{base:'none',sm:'none' ,md:'none' , lg:'65px'}}
                    w={{base:'none',sm:'none' ,md:'none' , lg:'850px'}}
                    mt="20px" >
Values                  </Text>

<Text fontFamily="Unbounded" fontWeight="500" 
fontSize={{base:'20px',sm:'20px' ,md:'20px' , lg:'24px'}}
                    color="black"
                     lineHeight="65px" 
                    w={{base:'none',sm:'none' ,md:'none' , lg:'850px'}}
                    >
1. Innovation:                   

  </Text>
  <Text fontFamily="Poppins" fontWeight="400"
   fontSize={{base:'17px',sm:'17px' ,md:'17px' , lg:'20px'}}
    color="black"
     mt={{base:'none',sm:'none' ,md:'none' , lg:'10px'}}
     >
  At Asture Consulting, innovation is at the heart of everything we do. We are committed to staying ahead of technological advancements, continually pushing the boundaries to provide our clients with forward-thinking solutions that make a tangible impact. Whether it's adopting new technologies or finding unique ways to solve complex challenges, we are driven by a passion for innovation.                    </Text>


  <Text fontFamily="Unbounded" fontWeight="500"
fontSize={{base:'20px',sm:'20px' ,md:'20px' , lg:'24px'}}
color="black"
                     lineHeight="65px" 
                    w={{base:'none',sm:'none' ,md:'none' , lg:'850px'}}
                    >
                      
2. Integrity:   
  </Text>
  <Text fontFamily="Poppins" fontWeight="400" 
   fontSize={{base:'17px',sm:'17px' ,md:'17px' , lg:'20px'}}
   color="black" 
                 mt={{base:'none',sm:'none' ,md:'none' , lg:'10px'}}
                 >
  We believe that trust is the foundation of any successful partnership. Our commitment to integrity means we approach every project with honesty, transparency, and accountability. We uphold the highest ethical standards, ensuring that our clients can rely on us for accurate, unbiased advice and reliable solutions that serve their best interests.                </Text>


  <Text fontFamily="Unbounded" fontWeight="500" 
fontSize={{base:'20px',sm:'20px' ,md:'20px' , lg:'24px'}}
color="black" 
lineHeight="65px" 
                    w={{base:'none',sm:'none' ,md:'none' , lg:'850px'}}
                    >
3. Client-Centricity:   
  </Text>
  <Text fontFamily="Poppins" fontWeight="400" 
   fontSize={{base:'17px',sm:'17px' ,md:'17px' , lg:'20px'}}
   color="black" 
   mt={{base:'none',sm:'none' ,md:'none' , lg:'10px'}}
   >
  Our clients are at the core of everything we do. We take the time to understand their specific challenges, goals, and objectives, delivering tailored solutions that are designed to meet their unique needs. At Asture Consulting, client satisfaction is our top priority, and we are proud of our ability to form lasting partnerships built on mutual trust and respect.               </Text>


  <Text fontFamily="Unbounded" fontWeight="500"
fontSize={{base:'20px',sm:'20px' ,md:'20px' , lg:'24px'}}
color="black" lineHeight="65px"
                    w={{base:'none',sm:'none' ,md:'none' , lg:'850px'}}
                     >
4. Collaboration:   
  </Text>
  <Text fontFamily="Poppins" 
  fontWeight="400" 
  fontSize={{base:'17px',sm:'17px' ,md:'17px' , lg:'20px'}}
  color="black" 
  mt={{base:'none',sm:'none' ,md:'none' , lg:'10px'}}
  >
  We believe that the best results come from strong collaboration. Whether working closely with our internal teams or partnering with clients, we foster an environment of open communication, shared ideas, and collective problem-solving. Our collaborative approach ensures that we deliver solutions that are aligned with our clients' vision and contribute to their success.                </Text>


  <Text fontFamily="Unbounded" fontWeight="500" 
fontSize={{base:'20px',sm:'20px' ,md:'20px' , lg:'24px'}}
color="black"
 lineHeight="65px" 
                    w={{base:'none',sm:'none' ,md:'none' , lg:'850px'}}
                    >
5. Continuous Learning:   
  </Text>
  <Text fontFamily="Poppins"
   fontWeight="400"
   fontSize={{base:'17px',sm:'17px' ,md:'17px' , lg:'20px'}}
   color="black"
   mt={{base:'none',sm:'none' ,md:'none' , lg:'10px'}}
   >
  In the fast-paced world of technology, we understand the importance of staying current. We are dedicated to continuous learning, ensuring that our team remains on the cutting edge of the latest industry trends and best practices. By investing in professional development and education, we ensure that our clients receive the most innovative and effective solutions available. 
         </Text>


         <Text fontFamily="Unbounded" fontWeight="500" 
fontSize={{base:'20px',sm:'20px' ,md:'20px' , lg:'24px'}}
color="black" lineHeight="65px" 
                    w={{base:'none',sm:'none' ,md:'none' , lg:'850px'}}
                    >
6. Inclusivity:   
  </Text>
  <Text fontFamily="Poppins" 
  fontWeight="400" 
  fontSize={{base:'17px',sm:'17px' ,md:'17px' , lg:'20px'}}
  color="black" 
  mt={{base:'none',sm:'none' ,md:'none' , lg:'10px'}}
  >
  We are committed to building a diverse and inclusive work environment where every individual feels valued and respected. At Asture Consulting, we believe that diversity drives innovation, and we actively promote an inclusive culture that encourages new ideas, different perspectives, and collaboration across all levels of the organization.          </Text>


  <Text fontFamily="Unbounded" fontWeight="600"
fontSize={{base:'30px',sm:'30px' ,md:'30px' , lg:'35px'}}
color="black" lineHeight="65px" width="850px" mt="20px">
Unique Offerings                   </Text>

<Text fontFamily="Unbounded" 
fontWeight="500" 
fontSize={{base:'20px',sm:'20px' ,md:'20px' , lg:'24px'}}
                    color="black" lineHeight="65px"
                    w={{base:'none',sm:'none' ,md:'none' , lg:'850px'}}
                     >
1. Tailored Digital Transformation Solutions:     </Text>
  <Text fontFamily="Poppins" fontWeight="400" 
  fontSize={{base:'17px',sm:'17px' ,md:'17px' , lg:'20px'}}
  color="black"
mt={{base:'none',sm:'none' ,md:'none' , lg:'10px'}}
>
  At Asture Consulting, we recognize that no two businesses are the same. We take a personalized approach to digital transformation, developing IT strategies that are customized to each client’s unique goals, challenges, and industry demands. From full-scale Microsoft 365 implementations to AI-driven analytics, we provide end-to-end services that help businesses adapt to and thrive in the digital age. 

</Text>

<Text fontFamily="Unbounded" fontWeight="500"
fontSize={{base:'20px',sm:'20px' ,md:'20px' , lg:'24px'}}
color="black" lineHeight="65px"
                    w={{base:'none',sm:'none' ,md:'none' , lg:'850px'}}
                     >
2. Comprehensive Expertise Across Platforms:      </Text>
  <Text fontFamily="Poppins" 
  fontWeight="400"
  fontSize={{base:'17px',sm:'17px' ,md:'17px' , lg:'20px'}}
   color="black"
   mt={{base:'none',sm:'none' ,md:'none' , lg:'10px'}}
   >
  Our team is proficient in a wide range of IT platforms and tools, allowing us to offer our clients a holistic suite of services. Whether it’s leveraging Microsoft 365 to enhance productivity, using Power Platform to automate workflows, or deploying Dynamics 365 to streamline business processes, we deliver integrated solutions that maximize the value of technology investments. Additionally, our expertise in custom app development ensures that our clients have the tools they need to stay ahead of the competition. 

</Text>

<Text fontFamily="Unbounded" fontWeight="500"
fontSize={{base:'20px',sm:'20px' ,md:'20px' , lg:'24px'}}
color="black" lineHeight="65px"
                    w={{base:'none',sm:'none' ,md:'none' , lg:'850px'}}
                     >
3. Cybersecurity and Compliance:    </Text>
  <Text fontFamily="Poppins" fontWeight="400" 
  fontSize={{base:'17px',sm:'17px' ,md:'17px' , lg:'20px'}}
  color="black" 
  mt={{base:'none',sm:'none' ,md:'none' , lg:'10px'}}
  >
  In today’s digital world, security is paramount. Asture Consulting provides robust cybersecurity services that help businesses protect their data, safeguard against threats, and ensure compliance with industry regulations. From conducting risk assessments to implementing comprehensive security frameworks, we take a proactive approach to cybersecurity, giving our clients the confidence to operate securely and efficiently. 

    
</Text>

<Text fontFamily="Unbounded" fontWeight="500"
fontSize={{base:'20px',sm:'20px' ,md:'20px' , lg:'24px'}}
color="black" lineHeight="65px"
                    w={{base:'none',sm:'none' ,md:'none' , lg:'850px'}}
                     >
4. Hybrid Workplace Solutions:     </Text>
  <Text fontFamily="Poppins" fontWeight="400" 
  fontSize={{base:'17px',sm:'17px' ,md:'17px' , lg:'20px'}}
  color="black" 
  mt={{base:'none',sm:'none' ,md:'none' , lg:'10px'}}
  >
  As the modern workplace continues to evolve, we offer solutions designed to meet the demands of today’s hybrid workforces. Our hybrid workplace strategies focus on fostering collaboration, maintaining productivity, and ensuring security, whether employees are working from the office or remotely. With tools like Microsoft 365 and Teams, we create flexible work environments that keep teams connected and businesses moving forward. 
    
</Text>

<Text fontFamily="Unbounded" fontWeight="500" 
fontSize={{base:'20px',sm:'20px' ,md:'20px' , lg:'24px'}}
color="black" lineHeight="65px"
                    w={{base:'none',sm:'none' ,md:'none' , lg:'850px'}}
                     >
5. International Experience with a Local Touch:        </Text>
  <Text fontFamily="Poppins" fontWeight="400" 
  fontSize={{base:'17px',sm:'17px' ,md:'17px' , lg:'20px'}}
  color="black" 
  mt={{base:'none',sm:'none' ,md:'none' , lg:'10px'}}
  >
  Although Asture Consulting is a startup, our team brings extensive experience from globally recognized IT companies, enabling us to provide world-class solutions with a local, personalized touch. We have worked with clients across various industries and regions, and our international expertise allows us to offer tailored strategies that cater to specific business needs, ensuring that our clients receive the highest level of service and support.     
</Text>

<Text fontFamily="Unbounded" fontWeight="500" 
fontSize={{base:'20px',sm:'20px' ,md:'20px' , lg:'24px'}}
color="black" lineHeight="65px" 
                    w={{base:'none',sm:'none' ,md:'none' , lg:'850px'}}
                    >
6. Business Process Automation:    
  </Text>
  <Text fontFamily="Poppins" fontWeight="400" 
  fontSize={{base:'17px',sm:'17px' ,md:'17px' , lg:'20px'}}
  color="black"
  mt={{base:'none',sm:'none' ,md:'none' , lg:'10px'}}
   >
  Efficiency is key to success in today’s competitive business landscape. Asture Consulting specializes in automating key business processes using platforms like Power Platform and Dynamics 365. By automating repetitive tasks and optimizing workflows, we help businesses reduce costs, increase efficiency, and focus on high-value activities that drive growth. 
  </Text>


<Text fontFamily="Unbounded" fontWeight="500"
fontSize={{base:'20px',sm:'20px' ,md:'20px' , lg:'24px'}}
color="black" lineHeight="65px" 
                    w={{base:'none',sm:'none' ,md:'none' , lg:'850px'}}
                    >
7. AI Integration and Data Analytics:     </Text>
  <Text fontFamily="Poppins" fontWeight="400"
  fontSize={{base:'17px',sm:'17px' ,md:'17px' , lg:'20px'}}
  color="black" 
  mt={{base:'none',sm:'none' ,md:'none' , lg:'10px'}}
  >
  We understand the transformative power of data. Asture Consulting helps businesses harness the potential of AI and data analytics to gain actionable insights, make informed decisions, and stay ahead of market trends. Whether it’s implementing AI-driven automation tools or developing custom analytics solutions, we enable our clients to leverage their data for smarter business strategies.   </Text>




                </Box>

          


            </Box>

           
        );
    }
}
 
export default Ourmisson;