import { Box, Flex, Img, ListItem, Text, UnorderedList } from "@chakra-ui/react";
import { Component } from "react";
import Banner from "../ContactUspage/Banner";

class ModernWorkplace extends Component {
  componentDidMount() {
    window.scrollTo(0, 0); // Scroll to the top of the page when the component mounts
  }
  state = {}
  render() {
    return (

      <Box>

        <Banner
          imageSrc="Modern Workplace 1.jpg"
          text="Modern Workplace"
        />

        <Box px={{ base: '20px', sm: '20px', md: '20px', lg: '50px' }}
          w={{ base: 'none', sm: 'none', md: 'none', lg: '1300px' }}
          margin="70px auto"

        >
          <Text fontFamily="Unbounded" fontWeight="700"
            fontSize={{ base: '35px', sm: '35px', md: '35px', lg: '54px' }}
            color="#06BCD0"
            lineHeight={{ base: 'none', sm: 'none', md: 'none', lg: '65px' }}
            w={{ base: 'none', sm: 'none', md: 'none', lg: '850px' }}
          >
            Modern Workplace
          </Text>
          <Text fontFamily="Poppins" fontWeight="400"
            fontSize={{ base: '17px', sm: '17px', md: '17px', lg: '20px' }}
            color="black"
            lineHeight="28px"
            mt="30px">
            Our <b>Modern Workplace</b> framework is designed to empower businesses with a seamless, secure, and integrated
            digital work environment built on Microsoft 365. By harnessing the power of collaboration tools such as
            <b>Microsoft Teams, SharePoint,</b> and <b>OneDrive,</b> alongside comprehensive device management and security solutions
            like <b>Intune</b> and <b>Microsoft Defender,</b> we ensure that your
            workforce remains productive, secure, and adaptable in an evolving landscape.
          </Text>

          <Text fontFamily="Poppins" fontWeight="400"
            fontSize={{ base: '17px', sm: '17px', md: '17px', lg: '20px' }}
            color="black" lineHeight="28px" mt="30px">
            Our approach focuses on enabling businesses to embrace flexible work models while maintaining data integrity, compliance, and operational efficiency.
          </Text>

          <Text fontFamily="Unbounded" fontWeight="700" fontSize="24px"
            color="black"
            lineHeight={{ base: '35px', sm: '35px', md: '35px', lg: '65px' }}
            mt={{ base: '8px', sm: '8px', md: '8px', lg: 'none' }}
          >
            Key Components of the Modern Workplace Framework
          </Text>

          {/* 1st Box  */}
          <Box>
            <Text fontFamily="Poppins" fontWeight="600" fontSize="22px" color="black">
              Contextual Support  </Text>
            <Text fontFamily="Poppins" fontWeight="500" fontSize="18px" color="black" mt="5px" >
              Enabling efficient communication and collaboration      </Text>
            <Text fontFamily="Poppins" fontWeight="400" fontSize="18px" color="black" mt="5px" >
              Collaboration and information sharing are at the heart of a modern workplace. We streamline communication workflows with an
              integrated suite of Microsoft 365 collaboration tools:
            </Text>
            <Box display="flex"
              flexDirection={{ base: 'column', sm: 'column', md: 'column', xl: 'row' }}
              gap="10px">
              <Text fontFamily="Poppins" fontWeight="400" fontSize="16px" color="black" mt="5px" >
                <b>1.Microsoft Teams: </b>  </Text>
              <Text fontFamily="Poppins" fontWeight="400" fontSize="16px" color="black"
                mt={{ base: '-7px', sm: '-7px', md: '-7px', xl: '5px' }} >
                Centralized communication hub for meetings, messaging, file sharing, and app integration.
              </Text>
            </Box>
            <Box display="flex"
              flexDirection={{ base: 'column', sm: 'column', md: 'column', xl: 'row' }}
              gap="10px">
              <Text fontFamily="Poppins" fontWeight="400" fontSize="16px" color="black" mt="5px" >
                <b>2.SharePoint:  </b>  </Text>
              <Text fontFamily="Poppins" fontWeight="400" fontSize="16px" color="black"
                mt={{ base: '-7px', sm: '-7px', md: '-7px', xl: '5px' }} >
                A secure platform for document management, version control, and organizational knowledge sharing.
              </Text>
            </Box>
            <Box display="flex"
              flexDirection={{ base: 'column', sm: 'column', md: 'column', xl: 'row' }}
              gap="10px">
              <Text fontFamily="Poppins" fontWeight="400" fontSize="16px" color="black" mt="5px" >
                <b>3.OneDrive: </b>  </Text>
              <Text fontFamily="Poppins" fontWeight="400" fontSize="16px" color="black"
                mt={{ base: '-7px', sm: '-7px', md: '-7px', xl: '5px' }} >
                Secure, cloud-based file storage and synchronization for personal and team files, ensuring accessibility from any location.
              </Text>
            </Box>
            <Text fontFamily="Poppins" fontWeight="400" fontSize="18px" color="black" mt="5px" >
              Together, these tools create a unified experience that reduces information silos and fosters real-time collaboration.
            </Text>
          </Box>


          {/* 2nd Box  */}
          <Box mt="20px">
            <Text fontFamily="Poppins" fontWeight="600" fontSize="22px" color="black">
              Device Management   </Text>
            <Text fontFamily="Poppins" fontWeight="500" fontSize="18px" color="black" mt="5px" >
              Ensuring control, flexibility, and security across devices      </Text>
            <Text fontFamily="Poppins" fontWeight="400" fontSize="18px" color="black" mt="5px" >
              Our framework incorporates robust device management solutions to ensure seamless access and secure endpoint protection:
            </Text>
            <Box display="flex"
              flexDirection={{ base: 'column', sm: 'column', md: 'column', xl: 'row' }}
              gap="10px">
              <Text fontFamily="Poppins" fontWeight="400" fontSize="16px" color="black"
                mt="5px" >
                <b>1.Microsoft Intune: </b>  </Text>
              <Text fontFamily="Poppins" fontWeight="400" fontSize="16px" color="black"
                mt={{ base: '-7px', sm: '-7px', md: '-7px', xl: '5px' }}  >
                Cloud-based endpoint management for both corporate-owned and BYOD (Bring Your Own Device) environments.
              </Text>
            </Box>
            <Box display="flex"
              flexDirection={{ base: 'column', sm: 'column', md: 'column', xl: 'row' }}
              gap="10px">
              <Text fontFamily="Poppins" fontWeight="400" fontSize="16px" color="black" mt="5px" >
                <b>2.SCCM (System Center Configuration Manager):  </b>  </Text>
              <Text fontFamily="Poppins" fontWeight="400" fontSize="16px" color="black"
                mt={{ base: '-7px', sm: '-7px', md: '-7px', xl: '5px' }}  >
                Advanced on-premise device management for organizations with hybrid environments.
              </Text>
            </Box>
            <Text fontFamily="Poppins" fontWeight="400" fontSize="18px" color="black" mt="5px" >
              This combination of tools ensures that users can securely access corporate resources from any device without compromising performance or security.
            </Text>
          </Box>


          {/* 3rd Box  */}
          <Box mt="20px">
            <Text fontFamily="Poppins" fontWeight="600" fontSize="22px" color="black">
              Security   </Text>
            <Text fontFamily="Poppins" fontWeight="500" fontSize="18px" color="black" mt="5px" >
              Safeguarding your digital workplace with a zero-trust approach      </Text>
            <Text fontFamily="Poppins" fontWeight="400" fontSize="18px" color="black" mt="5px" >
              Security is foundational to a modern workplace. We implement a robust, multi-layered security framework to protect against evolving threats:
            </Text>
            <Box display="flex"
              flexDirection={{ base: 'column', sm: 'column', md: 'column', xl: 'row' }}
              gap="10px">
              <Text fontFamily="Poppins" fontWeight="400" fontSize="16px" color="black" mt="5px" >
                <b>1.Entra ID (formerly Azure Active Directory):  </b>  </Text>
              <Text fontFamily="Poppins" fontWeight="400" fontSize="16px" color="black"
                mt={{ base: '-7px', sm: '-7px', md: '-7px', xl: '5px' }}  >
                Secure identity management with multi-factor authentication (MFA) and conditional access policies.
              </Text>
            </Box>
            <Box display="flex"
              flexDirection={{ base: 'column', sm: 'column', md: 'column', xl: 'row' }}
              gap="10px">
              <Text fontFamily="Poppins" fontWeight="400" fontSize="16px" color="black" mt="5px" >
                <b>2.Microsoft Defender:  </b>  </Text>
              <Text fontFamily="Poppins" fontWeight="400" fontSize="16px" color="black"
                mt={{ base: '-7px', sm: '-7px', md: '-7px', xl: '5px' }}  >
                Comprehensive threat protection across endpoints, cloud services, and email to mitigate security risks.
              </Text>
            </Box>
            <Box display="flex"
              flexDirection={{ base: 'column', sm: 'column', md: 'column', xl: 'row' }}
              gap="10px">
              <Text fontFamily="Poppins" fontWeight="400" fontSize="16px" color="black" mt="5px" >
                <b>3.Zero Trust Model:   </b>  </Text>
              <Text fontFamily="Poppins" fontWeight="400" fontSize="16px" color="black"
                mt={{ base: '-7px', sm: '-7px', md: '-7px', xl: '5px' }}  >
                A security strategy that continuously verifies users and devices before granting access, ensuring minimal exposure to threats.
              </Text>
            </Box>
            <Text fontFamily="Poppins" fontWeight="400" fontSize="18px" color="black" mt="5px" >
              These security measures ensure that your employees can work confidently, knowing that their data and systems are protected.
            </Text>
          </Box>

          {/* 4th Box  */}
          <Box mt="20px">
            <Text fontFamily="Poppins" fontWeight="600" fontSize="22px" color="black" >
              Productivity   </Text>
            <Text fontFamily="Poppins" fontWeight="500" fontSize="18px" color="black" mt="5px" >
              Driving efficiency and innovation through powerful tools       </Text>
            <Text fontFamily="Poppins" fontWeight="400" fontSize="18px" color="black" mt="5px" >
              Our Modern Workplace framework optimizes productivity by leveraging the Microsoft 365 suite and automation tools to streamline processes:
            </Text>
            <Box display="flex"
              flexDirection={{ base: 'column', sm: 'column', md: 'column', xl: 'row' }}
              gap="10px">
              <Text fontFamily="Poppins" fontWeight="400" fontSize="16px" color="black" mt="5px" >
                <b>1.Office 365 Suite:   </b>  </Text>
              <Text fontFamily="Poppins" fontWeight="400" fontSize="16px" color="black"
                mt={{ base: '-7px', sm: '-7px', md: '-7px', xl: '5px' }} >
                Familiar tools like Word, Excel, PowerPoint, and Outlook integrated with cloud connectivity for collaborative work.
              </Text>
            </Box>
            <Box display="flex"
              flexDirection={{ base: 'column', sm: 'column', md: 'column', xl: 'row' }}
              gap="10px">
              <Text fontFamily="Poppins" fontWeight="400" fontSize="16px" color="black" mt="5px" >
                <b>2.Power Platform:  </b>  </Text>
              <Text fontFamily="Poppins" fontWeight="400" fontSize="16px" color="black"
                mt={{ base: '-7px', sm: '-7px', md: '-7px', xl: '5px' }} >
                Empowering users to create custom workflows, dashboards,
                and applications using <b>Power Automate, Power BI,</b> and <b>Power Apps.</b>
              </Text>
            </Box>
            <Text fontFamily="Poppins" fontWeight="400" fontSize="18px" color="black" mt="5px" >
              By harnessing these tools, we enable organizations to automate repetitive tasks, gain actionable insights, and build tailored solutions that drive innovation.
            </Text>
          </Box>


          <Text fontFamily="Unbounded" fontWeight="700" fontSize="24px"
            color="black"
            lineHeight={{ base: '35px', sm: '35px', md: '35px', lg: '65px' }}
            mt={{ base: '8px', sm: '8px', md: '8px', lg: 'none' }}
          >
            Business Outcomes of the Modern Workplace Framework           </Text>
          <Box display="flex"
            flexDirection={{ base: 'column', sm: 'column', md: 'column', xl: 'row' }}
            gap="5px">
            <Text fontFamily="Poppins" fontWeight="400"
              fontSize={{ base: '16px', sm: '16px', md: '16px', lg: '17px' }}
              color="black">
              <b>Enhanced Collaboration and Communication:</b>
            </Text>
            <Text fontFamily="Poppins" fontWeight="400"
              fontSize={{ base: '16px', sm: '16px', md: '16px', lg: '17px' }}
              color="black">
              Foster real-time collaboration across dispersed teams with centralized communication tools.
            </Text>
          </Box>

          <Box display="flex"
            flexDirection={{ base: 'column', sm: 'column', md: 'column', xl: 'row' }}
            gap="5px">
            <Text fontFamily="Poppins" fontWeight="400"
              fontSize={{ base: '16px', sm: '16px', md: '16px', lg: '17px' }}
              color="black">
              <b>Increased Flexibility:</b>
            </Text>
            <Text fontFamily="Poppins" fontWeight="400"
              fontSize={{ base: '16px', sm: '16px', md: '16px', lg: '17px' }}
              color="black">
              Support hybrid and remote work environments without compromising productivity or security.
            </Text>
          </Box>

          <Box display="flex"
            flexDirection={{ base: 'column', sm: 'column', md: 'column', xl: 'row' }}
            gap="5px">
            <Text fontFamily="Poppins" fontWeight="400"
              fontSize={{ base: '16px', sm: '16px', md: '16px', lg: '17px' }}
              color="black">
              <b>Improved Security:</b>
            </Text>
            <Text fontFamily="Poppins" fontWeight="400"
              fontSize={{ base: '16px', sm: '16px', md: '16px', lg: '17px' }}
              color="black">
              Protect sensitive data with enterprise-grade security measures and ensure adherence to regulatory requirements.
            </Text>
          </Box>

          <Box display="flex"
            flexDirection={{ base: 'column', sm: 'column', md: 'column', xl: 'row' }}
            gap="5px">
            <Text fontFamily="Poppins" fontWeight="400"
              fontSize={{ base: '16px', sm: '16px', md: '16px', lg: '17px' }}
              color="black">
              <b>Operational Efficiency:</b>
            </Text>
            <Text fontFamily="Poppins" fontWeight="400"
              fontSize={{ base: '16px', sm: '16px', md: '16px', lg: '17px' }}
              color="black">
              Automate routine processes and streamline workflows to reduce manual overhead.
            </Text>
          </Box>

          <Box display="flex"
            flexDirection={{ base: 'column', sm: 'column', md: 'column', xl: 'row' }}
            gap="5px">
            <Text fontFamily="Poppins" fontWeight="400"
              fontSize={{ base: '16px', sm: '16px', md: '16px', lg: '17px' }}
              color="black">
              <b>Data-Driven Decision Making: </b>
            </Text>
            <Text fontFamily="Poppins" fontWeight="400"
              fontSize={{ base: '16px', sm: '16px', md: '16px', lg: '17px' }}
              color="black">
              Leverage data insights through integrated analytics tools to inform business strategy.
            </Text>
          </Box>

          <Text fontFamily="Unbounded" fontWeight="700" fontSize="24px"
            color="black"
            lineHeight={{ base: '35px', sm: '35px', md: '35px', lg: '65px' }}
            mt={{ base: '8px', sm: '8px', md: '8px', lg: 'none' }}
          >
            Why Choose Asture Consulting?
          </Text>

          <Text fontFamily="Poppins" fontWeight="400"
            fontSize={{ base: '17px', sm: '17px', md: '17px', lg: '20px' }}
            color="black" lineHeight="28px">
            At <b>Asture Consulting,</b> we understand that every organization’s journey to a modern workplace is unique. Our expertise in Microsoft 365 solutions allows us to tailor the Modern Workplace framework to your specific needs, ensuring a seamless adoption process. We provide ongoing support, training, and strategic guidance to help your business thrive in the digital era.
          </Text>

          <Text fontFamily="Poppins" fontWeight="400"
            fontSize={{ base: '17px', sm: '17px', md: '17px', lg: '20px' }}
            color="black" lineHeight="28px" mt="30px">
            Whether you're embarking on a digital transformation journey or seeking to optimize your existing environment, our Modern Workplace framework equips you with the tools and strategies to succeed.
          </Text>

          <Text fontFamily="Poppins" fontWeight="400"
            fontSize={{ base: '17px', sm: '17px', md: '17px', lg: '20px' }}
            color="black" lineHeight="28px" mt="30px">
            <b>Next Steps:</b> Ready to modernize your workplace? Contact <b>Asture Consulting</b> today to
            learn more about how our Modern Workplace
            framework can empower your workforce and enhance your business operations.
          </Text>

          <Img mt="30px" src="Modern Workplace.png" />

        </Box>
      </Box>


    );
  }
}

export default ModernWorkplace;