import { Box, Flex, Img, ListItem, Text, UnorderedList } from "@chakra-ui/react";
import { Component } from "react";
import Banner from "../ContactUspage/Banner";

class Microsoft356 extends Component {
    componentDidMount() {
        window.scrollTo(0, 0); // Scroll to the top of the page when the component mounts
      }
    state = {  } 
    render() { 
        return (

          <Box>

<Banner 
        imageSrc="Microsoft 365.jpg" 
        text="Microsoft 365

        " 
      />


<Box px={{base:'20px',sm:'20px' ,md:'20px' , lg:'50px'}}
            w={{base:'none',sm:'none' ,md:'none' , lg:'1300px'}}
            margin="70px auto"
 
             >
                   <Text fontFamily="Unbounded" fontWeight="700" 
                   fontSize={{base:'35px',sm:'35px' ,md:'35px',lg:'54px'}}
                    color="#06BCD0" lineHeight="65px" 
                    w={{base:'none',sm:'none' ,md:'none' , lg:'850px'}}
                    >
                    Microsoft 365
                 </Text>
                 <Text fontFamily="Poppins" fontWeight="400" 
                  fontSize={{base:'17px',sm:'17px' ,md:'17px',lg:'20px'}}
                  color="black" lineHeight="28px" mt="30px">
                 Unlock the full potential of Microsoft 365 with Asture Consulting’s implementation and support services. Microsoft 365 is a suite of productivity tools designed to enhance collaboration, communication, and efficiency across your organization. At Asture Consulting, we provide end-to-end solutions, from initial deployment to ongoing management, to ensure your business leverages the power of Microsoft 365 to its fullest. 
                 </Text>
         
 
 
                 <Text fontFamily="Unbounded" fontWeight="700" fontSize="24px"
                    color="black" lineHeight="65px"
                    mt={{base:'8px',sm:'8px' ,md:'8px',lg:'67px'}}
                      >
Key Features:                  </Text>

       <Text fontFamily="Poppins" fontWeight="500" fontSize="20px" color="black">
    Seamless Implementation and Migration:   </Text>
  <Text fontFamily="Poppins" fontWeight="400" fontSize="16px" color="black"mt="5px" >
  We handle the setup, customization, and migration of your existing data and applications<br/> to Microsoft 365 with minimal disruption.   </Text>

     <Text fontFamily="Poppins" fontWeight="500" fontSize="20px" color="black" pt="20px">
  Productivity and Collaboration:     </Text>
  <Text fontFamily="Poppins" fontWeight="400" fontSize="16px" color="black" mt="5px" >
  Enable teams to collaborate seamlessly through tools like Teams, SharePoint, OneDrive, and Office apps.   
   </Text>


     <Text fontFamily="Poppins" fontWeight="500" fontSize="20px" color="black" pt="20px" >
  Security and Compliance:   </Text>
  <Text fontFamily="Poppins" fontWeight="400" fontSize="16px" color="black" mt="5px" >
  With built-in security features and compliance controls, Microsoft 365 ensures your data is protected <br/>and meets regulatory standards.       </Text>
  


 
                 <Text fontFamily="Poppins" fontWeight="400" 
                  fontSize={{base:'17px',sm:'17px' ,md:'17px',lg:'20px'}}
                  color="black" lineHeight="28px" mt="20px">
                 We specialize in helping businesses maximize the capabilities of Microsoft 365 through tailored implementations, training, and continuous support. Whether you’re migrating from legacy systems or looking to optimize your current setup, Asture Consulting ensures a smooth transition to a more collaborative and secure digital workplace. 
                 </Text>
 
                 <Img mt="30px" src="Microsoft 3651.png" />
 
             </Box>

          </Box>
          
        );
    }
}
 
export default Microsoft356;