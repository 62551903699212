import { Box, List, ListItem, Text, Textarea, UnorderedList } from "@chakra-ui/react";
import { Component } from "react";


class Business_consultant extends Component {
    state = {}
    render() {
        return (
            <Box>



                <Text fontFamily="Unbounded" fontWeight="700"
                    fontSize={{ base: '20px', sm: '20px', md: '20px', lg: '24px' }}
                    lineHeight={{ base: '35px', sm: '35px', md: '35px', lg: '65px' }}
                    color="black"
                    mt={{ base: '8px', sm: '8px', md: '8px', lg: '10px' }}

                >
                    Shape the Future of Digital Transformation with Asture Consulting!                     </Text>

                <Text fontFamily="Poppins" fontWeight="400"
                    fontSize={{ base: '17px', sm: '17px', md: '17px', lg: '20px' }}
                    color="black" lineHeight="28px">
                    Are you an experienced business professional with a passion for helping organizations navigate their digital transformation journey? Do you have the expertise to provide innovative solutions, deliver actionable strategies, and drive measurable outcomes? If so, Asture Consulting is seeking a Business Consultant to join our
                    team and work on cutting-edge projects that define the future of business operations.
                </Text>

                <Text fontFamily="Unbounded" fontWeight="700"
                    fontSize={{ base: '20px', sm: '20px', md: '20px', lg: '24px' }}
                    lineHeight={{ base: '35px', sm: '35px', md: '35px', lg: '65px' }}
                    color="black" l

                    mt={{ base: '8px', sm: '8px', md: '8px', lg: '10px' }}

                >
                    About Asture Consulting                    </Text>


                <Text fontFamily="Poppins" fontWeight="400"
                    fontSize={{ base: '17px', sm: '17px', md: '17px', lg: '20px' }}
                    color="black" lineHeight="28px">
                    At Asture Consulting, we specialize in empowering organizations with cutting-edge
                    business solutions. We partner with diverse clients across industries to deliver transformative strategies that fuel growth, improve operations, and unlock long-term success.
                </Text>


                <Text fontFamily="Poppins" fontWeight="400"
                    fontSize={{ base: '17px', sm: '17px', md: '17px', lg: '20px' }}
                    color="black" lineHeight="28px">
                    As a Business Consultant, you will collaborate with industry leaders, supporting clients across the following core areas:
                </Text>

                {/* Bullets Start */}

                <Text fontFamily="Poppins" fontWeight="400"
                    fontSize={{ base: '15px', sm: '15px', md: '15px', lg: '18px' }}
                    color="black" lineHeight="28px" mt="10px">
                    <b>Consultancy as a Service (CaaS):</b> Provide on-demand IT consulting for digital transformation,
                    technology adoption, and operational optimization.
                </Text>

                <Text fontFamily="Poppins" fontWeight="400"
                    fontSize={{ base: '15px', sm: '15px', md: '15px', lg: '18px' }}
                    color="black" lineHeight="28px" mt="10px">
                    <b>Digital Strategy:</b> Design and implement forward-thinking digital strategies that align with clients’ long-term objectives.
                </Text>

                <Text fontFamily="Poppins" fontWeight="400"
                    fontSize={{ base: '15px', sm: '15px', md: '15px', lg: '18px' }}
                    color="black" lineHeight="28px" mt="10px">
                    <b>Microsoft Ecosystem Solutions: </b> Support implementation and optimization of tools such as Microsoft 365, Microsoft Copilot, Dynamics 365, and
                    Power Platform to enhance productivity, collaboration, and business efficiency.
                </Text>

                {/* Bullets End */}


                <Text fontFamily="Unbounded" fontWeight="700"
                    fontSize={{ base: '20px', sm: '20px', md: '20px', lg: '24px' }}
                    lineHeight={{ base: '35px', sm: '35px', md: '35px', lg: '65px' }}
                    color="black"
                    mt={{ base: '8px', sm: '8px', md: '8px', lg: '10px' }}

                >
                    Role Overview  </Text>

                <Text fontFamily="Poppins" fontWeight="400"
                    fontSize={{ base: '17px', sm: '17px', md: '17px', lg: '20px' }}
                    color="black" lineHeight="28px">
                    As a <b>Business Consultant,</b> you will leverage your expertise to help clients address critical
                    challenges and seize opportunities for improvement. You will lead the way in delivering
                    actionable recommendations and implementing impactful solutions that drive measurable results.
                </Text>

                <Text fontFamily="Unbounded" fontWeight="700"
                    fontSize={{ base: '20px', sm: '20px', md: '20px', lg: '24px' }}
                    lineHeight={{ base: '35px', sm: '35px', md: '35px', lg: '65px' }}
                    color="black"
                    mt={{ base: '8px', sm: '8px', md: '8px', lg: '10px' }}

                >
                    Key Responsibilities</Text>

                <UnorderedList
                    fontFamily="Poppins" fontWeight="500"
                    fontSize={{ base: '15px', sm: '15px', md: '15px', lg: '18px' }}
                    color="black" lineHeight="28px"
                >
                    <ListItem>Developing tailored strategies that leverage tools like Microsoft 365, Dynamics 365, and Power Platform to drive client success.</ListItem>
                    <ListItem>Collaborating with clients to identify business challenges and opportunities, with a focus on digital transformation and operational efficiency.</ListItem>
                    <ListItem>Leading projects to implement IT solutions, ensuring alignment with clients’ strategic goals.</ListItem>
                    <ListItem>Analyze business processes, financial data, and market trends to identify areas of improvement.</ListItem>
                    <ListItem>Monitoring project outcomes, optimizing strategies, and adapting to evolving client requirements.</ListItem>
                    <ListItem>Build strong client relationships, serving as a trusted advisor for their business needs.</ListItem>
                    <ListItem> Stay ahead of industry trends to provide clients with innovative, forward-thinking solutions. </ListItem>
                </UnorderedList>

                <Text fontFamily="Unbounded" fontWeight="700"
                    fontSize={{ base: '20px', sm: '20px', md: '20px', lg: '24px' }}
                    lineHeight={{ base: '35px', sm: '35px', md: '35px', lg: '65px' }}
                    color="black"
                    mt={{ base: '8px', sm: '8px', md: '8px', lg: '10px' }}

                >
                    What We’re Looking For </Text>

                {/* Bullets Start */}

                <Text fontFamily="Poppins" fontWeight="400"
                    fontSize={{ base: '15px', sm: '15px', md: '15px', lg: '18px' }}
                    color="black" lineHeight="28px" mt="10px">
                    <b>Educational Background:</b> Bachelor’s degree in Business, IT, or a related field (MBA or equivalent advanced degree strongly preferred).
                </Text>

                <Text fontFamily="Poppins" fontWeight="400"
                    fontSize={{ base: '15px', sm: '15px', md: '15px', lg: '18px' }}
                    color="black" lineHeight="28px" mt="10px">
                    <b>Experience:</b> A minimum of 5 years in business consulting, IT consulting, or a related role, with a proven track record of success.
                </Text>

                <Text fontFamily="Poppins" fontWeight="400"
                    fontSize={{ base: '15px', sm: '15px', md: '15px', lg: '18px' }}
                    color="black" lineHeight="28px" mt="10px">
                    <b>Skills: </b> <br></br>
                    <UnorderedList
                        fontFamily="Poppins" fontWeight="400"
                        fontSize={{ base: '15px', sm: '15px', md: '15px', lg: '18px' }}
                        color="black" lineHeight="28px"
                    >
                        <ListItem>Strong expertise in digital transformation and IT consulting.</ListItem>
                        <ListItem>Proficiency with Microsoft tools (Microsoft 365, Dynamics 365, Power Platform) and their business applications.</ListItem>
                        <ListItem>Exceptional analytical and problem-solving abilities.</ListItem>
                        <ListItem>Strong communication and interpersonal skills to engage effectively with clients and teams.</ListItem>
                        <ListItem>Project management experience, with the ability to lead complex client engagements.</ListItem>
                    </UnorderedList>
                </Text>


                <Text fontFamily="Poppins" fontWeight="400"
                    fontSize={{ base: '15px', sm: '15px', md: '15px', lg: '18px' }}
                    color="black" lineHeight="28px" mt="10px">
                    <b>Technical Proficiency:</b> Familiarity with business analysis tools, financial modeling, and project management software.
                </Text>

                <Text fontFamily="Poppins" fontWeight="400"
                    fontSize={{ base: '15px', sm: '15px', md: '15px', lg: '18px' }}
                    color="black" lineHeight="28px" mt="10px">
                    <b>Mindset:</b> Proactive, solutions-oriented, and passionate about delivering client success.
                </Text>

                {/* Bullets End */}

                <Text fontFamily="Unbounded" fontWeight="700"
                    fontSize={{ base: '20px', sm: '20px', md: '20px', lg: '24px' }}
                    lineHeight={{ base: '35px', sm: '35px', md: '35px', lg: '65px' }}
                    color="black"
                    mt={{ base: '8px', sm: '8px', md: '8px', lg: '10px' }}

                >
                    What We Offer </Text>

                <UnorderedList
                    fontFamily="Poppins" fontWeight="400"
                    fontSize={{ base: '15px', sm: '15px', md: '15px', lg: '18px' }}
                    color="black" lineHeight="28px"
                >
                    <ListItem>A competitive compensation package with performance-based incentives.</ListItem>
                    <ListItem>Opportunities for professional development and leadership growth.</ListItem>
                    <ListItem>Exposure to diverse industries and high-impact projects.</ListItem>
                    <ListItem>A collaborative and inclusive work environment that values innovation and teamwork.</ListItem>
                    <ListItem>Work-life balance to support your personal and professional goals.</ListItem>
                </UnorderedList>

                <Text fontFamily="Unbounded" fontWeight="700"
                    fontSize={{ base: '20px', sm: '20px', md: '20px', lg: '24px' }}
                    lineHeight={{ base: '35px', sm: '35px', md: '35px', lg: '65px' }}
                    color="black"
                    mt={{ base: '8px', sm: '8px', md: '8px', lg: '10px' }}

                >
                    How to Apply </Text>

                <Text fontFamily="Poppins" fontWeight="400"
                    fontSize={{ base: '17px', sm: '17px', md: '17px', lg: '20px' }}
                    color="black" lineHeight="28px">
                    If you meet the above criteria and are ready to take your consulting career to the next level, we want to hear from you! Please send your resume and a
                    cover letter detailing your experience and accomplishments to

                </Text>
                <Text fontFamily="Poppins" fontWeight="400"
                    fontSize={{ base: '17px', sm: '17px', md: '17px', lg: '20px' }}
                    color="#06BCD0" lineHeight="28px">
                    <b>
                        <a href="mailto:post@asture.co" > post@asture.co.</a>
                    </b>

                </Text>



                <Text fontFamily="Poppins" fontWeight="400"
                    fontSize={{ base: '17px', sm: '17px', md: '17px', lg: '20px' }}
                    color="black" lineHeight="28px" mt="10px">
                    <b>Asture Consulting is an equal opportunity employer.</b> We celebrate diversity
                    and are committed to creating an inclusive workplace where everyone can thrive.
                </Text>

                <Text fontFamily="Poppins" fontWeight="400"
                    fontSize={{ base: '17px', sm: '17px', md: '17px', lg: '20px' }}
                    color="black" lineHeight="28px" mt="10px">
                    <b>Apply today and take the next step in your career with Asture Consulting
                        – where innovation meets excellence!</b>
                </Text>


            </Box>
        );
    }
}

export default Business_consultant;