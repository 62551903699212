import {Box} from '@chakra-ui/react'
import Head from './Header/Head';
import Foot from './Footer/Foot';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'; // Import BrowserRouter, Routes, and Route
import Contactpage from './ContactUspage/Contactpage';
import Servicespage from './Servicespage/Servicespage';
import Azuredetail from './Servicespage/Azurdetailspage';
import Copilotdetail from './Servicespage/Copilotdetailspage';
import Dynamicsdetail from './Servicespage/Dynamicsdetailspage';
import Licensingdetail from './Servicespage/Licensingdetailpage';
import Powerdetail from './Servicespage/Powerdetail';
import Teamsdetail from './Servicespage/Teamsdetailpage';
import Careers from './Careers/Careers';
import Casestudy from './Casestudy/Casestudy';
import EmployeeExp from './Servicespage/EmployeeeExp';
import Microsoft356 from './Servicespage/Microsoft356';
import Digitalstrategy from './Servicespage/Digitalstrategy';
import Astureconsult from './Servicespage/Astureconsult';
import ModernWorkplace from './FrameworkPages/ModernWorkplace';
import DigitalTransformation from './FrameworkPages/DigitalTransformation';
import Cybersecurity from './FrameworkPages/Cybersecurity';
import CloudMigration from './FrameworkPages/Cloud Migration';
import Business_Process_Automation from './FrameworkPages/BusinessProcessAutomation';
import OurTeam from './Aboutuspages/OurTeam';
import Ourmisson from './Aboutuspages/Ourmission';
import Privacypolicy from './Privacypolicy/Privacypolicy';
import Whoweare from './Aboutuspages/Whoweare';
import Comingsoon from './Index/Comingsoon';
import Homepage from './HomePage/Homepage';
import RandomPopup from './Services/Random';

function App() {
  return (
    
  <Box width="1920px" maxW="100%">
{/* <RandomPopup/> */}
{/* <Comingsoon/> */}


        <Router>
          


      {/* Header */}
    <Box 
     px={{base:'20px',sm:'20px' ,md:'20px' , lg:'50px'}}
    bgColor={{base:'black',sm:'black' ,md:'black' , lg:'white'}}
    backdropFilter="blur(5px)"
    h={{base:'none',sm:'none' ,md:'none' , lg:'120px'}}
    pt={{base:'5px',sm:'5px' ,md:'5px' , lg:'17px'}}
    pb={{base:'5px',sm:'5px' ,md:'5px' , lg:'none'}}
    position="sticky"
    top="0"
    zIndex={{ base: '+4', sm: '+4', md: '+4', lg: '+4' }}

    >
    <Head/>
    </Box>

    {/* <RandomPopup/> */}
    <Routes>
    <Route path="/" element={<Homepage/>} />
    <Route path="/services" element={<Servicespage/>} />
    <Route path="/contactpage" element={<Contactpage />} />
    <Route path="/Azuredetail" element={<Azuredetail />} />
    <Route path="/Copilotdetail" element={<Copilotdetail />} />
    <Route path="/Dynamicsdetail" element={<Dynamicsdetail />} />
    <Route path="/Licensingdetail" element={<Licensingdetail />} />
    <Route path="/Powerdetail" element={<Powerdetail />} />
    <Route path="/Teamsdetail" element={<Teamsdetail />} />
    <Route path="/Careers" element={<Careers />} />
    <Route path="/Casestudy" element={<Casestudy />} />
    <Route path="/employee_experience" element={<EmployeeExp />} />
    <Route path="/Microsoft365" element={<Microsoft356 />} />
    <Route path="/Digital_strategy" element={<Digitalstrategy />} />
    <Route path="/Asture_consult" element={<Astureconsult />} />
    <Route path="/Modern_Workplace" element={<ModernWorkplace />} />
    <Route path="/Digital_Transformation" element={<DigitalTransformation />} />
    <Route path="/Cybersecurity" element={<Cybersecurity />} />
    <Route path="/CloudMigration" element={<CloudMigration />} />
    <Route path="/Business_Process_Automation" element={<Business_Process_Automation />} />
    <Route path="/ourpartner" element={<OurTeam />} />
    <Route path="/Ourmisson" element={<Ourmisson />} />
    <Route path="/privacypolicy" element={<Privacypolicy />} />
    <Route path="/whoweare" element={<Whoweare />} />

















    </Routes>

{/* Footer */}
    <Box
    px={{base:'20px',sm:'20px' ,md:'20px' , lg:'50px'}}
    py="60px" bgColor="#0F1414" bgImage="footeroverimg.svg" bgPosition="bottom">
      <Foot/>
    </Box>
    </Router>

      </Box>
  );
}

export default App;
