import { Box, Flex, Img, ListItem, Text, UnorderedList } from "@chakra-ui/react";
import { Component } from "react";
import Banner from "../ContactUspage/Banner";

class Powerdetail extends Component {
    componentDidMount() {
        window.scrollTo(0, 0); // Scroll to the top of the page when the component mounts
      }
    state = {  } 
    render() { 
        return (

          <Box>

<Banner 
        imageSrc="Power Platform.jpg" 
        text="Power Platform

        " 
      />

<Box px={{base:'20px',sm:'20px' ,md:'20px' , lg:'50px'}}
            w={{base:'none',sm:'none' ,md:'none' , lg:'1300px'}}
            margin="70px auto"
 
             >
                   <Text fontFamily="Unbounded" fontWeight="700" 
                   fontSize={{base:'35px',sm:'35px' ,md:'35px',lg:'54px'}}
                   color="#06BCD0" lineHeight="65px" 
                    w={{base:'none',sm:'none' ,md:'none' , lg:'850px'}}
                    >
Power Platform
                 </Text>
                 <Text fontFamily="Poppins" fontWeight="400" 
                  fontSize={{base:'17px',sm:'17px' ,md:'17px',lg:'20px'}}
                  color="black" lineHeight="28px" mt="30px">
                 Empower your business with Microsoft Power Platform, a suite of low-code tools that allow you to build custom applications, automate workflows, and generate real-time insights through data. Asture Consulting helps businesses unlock the full potential of Power Platform by providing tailored solutions using Power BI, Power Apps, Power Automate, and Power Virtual Agents. 

                 </Text>

 
 
                 <Text fontFamily="Unbounded" fontWeight="700" fontSize="24px"
                    color="black" lineHeight="65px" 
                    mt={{base:'8px',sm:'8px' ,md:'8px',lg:'67px'}}
                     >
Key Features:                  </Text>
 

 
      <Text fontFamily="Poppins" fontWeight="500" fontSize="20px" color="black">
    Custom App Development:    </Text>
  <Text fontFamily="Poppins" fontWeight="400" fontSize="16px" color="black"mt="5px" >
  Build and deploy business apps quickly using Power Apps to meet your unique operational needs.      </Text>
 
     <Text fontFamily="Poppins" fontWeight="500" fontSize="20px" color="black" pt="20px">
  Process Automation:      </Text>
  <Text fontFamily="Poppins" fontWeight="400" fontSize="16px" color="black" mt="5px" >
  Streamline and automate manual, repetitive tasks with Power Automate, saving time and reducing errors. 
     </Text>

     <Text fontFamily="Poppins" fontWeight="500" fontSize="20px" color="black" pt="20px" >
  Data-Driven Insights:   </Text>
  <Text fontFamily="Poppins" fontWeight="400" fontSize="16px" color="black" mt="5px" >
  Leverage Power BI for data analytics and visualizations to drive smarter decision-making based on real-time insights.
          </Text>


                 <Text fontFamily="Poppins" fontWeight="400"
                  fontSize={{base:'17px',sm:'17px' ,md:'17px',lg:'20px'}}
                  color="black" lineHeight="28px" mt="20px">
                 With Asture Consulting, your organization can harness the power of Microsoft Power Platform to automate workflows, enhance productivity, and unlock valuable insights. Our team of experts will guide you through the creation and deployment of solutions that accelerate your business's digital transformation. 
                 </Text>
 
                 <Img mt="30px" src="Power Platform1.jpg" />
 
             </Box>

          </Box>
           
        );
    }
}
 
export default Powerdetail;