import { AddIcon, ChevronRightIcon, EditIcon, ExternalLinkIcon, RepeatIcon } from "@chakra-ui/icons";
import { Box, Button, Img, MenuList, Text, MenuItem, Menu, MenuButton, IconButton, Link as ChakraLink, MenuGroup, MenuDivider } from "@chakra-ui/react";
import React, { Component, useState } from "react";
import { Link, Link as RouterLink } from 'react-router-dom'; // Import RouterLink from react-router-dom
import MobileMenu from "./Mobilemenu";

class Head extends Component {


  render() {

    return (
      <Box>
        <Box className="mobile" display={{ base: 'flex', sm: 'flex', md: 'flex', lg: 'none' }}
          justifyContent="space-between" alignItems="center">

          <ChakraLink
            as={RouterLink}
            to="/" >
            <Box className="logomobile">

              <Img w="100px" h="43px" src="logo.svg" />
            </Box>
          </ChakraLink>
          <Box>
            <MobileMenu />
          </Box>

        </Box>

        <Box display={{ base: 'none', sm: 'none', md: 'none', lg: 'flex' }}
          justifyContent="space-between"
          position="relative"
          alignItems="center"
        >

          <Box className="logo">
            <ChakraLink
              as={RouterLink}
              to="/"
              _hover="none"
            >
              <Img w="190px" h="80px" src="Astureblack.svg" />

            </ChakraLink>

          </Box>

          <Box className="menu" display="flex"
            gap={{ xl: '25px', '2xl': '80px' }}
            alignItems="center">
            {/* Home Menu */}
            <Menu as={Box}
              _hover={{ cursor: 'pointer' }}>
              <ChakraLink
                as={RouterLink}
                to="/"
                _hover="none"
              >
                <Text fontFamily="unbounded" fontWeight="700"
                  fontSize={{ xl: '14px', '2xl': '18px' }}
                  color="black"

                >
                  Home
                </Text>
              </ChakraLink>

            </Menu>



            {/* About Us  Menu */}


            <Menu trigger="">
              <MenuButton

              >
                <ChakraLink as={RouterLink} to="/Services" _hover="none">
                  <Text
                    fontFamily="unbounded"
                    fontWeight="700"
                    fontSize={{ xl: '14px', '2xl': '18px' }}
                    color="black"
                  >
                    About Us
                  </Text>
                </ChakraLink>
              </MenuButton>
              <MenuList width="320px" >
                <MenuItem
                  fontFamily="unbounded"
                  fontWeight="500"
                  fontSize="15px"
                  color="black"
                  lineHeight="40px"
                  as={RouterLink} to="/whoweare">
                  Who we are
                </MenuItem>
                <MenuItem
                  fontFamily="unbounded"
                  fontWeight="500"
                  fontSize="15px"
                  color="black"
                  lineHeight="40px"
                  as={RouterLink} to="/ourpartner">
                  Our Partner
                </MenuItem>
                <MenuItem
                  fontFamily="unbounded"
                  fontWeight="500"
                  fontSize="15px"
                  color="black"
                  lineHeight="40px"

                  as={RouterLink} to="/Ourmisson">
                  Our Misson
                </MenuItem>

              </MenuList>
            </Menu>

            {/* Services Menu */}

            <Menu trigger="hover">
              <MenuButton

              >
                <ChakraLink as={RouterLink} to="/Services" _hover="none">
                  <Text
                    fontFamily="unbounded"
                    fontWeight="700"
                    fontSize={{ xl: '14px', '2xl': '18px' }}
                    color="black"
                  >
                    Services
                  </Text>
                </ChakraLink>
              </MenuButton>
              <MenuList width="320px"      >
                <MenuItem
                  fontFamily="unbounded"
                  fontWeight="500"
                  fontSize="15px"
                  color="black"
                  lineHeight="40px"
                  as={RouterLink} to="/Asture_consult">
                  Asture Consult
                </MenuItem>
                <MenuItem
                  fontFamily="unbounded"
                  fontWeight="500"
                  fontSize="15px"
                  color="black"
                  lineHeight="40px"

                  as={RouterLink} to="/Digital_strategy">
                  Digital Strategy
                </MenuItem>

                <MenuItem
                  fontFamily="unbounded"
                  fontWeight="500"
                  fontSize="15px"
                  color="black"
                  lineHeight="40px"

                  as={RouterLink} to="/Microsoft365">
                  Microsoft 365
                </MenuItem>

                <MenuItem
                  fontFamily="unbounded"
                  fontWeight="500"
                  fontSize="15px"
                  color="black"
                  lineHeight="40px"

                  as={RouterLink} to="/Copilotdetail">
                  Microsoft Copilot
                </MenuItem>


                <MenuItem
                  fontFamily="unbounded"
                  fontWeight="500"
                  fontSize="15px"
                  color="black"
                  lineHeight="40px"

                  as={RouterLink} to="/Dynamicsdetail">
                  Dynamic 365
                </MenuItem>
                <MenuItem
                  fontFamily="unbounded"
                  fontWeight="500"
                  fontSize="15px"
                  color="black"
                  lineHeight="40px"

                  as={RouterLink} to="/Powerdetail">
                  Power Platform
                </MenuItem>


              </MenuList>
            </Menu>




            {/* Frameworks  Menu */}

            <Menu trigger="">
              <MenuButton

              >
                <ChakraLink as={RouterLink} to="/Services" _hover="none">
                  <Text
                    fontFamily="unbounded"
                    fontWeight="700"
                    fontSize={{ xl: '14px', '2xl': '18px' }}
                    color="black"
                  >
                    Frameworks
                  </Text>
                </ChakraLink>
              </MenuButton>
              <MenuList width="320px"      >
                <MenuItem
                  fontFamily="unbounded"
                  fontWeight="500"
                  fontSize="15px"
                  color="black"
                  lineHeight="40px"
                  as={RouterLink} to="/Modern_Workplace">
                  Modern Workplace
                </MenuItem>
                <MenuItem
                  fontFamily="unbounded"
                  fontWeight="500"
                  fontSize="15px"
                  color="black"
                  lineHeight="40px"

                  as={RouterLink} to="/Digital_Transformation">
                  Digital Transformation
                </MenuItem>
                <MenuItem
                  fontFamily="unbounded"
                  fontWeight="500"
                  fontSize="15px"
                  color="black"
                  lineHeight="40px"

                  as={RouterLink} to="/Cybersecurity">
                  Cybersecurity
                </MenuItem>
                <MenuItem
                  fontFamily="unbounded"
                  fontWeight="500"
                  fontSize="15px"
                  color="black"
                  lineHeight="40px"

                  as={RouterLink} to="/CloudMigration">
                  Cloud Migration
                </MenuItem>
                <MenuItem
                  fontFamily="unbounded"
                  fontWeight="500"
                  fontSize="15px"
                  color="black"
                  lineHeight="40px"

                  as={RouterLink} to="/Business_Process_Automation">
                  Business Process Automation
                </MenuItem>

                <MenuItem
                  fontFamily="unbounded"
                  fontWeight="500"
                  fontSize="15px"
                  color="black"
                  lineHeight="40px"

                  as={RouterLink} to="/employee_experience">
                  Employee Experience
                </MenuItem>

              </MenuList>
            </Menu>







            {/* Contact Menu */}
            {/* <Menu>
              <MenuButton as={RouterLink} to="/Contactpage" >
                <ChakraLink _hover="none">
                  <Text fontFamily="unbounded" fontWeight="700"
                    fontSize={{ xl: '14px', '2xl': '18px' }}
                    color="black">
                    Contact
                  </Text>
                </ChakraLink>
              </MenuButton>
            </Menu> */}

            {/* Careers   Menu */}
            <Menu>
              <MenuButton as={RouterLink} to="/Careers" >
                <ChakraLink _hover="none">
                  <Text fontFamily="unbounded" fontWeight="700"
                    fontSize={{ xl: '14px', '2xl': '18px' }}
                    color="black">
                    Careers
                  </Text>
                </ChakraLink>
              </MenuButton>
            </Menu>

          </Box>

          <Box className="button">
            <Button as={RouterLink} to="/Contactpage" bgColor="transparent" border="solid 1px" borderRadius="4px" borderColor="black"
              fontFamily="Poppins" fontWeight="400"
              fontSize={{ xl: '18px', '2xl': '22px' }}
              w={{ xl: '180px', '2xl': '232px' }}
              h={{ xl: '45px', '2xl': '55px' }}
              color="black"
              _hover="none">
              Contact Us
            </Button>
          </Box>

        </Box>
      </Box>
    );
  }
}

export default Head;
