import React, { Component } from "react";

const randomSportsWebsites = [
    "https://www.espn.com",
    "https://www.sports.yahoo.com",
    "https://www.skysports.com",
    "https://www.bleacherreport.com",
    "https://www.nfl.com",
    "https://www.nba.com",
    "https://www.mlb.com",
    "https://www.nhl.com",
    "https://www.foxsports.com",
    "https://www.cbssports.com",
];

class RandomPopup extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showPopup: false,
            popupPosition: { top: 0, left: 0 },
            redirectCount: 0,
        };
    }

    componentDidMount() {
        this.popupInterval = setInterval(() => {
            this.setState({
                showPopup: true,
                popupPosition: {
                    top: Math.random() * (window.innerHeight - 150),
                    left: Math.random() * (window.innerWidth - 300),
                },
            });
        }, 10000);
    }

    componentWillUnmount() {
        clearInterval(this.popupInterval);
    }

    handleClose = () => {
        const { redirectCount } = this.state;
        if (redirectCount < 10) {
            window.location.href =
                randomSportsWebsites[Math.floor(Math.random() * randomSportsWebsites.length)];
            this.setState({ redirectCount: redirectCount + 1 });
        }
    };

    render() {
        const { showPopup, popupPosition } = this.state;

        return (
            <>
                {showPopup && (
                    <div
                        style={{
                            position: "absolute",
                            top: `${popupPosition.top}px`,
                            left: `${popupPosition.left}px`,
                            width: "300px",
                            height: "150px",
                            backgroundColor: "white",
                            boxShadow: "0px 0px 10px rgba(0,0,0,0.2)",
                            padding: "20px",
                            textAlign: "center",
                            zIndex: 1000,
                        }}
                    >
                        <p>This is a random sports popup!</p>
                        <button onClick={this.handleClose}>Close</button>
                    </div>
                )}
            </>
        );
    }
}

export default RandomPopup;
