import { Box, Flex, Img, ListItem, Text, UnorderedList } from "@chakra-ui/react";
import { Component } from "react";
import Banner from "../ContactUspage/Banner";

class DigitalTransformation extends Component {
  componentDidMount() {
    window.scrollTo(0, 0); // Scroll to the top of the page when the component mounts
  }
  state = {}
  render() {
    return (

      <Box>

        <Banner
          imageSrc="Digital Transformation 1.jpg"
          text="Digital Transformation"
        />

        <Box px={{ base: '20px', sm: '20px', md: '20px', lg: '50px' }}
          w={{ base: 'none', sm: 'none', md: 'none', lg: '1300px' }}
          margin="70px auto"

        >
          <Text fontFamily="Unbounded" fontWeight="700"
            fontSize={{ base: '35px', sm: '35px', md: '35px', lg: '54px' }}
            color="#06BCD0"
            lineHeight={{ base: 'none', sm: 'none', md: 'none', lg: '65px' }}
            w={{ base: 'none', sm: 'none', md: 'none', lg: '850px' }}
          >
            Digital Transformation Framework
          </Text>
          <Text fontFamily="Poppins" fontWeight="400"
            fontSize={{ base: '17px', sm: '17px', md: '17px', lg: '20px' }}
            color="black" lineHeight="28px" mt="30px">
            Our <b>Digital Transformation</b> framework is designed to help organizations navigate the complexities of evolving technology landscapes and drive business innovation. By integrating advanced digital solutions, modernizing legacy systems, and enhancing customer and employee experiences, we empower businesses to achieve agility, resilience, and sustainable growth.
          </Text>


          <Text fontFamily="Unbounded" fontWeight="700" fontSize="24px"
            color="black"
            lineHeight={{ base: '35px', sm: '35px', md: '35px', lg: '65px' }}
            mt={{ base: '8px', sm: '8px', md: '8px', lg: 'none' }}
          >
            Key Components of the Digital Transformation Framework
          </Text>


          <Box className="box1">
            <Text fontFamily="Poppins" fontWeight="600" fontSize="22px" color="black">
              Strategy and Roadmap Development </Text>
            <Text fontFamily="Poppins" fontWeight="500" fontSize="18px" color="black" mt="5px" >
              Creating a clear, actionable path to digital success     </Text>
            <Text fontFamily="Poppins" fontWeight="400" fontSize="18px" color="black" mt="5px" >
              We collaborate closely with stakeholders to develop a tailored digital transformation
              strategy that aligns with your business objectives:
            </Text>
            <Box display="flex"
              flexDirection={{ base: 'column', sm: 'column', md: 'column', xl: 'row' }}
              gap="10px">
              <Text fontFamily="Poppins" fontWeight="400" fontSize="16px" color="black" mt="5px" >
                <b>1.Assessment of Current State: </b>  </Text>
              <Text fontFamily="Poppins" fontWeight="400" fontSize="16px" color="black"
                mt={{ base: '-7px', sm: '-7px', md: '-7px', xl: '5px' }}  >
                In-depth evaluation of existing systems, workflows, and capabilities.
              </Text>
            </Box>
            <Box display="flex"
              flexDirection={{ base: 'column', sm: 'column', md: 'column', xl: 'row' }}
              gap="10px">
              <Text fontFamily="Poppins" fontWeight="400" fontSize="16px" color="black" mt="5px" >
                <b>2.Future Vision and Goals: </b>  </Text>
              <Text fontFamily="Poppins" fontWeight="400" fontSize="16px" color="black"
                mt={{ base: '-7px', sm: '-7px', md: '-7px', xl: '5px' }} >
                Definition of key transformation goals, including customer experience, operational efficiency, and innovation.
              </Text>
            </Box>
            <Box display="flex"
              flexDirection={{ base: 'column', sm: 'column', md: 'column', xl: 'row' }}
              gap="10px">
              <Text fontFamily="Poppins" fontWeight="400" fontSize="16px" color="black" mt="5px" >
                <b>3.Roadmap Creation: </b>  </Text>
              <Text fontFamily="Poppins" fontWeight="400" fontSize="16px" color="black"
                mt={{ base: '-7px', sm: '-7px', md: '-7px', xl: '5px' }} >
                A phased plan outlining prioritized initiatives, timelines, and success metrics.
              </Text>
            </Box>
            <Text fontFamily="Poppins" fontWeight="400" fontSize="18px" color="black" mt="5px" >
              This strategic approach ensures that your digital transformation initiatives are focused, measurable,
              and aligned with long-term business growth.
            </Text>
          </Box>

          <Box className="box2" mt="20px">
            <Text fontFamily="Poppins" fontWeight="600" fontSize="22px" color="black" >
              Modernization of IT Infrastructure </Text>
            <Text fontFamily="Poppins" fontWeight="500" fontSize="18px" color="black" mt="5px" >
              Enhancing performance and scalability      </Text>
            <Text fontFamily="Poppins" fontWeight="400" fontSize="18px" color="black" mt="5px" >
              Our framework supports the modernization of IT environments to drive efficiency and flexibility:
            </Text>
            <Box display="flex"
              flexDirection={{ base: 'column', sm: 'column', md: 'column', xl: 'row' }}
              gap="10px">
              <Text fontFamily="Poppins" fontWeight="400" fontSize="16px" color="black" mt="5px" >
                <b>1.Cloud Adoption: </b>  </Text>
              <Text fontFamily="Poppins" fontWeight="400" fontSize="16px" color="black"
                mt={{ base: '-7px', sm: '-7px', md: '-7px', xl: '5px' }} >
                Migration to cloud platforms (e.g., Azure, AWS) for enhanced scalability, reliability, and cost-efficiency.
              </Text>
            </Box>
            <Box display="flex"
              flexDirection={{ base: 'column', sm: 'column', md: 'column', xl: 'row' }}
              gap="10px">
              <Text fontFamily="Poppins" fontWeight="400" fontSize="16px" color="black" mt="5px" >
                <b>2.Hybrid Solutions: </b>  </Text>
              <Text fontFamily="Poppins" fontWeight="400" fontSize="16px" color="black"
                mt={{ base: '-7px', sm: '-7px', md: '-7px', xl: '5px' }} >
                Support for hybrid environments that balance cloud and on-premises capabilities.
              </Text>
            </Box>
            <Box display="flex"
              flexDirection={{ base: 'column', sm: 'column', md: 'column', xl: 'row' }}
              gap="10px">
              <Text fontFamily="Poppins" fontWeight="400" fontSize="16px" color="black" mt="5px" >
                <b>3.Network and Systems Optimization: </b>  </Text>
              <Text fontFamily="Poppins" fontWeight="400" fontSize="16px" color="black"
                mt={{ base: '-7px', sm: '-7px', md: '-7px', xl: '5px' }} >
                Implementation of cutting-edge network solutions to support digital operations.
              </Text>
            </Box>
            <Text fontFamily="Poppins" fontWeight="400" fontSize="18px" color="black" mt="5px" >
              With modernized IT infrastructure, organizations can respond more effectively to market changes and user demands.
            </Text>
          </Box>


          <Box className="box3" mt="20px">
            <Text fontFamily="Poppins" fontWeight="600" fontSize="22px" color="black" >
              Process Automation and Optimization  </Text>
            <Text fontFamily="Poppins" fontWeight="500" fontSize="18px" color="black" mt="5px" >
              Streamlining workflows and reducing manual effort      </Text>
            <Text fontFamily="Poppins" fontWeight="400" fontSize="18px" color="black" mt="5px" >
              We leverage advanced tools to automate repetitive tasks and optimize core business processes:
            </Text>
            <Box display="flex"
              flexDirection={{ base: 'column', sm: 'column', md: 'column', xl: 'row' }}
              gap="10px">
              <Text fontFamily="Poppins" fontWeight="400" fontSize="16px" color="black" mt="5px" >
                <b>1.Power Automate: </b>  </Text>
              <Text fontFamily="Poppins" fontWeight="400" fontSize="16px" color="black"
                mt={{ base: '-7px', sm: '-7px', md: '-7px', xl: '5px' }} >
                Custom workflows to automate data collection, approvals, and notifications.
              </Text>
            </Box>
            <Box display="flex"
              flexDirection={{ base: 'column', sm: 'column', md: 'column', xl: 'row' }}
              gap="10px">
              <Text fontFamily="Poppins" fontWeight="400" fontSize="16px" color="black" mt="5px" >
                <b>2.Robotic Process Automation (RPA): </b>  </Text>
              <Text fontFamily="Poppins" fontWeight="400" fontSize="16px" color="black"
                mt={{ base: '-7px', sm: '-7px', md: '-7px', xl: '5px' }} >
                Automation of rule-based tasks to enhance operational efficiency.
              </Text>
            </Box>
            <Box display="flex"
              flexDirection={{ base: 'column', sm: 'column', md: 'column', xl: 'row' }}
              gap="10px">
              <Text fontFamily="Poppins" fontWeight="400" fontSize="16px" color="black" mt="5px" >
                <b>3.Integrated Systems: </b>  </Text>
              <Text fontFamily="Poppins" fontWeight="400" fontSize="16px" color="black"
                mt={{ base: '-7px', sm: '-7px', md: '-7px', xl: '5px' }} >
                Seamless integration of data across platforms to ensure process consistency.
              </Text>
            </Box>
            <Text fontFamily="Poppins" fontWeight="400" fontSize="18px" color="black" mt="5px" >
              By automating manual tasks, businesses can reallocate resources to more strategic initiatives and improve overall productivity.
            </Text>
          </Box>


          <Box className="box4" mt="20px">
            <Text fontFamily="Poppins" fontWeight="600" fontSize="22px" color="black" >
              Data-Driven Decision Making   </Text>
            <Text fontFamily="Poppins" fontWeight="500" fontSize="18px" color="black" mt="5px" >
              Unlocking insights to inform strategic actions       </Text>
            <Text fontFamily="Poppins" fontWeight="400" fontSize="18px" color="black" mt="5px" >
              Our framework empowers organizations to harness the power of their data:
            </Text>
            <Box display="flex"
              flexDirection={{ base: 'column', sm: 'column', md: 'column', xl: 'row' }}

              gap="10px">
              <Text fontFamily="Poppins" fontWeight="400" fontSize="16px" color="black" mt="5px" >
                <b>1.Power BI Dashboards: </b>  </Text>
              <Text fontFamily="Poppins" fontWeight="400" fontSize="16px" color="black"
                mt={{ base: '-7px', sm: '-7px', md: '-7px', xl: '5px' }} >
                Interactive visualizations and real-time analytics for key business metrics.
              </Text>
            </Box>
            <Box display="flex"
              flexDirection={{ base: 'column', sm: 'column', md: 'column', xl: 'row' }}

              gap="10px">
              <Text fontFamily="Poppins" fontWeight="400" fontSize="16px" color="black" mt="5px" >
                <b>2.AI and Machine Learning: </b>  </Text>
              <Text fontFamily="Poppins" fontWeight="400" fontSize="16px" color="black"
                mt={{ base: '-7px', sm: '-7px', md: '-7px', xl: '5px' }} >
                Predictive analytics to uncover trends and optimize decision-making.
              </Text>
            </Box>
            <Box display="flex"
              flexDirection={{ base: 'column', sm: 'column', md: 'column', xl: 'row' }}

              gap="10px">
              <Text fontFamily="Poppins" fontWeight="400" fontSize="16px" color="black" mt="5px" >
                <b>3.Unified Data Platforms: </b>  </Text>
              <Text fontFamily="Poppins" fontWeight="400" fontSize="16px" color="black"
                mt={{ base: '-7px', sm: '-7px', md: '-7px', xl: '5px' }} >
                Consolidation of data from disparate sources to create a single source of truth.
              </Text>
            </Box>
            <Text fontFamily="Poppins" fontWeight="400" fontSize="18px" color="black" mt="5px" >
              This data-centric approach helps businesses make informed, proactive decisions that drive growth and innovation.
            </Text>
          </Box>



          <Box className="box5" mt="20px">
            <Text fontFamily="Poppins" fontWeight="600" fontSize="22px" color="black" >
              Customer and Employee Experience Transformation    </Text>
            <Text fontFamily="Poppins" fontWeight="500" fontSize="18px" color="black" mt="5px" >
              Creating seamless, intuitive digital experiences       </Text>
            <Text fontFamily="Poppins" fontWeight="400" fontSize="18px" color="black" mt="5px" >
              We focus on enhancing experiences to build stronger connections with customers and employees:
            </Text>
            <Box display="flex"
              flexDirection={{ base: 'column', sm: 'column', md: 'column', xl: 'row' }}

              gap="10px">
              <Text fontFamily="Poppins" fontWeight="400" fontSize="16px" color="black" mt="5px" >
                <b>1.Customer Experience (CX): </b>  </Text>
              <Text fontFamily="Poppins" fontWeight="400" fontSize="16px" color="black"
                mt={{ base: '-7px', sm: '-7px', md: '-7px', xl: '5px' }} >
                Digital touchpoints and self-service solutions that enhance customer engagement.
              </Text>
            </Box>
            <Box display="flex"
              flexDirection={{ base: 'column', sm: 'column', md: 'column', xl: 'row' }}

              gap="10px">
              <Text fontFamily="Poppins" fontWeight="400" fontSize="16px" color="black" mt="5px" >
                <b>2.Employee Experience (EX): </b>  </Text>
              <Text fontFamily="Poppins" fontWeight="400" fontSize="16px" color="black"
                mt={{ base: '-7px', sm: '-7px', md: '-7px', xl: '5px' }} >
                Intuitive platforms and tools that enhance productivity, collaboration, and satisfaction.
              </Text>
            </Box>
            <Box display="flex"
              flexDirection={{ base: 'column', sm: 'column', md: 'column', xl: 'row' }}

              gap="10px">
              <Text fontFamily="Poppins" fontWeight="400" fontSize="16px" color="black" mt="5px" >
                <b>3.Feedback Loops: </b>  </Text>
              <Text fontFamily="Poppins" fontWeight="400" fontSize="16px" color="black"
                mt={{ base: '-7px', sm: '-7px', md: '-7px', xl: '5px' }} >
                Continuous feedback mechanisms to iterate and improve digital experiences.
              </Text>
            </Box>
            <Text fontFamily="Poppins" fontWeight="400" fontSize="18px" color="black" mt="5px" >
              With a focus on experience-driven transformation, businesses can increase engagement, loyalty, and competitive advantage.
            </Text>
          </Box>

          <Text fontFamily="Unbounded" fontWeight="700" fontSize="24px"
            color="black"
            lineHeight={{ base: '35px', sm: '35px', md: '35px', lg: '65px' }}
            mt={{ base: '8px', sm: '8px', md: '8px', lg: 'none' }}
          >
            Business Outcomes of the Digital Transformation Framework
          </Text>
          <Box display="flex"
            flexDirection={{ base: 'column', sm: 'column', md: 'column', xl: 'row' }}

            gap="5px">
            <Text fontFamily="Poppins" fontWeight="400"
              fontSize={{ base: '16px', sm: '16px', md: '16px', lg: '17px' }}
              color="black">
              <b>Enhanced Operational Agility:</b>
            </Text>
            <Text fontFamily="Poppins" fontWeight="400"
              fontSize={{ base: '16px', sm: '16px', md: '16px', lg: '17px' }}
              color="black">
              Increased responsiveness to market changes through optimized processes and modern infrastructure.
            </Text>
          </Box>
          <Box display="flex"
            flexDirection={{ base: 'column', sm: 'column', md: 'column', xl: 'row' }}

            gap="5px">
            <Text fontFamily="Poppins" fontWeight="400"
              fontSize={{ base: '16px', sm: '16px', md: '16px', lg: '17px' }}
              color="black">
              <b>Improved Customer Engagement:</b>
            </Text>
            <Text fontFamily="Poppins" fontWeight="400"
              fontSize={{ base: '16px', sm: '16px', md: '16px', lg: '17px' }}
              color="black">
              Deliver personalized, seamless experiences across digital touchpoints.
            </Text>
          </Box>

          <Box display="flex"
            flexDirection={{ base: 'column', sm: 'column', md: 'column', xl: 'row' }}

            gap="5px">
            <Text fontFamily="Poppins" fontWeight="400"
              fontSize={{ base: '16px', sm: '16px', md: '16px', lg: '17px' }}
              color="black">
              <b>Data-Driven Innovation:</b>
            </Text>
            <Text fontFamily="Poppins" fontWeight="400"
              fontSize={{ base: '16px', sm: '16px', md: '16px', lg: '17px' }}
              color="black">
              Leverage insights to identify new opportunities and drive innovation.
            </Text>
          </Box>

          <Box display="flex"
            flexDirection={{ base: 'column', sm: 'column', md: 'column', xl: 'row' }}

            gap="5px">
            <Text fontFamily="Poppins" fontWeight="400"
              fontSize={{ base: '16px', sm: '16px', md: '16px', lg: '17px' }}
              color="black">
              <b>Increased Efficiency:</b>
            </Text>
            <Text fontFamily="Poppins" fontWeight="400"
              fontSize={{ base: '16px', sm: '16px', md: '16px', lg: '17px' }}
              color="black">
              Automate workflows to reduce costs and improve resource allocation.
            </Text>
          </Box>

          <Box display="flex"
            flexDirection={{ base: 'column', sm: 'column', md: 'column', xl: 'row' }}

            gap="5px">
            <Text fontFamily="Poppins" fontWeight="400"
              fontSize={{ base: '16px', sm: '16px', md: '16px', lg: '17px' }}
              color="black">
              <b>Sustainable Growth:</b>
            </Text>
            <Text fontFamily="Poppins" fontWeight="400"
              fontSize={{ base: '16px', sm: '16px', md: '16px', lg: '17px' }}
              color="black">
              Build a resilient, future-ready organization that can scale and adapt.
            </Text>
          </Box>



          <Text fontFamily="Unbounded" fontWeight="700" fontSize="24px"
            color="black"
            lineHeight={{ base: '35px', sm: '35px', md: '35px', lg: '65px' }}
            mt={{ base: '8px', sm: '8px', md: '8px', lg: 'none' }}
          >
            Why Choose Asture Consulting?
          </Text>


          <Text fontFamily="Poppins" fontWeight="400"
            fontSize={{ base: '17px', sm: '17px', md: '17px', lg: '20px' }}
            color="black" lineHeight="28px">
            At <b>Asture Consulting,</b> we understand that digital transformation is more than just implementing new technologies—it’s about redefining how your business creates value. Our team of experts guides you through every step of the process, from strategy development to execution and optimization.
          </Text>

          <Text fontFamily="Poppins" fontWeight="400"
            fontSize={{ base: '17px', sm: '17px', md: '17px', lg: '20px' }}
            color="black" lineHeight="28px" mt="30px">
            We provide end-to-end support, ensuring a smooth transition and delivering measurable outcomes that align with your business goals. Whether you’re looking to modernize your IT infrastructure, enhance your data capabilities, or elevate your customer experience, our Digital Transformation framework is your blueprint for success.
          </Text>


          <Text fontFamily="Poppins" fontWeight="400"
            fontSize={{ base: '17px', sm: '17px', md: '17px', lg: '20px' }}
            color="black" lineHeight="28px" mt="30px">
            <b>Next Steps:</b> Ready to embark on your digital transformation journey? Contact <b>Asture Consulting</b> today to explore how our tailored framework can accelerate your path to innovation and growth.
          </Text>

          <Img mt="30px" src="Digital Transformation 2.png" />

        </Box>
      </Box>

    );
  }
}

export default DigitalTransformation;