import { Box, Button, Heading, IconButton, Img, Link, Menu, MenuButton, MenuItem, MenuList, Text } from "@chakra-ui/react";
import { Component } from "react";

class Company extends Component {
    state = {}
    render() {
        return (
            <Box display="flex"
                flexDirection={{ base: 'column', sm: 'column', md: 'column', lg: 'row' }}
                justifyContent="space-between"
                gap={{ base: '14px', sm: '14px', md: '14px', lg: '30px', '2xl': '80px' }}>


                <Box bg="#F6F8F8"
                    p={{ base: '15px', sm: '15px', md: '15px', lg: '30px' }}
                    borderRadius="15px"
                    display="flex"
                    alignItems="center"
                    _hover={{ bgColor: '#EBF0EF' }}
                    gap={{ base: '24px', sm: '24px', md: '24px', lg: '60px' }}>
                    <Img
                        h={{ base: '130px', sm: '130px', md: '130px', lg: '270px' }}
                        w={{ base: '130px', sm: '130px', md: '130px', lg: '290px' }}
                        src="Astureblack.png" />
                    <Box>
                        <Heading fontFamily="Unbounded"
                            fontSize={{ base: '20px', sm: '20px', md: '20px', lg: '25px', '2xl': '36px' }}
                            fontWeight="800"
                        >Consulting</Heading>
                        <Text
                            pt="20px"
                            fontFamily="Poppins"
                            fontSize={{ base: '14px', sm: '14px', md: '14px', lg: '20px' }}
                            lineHeight={{ base: '19px', sm: '19px', md: '19px', lg: '24px' }}
                            fontWeight="400"
                        >We are a full-service leading Microsoft Consulting Services company
                            offering a wide range of digital solutions.</Text>
                    </Box>
                </Box>


                <Box bg="#F6F8F8"
                    p={{ base: '15px', sm: '15px', md: '15px', lg: '30px' }}
                    borderRadius="15px" display="flex" alignItems="center"
                    _hover={{ bgColor: '#EBF0EF' }}
                    gap={{ base: '24px', sm: '24px', md: '24px', lg: '60px' }}>

                    <Img
                        h={{ base: '130px', sm: '130px', md: '130px', lg: '200px' }}
                        w={{ base: '130px', sm: '130px', md: '130px', lg: '200px' }}
                        src="webringlogo.png" />
                    <Box>
                        <Heading
                            fontFamily="Unbounded"
                            fontSize={{ base: '20px', sm: '20px', md: '20px', lg: '25px', '2xl': '36px' }}
                            fontWeight="800">Development</Heading>
                        <Text fontFamily="Poppins"
                            fontSize={{ base: '14px', sm: '14px', md: '14px', lg: '20px' }}
                            lineHeight={{ base: '19px', sm: '19px', md: '19px', lg: '24px' }}

                            fontWeight="400"
                            pt={{ base: '5px', sm: '5px', md: '5px', lg: '16px' }}
                        >
                            We are a full-service leading Web & App Development company
                            offering a wide range of digital solutions.
                        </Text>

                        {/* <Link href="https://www.webring.ltd/">
                <Button 
                fontFamily="Poppins"
                fontWeight="400" 
                fontSize={{base:'13px',sm:'13px',md:'13px',lg:'16px'}}
                color="white" lineHeight="24px"
colorScheme="none" bgColor="#06BCD0" 
w={{base:'100px',sm:'100px',md:'100px',lg:'129px'}}
h= {{base:'30px',sm:'30px',md:'30px',lg:'44px'}}
mt={{base:'10px',sm:'10px',md:'10px',lg:'32px'}}
>Learn More</Button>
</Link> */}
                    </Box>
                </Box>
            </Box>
        );
    }

}

export default Company;